/* eslint-disable camelcase */
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { Contact, FavoriteChange } from '../models';
import ArrayUtils from '../../utils/ArrayUtils';

@Module({
  namespaced: true,
  name: 'search',
  dynamic: true,
  store,
  stateFactory: true
})
class SearchModule extends VuexModule {
  search_contacts: Contact[] = [];

  sort_search_contacts: Contact[] = [];

  search_input = '';

  search_tag = '';

  search_type = '';

  search_total = 0;

  search_offset = 0;

  search_limit: number | null = null;

  search_next_offset = 0;

  search_request_path = '';

  @Mutation
  add_search(data: Contact[]) {
    this.search_contacts = data;
  }

  @Mutation
  sort_search(payload: Contact[]) {
    this.sort_search_contacts = ArrayUtils.alphabeticSort(payload, 'fullname');
  }

  @Mutation
  append_search(payload: Contact[]) {
    const all_contacts = this.sort_search_contacts.concat(payload);
    this.sort_search_contacts = ArrayUtils.alphabeticSort(all_contacts, 'fullname');
  }

  @Mutation
  add_search_input(payload: string) {
    this.search_input = payload;
  }

  @Mutation
  add_search_tag(payload: string) {
    this.search_tag = payload;
  }

  @Mutation
  add_search_type(payload: string) {
    this.search_type = payload;
  }

  @Mutation
  update_sort_search(payload: FavoriteChange) {
    const foundContact = this.sort_search_contacts.find((contact: Contact) => contact.id === payload.id);

    if (foundContact) {
      foundContact.favorite = payload.favorite;
    }
  }

  @Mutation
  add_search_total(payload: number) {
    this.search_total = payload;
  }

  @Mutation
  add_search_offset(payload: number) {
    this.search_offset = payload;
  }

  @Mutation
  add_search_limit(payload: number) {
    this.search_limit = payload;
  }

  @Mutation
  add_search_next_offset(payload: number) {
    this.search_next_offset = payload;
  }

  @Mutation
  add_search_request_path(payload: string) {
    this.search_request_path = payload;
  }
}

export default getModule(SearchModule);
