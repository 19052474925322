import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { RootState } from '@/store/types';
import pwaService from './modules/pwaService/pwaService';
import contact from './modules/contact/index';
import product from './modules/product/index';
import order from './modules/order/index';

Vue.use(Vuex);

const initialModules = {
  pwaService,
  contact,
  product,
  order
};

const storePattern = {
  modules: { ...initialModules }
};

const store: Store<RootState> = new Vuex.Store({
  ...storePattern
});

export default store;
