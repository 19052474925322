







import Vue from 'vue';
import InfoCard from '@/components/Common/InfoCards/InfoCard.vue';

export default Vue.extend({
  name: 'InfoCardsWrapper',
  components: {
    InfoCard
  },
  props: {
    internalInfo: {
      type: String
    },
    additionalInfo: {
      type: String
    },
    edit: {
      type: Boolean
    }
  },
  methods: {
    changeInternal(value: string): void {
      this.$emit('changeInternal', value);
    },
    changeAdditional(value: string): void {
      this.$emit('changeAdditional', value);
    }
  }
});
