import Vue from 'vue';

type ContactItem = {
  type: string;
  context: string;
  icon: string;
  action: (arg0: string) => void;
};

export default Vue.extend({
  data() {
    return {
      sheet: false
    };
  },
  computed: {
    contactInfo(): ContactItem[] {
      return [
        {
          type: 'phone',
          context: '075 72 / 763 00',
          icon: 'mdi-phone',
          action: this.call
        },
        {
          type: 'email',
          context: 'support@kiratik.de',
          icon: 'mdi-email',
          action: this.mail
        }
      ];
    }
  },
  methods: {
    call(phone: string): void {
      const prepatedNoNumber = phone.replace('/', '').replace(/ /g, '');
      window.open(`tel:${prepatedNoNumber}`, '_self');
    },

    mail(email: string): void {
      window.open(`mailto:${email}`, '_self');
    }
  }
});
