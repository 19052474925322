<template>
  <div class="search-content">
    <div class="big-search-logo d-flex flex-column align-center justify-center" v-if="Object.keys(filtered).length === 0">
      <img src="../../assets/big-search-icon.svg" class="big-search-logo-icon" alt="search logo" />
      <p class="big-search-logo-text">
        {{ $t('search_for_a_contact') }}
      </p>
    </div>
    <ContactsList v-bind:contacts="filtered" :pagination="this.pagination" />
  </div>
</template>

<script>
/* eslint-disable class-methods-use-this */
import lsService from '@/services/localStorage/lsService';
import globalStore from '@/store/modules/global';
import searchStore from '@/store/modules/search';
import eventBus from '@/services/eventBus';
import { Component, Watch, Vue } from 'vue-property-decorator';
import ContactsList from '../../components/Common/ContactList.vue';

@Component({
  components: {
    ContactsList
  }
})
export default class Search extends Vue {
  name = 'SearchView';

  title = 'search';

  contactsRepository = this.$repositoryFactory.get('contacts');

  apiVersionRepository = this.$repositoryFactory.get('apiversion');

  get filtered() {
    return searchStore.sort_search_contacts;
  }

  get contacts() {
    return searchStore.search_contacts;
  }

  get params() {
    return {
      searchString: this.searchInput,
      type: this.searchType,
      tag: this.searchTag,
      offset: this.searchNextOffset
    };
  }

  get searchInput() {
    return searchStore.search_input;
  }

  set searchInput(value) {
    searchStore.add_search_input(value);
  }

  get searchTag() {
    return searchStore.search_tag;
  }

  set searchTag(value) {
    searchStore.add_search_tag(value);
  }

  get searchType() {
    return searchStore.search_type;
  }

  set searchType(value) {
    searchStore.add_search_type(value);
  }

  get searchOffset() {
    return searchStore.search_offset;
  }

  get searchLimit() {
    return searchStore.search_limit;
  }

  get searchNextOffset() {
    return searchStore.search_next_offset;
  }

  set searchNextOffset(value) {
    searchStore.add_search_next_offset(value);
  }

  get searchTotal() {
    return searchStore.search_total;
  }

  get pagination() {
    return this.searchNextOffset < this.searchTotal;
  }

  search(limit) {
    this.contactsRepository.get(this.params, limit);
  }

  getApiVersion() {
    this.apiVersionRepository.get(null);
  }

  searchCallback(searchString) {
    lsService.setItem(`searchStr_${this.$route.name}`, searchString);
    this.searchInput = searchString !== undefined ? searchString : this.searchInput;
    this.search();
  }

  tagChangedCallback(tagData) {
    this.searchTag = tagData.name;
  }

  typeChangedCallback(typeData) {
    this.searchType = typeData.name;
  }

  faveChangedCallback() {
    this.searchNextOffset = 0;
    const limit = this.searchOffset + this.searchLimit;
    this.search(limit);
  }

  mounted() {
    globalStore.changeTitle(this.title);
    globalStore.changeView(this.title);
    globalStore.showSearchInput();
    this.getApiVersion();
  }

  created() {
    eventBus.$on('searchContact', this.searchCallback);
    eventBus.$on('tagChanged', this.tagChangedCallback);
    eventBus.$on('typeChanged', this.typeChangedCallback);
    eventBus.$on('faveChanged', this.faveChangedCallback);
  }

  beforeDestroy() {
    eventBus.$off('searchContact', this.searchCallback);
    eventBus.$off('typeChanged', this.tagChangedCallback);
    eventBus.$off('tagChanged', this.typeChangedCallback);
    eventBus.$off('faveChanged', this.faveChangedCallback);
  }

  @Watch('searchTag')
  searchTagChanged() {
    this.search();
  }

  @Watch('searchType')
  searchTypeChanged() {
    this.search();
  }

  @Watch('contacts')
  contactsChanged() {
    this.search();
  }
}
</script>
<style scoped lang="scss">
.search-content {
  padding: 104px 0 56px 0;
}
</style>
<style lang="scss" scoped>
.search-content {
  height: 100%;
}

.big-search-logo {
  height: 100%;
  color: $brand-new-black-30;

  .big-search-logo-icon {
    max-width: 136px;
  }

  .big-search-logo-text {
    font-size: 24px;
  }
}
</style>
