import { RootState } from '@/store/types';
import { Module } from 'vuex';
import { ContactState } from '@/store/modules/contact/types';
import state from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const contact: Module<ContactState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default contact;
