


















































import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ContactRepository } from '@/services/api/IEntityRepository';
import RepositoryFactory from '@/services/api/RepositoryFactory';
import convertDate from '@/utils/ConvertDate';

const repositoryFactory = new RepositoryFactory();
const contactsRepository: ContactRepository = repositoryFactory.get('contacts') as ContactRepository;

export default Vue.extend({
  name: 'OrderingDeliveryDate',
  components: {},
  props: {},
  data: () => ({
    // Form init data source
    minDateForDataPicker: new Date(Date.now()).toISOString().substr(0, 10),
    availableDeliveryDates: [] as string[],

    // Modal/Dialog
    modalDeliveryDate: false,
    // Switch/Toggle
    switchCustomerDeliveryDates: false,
    loadingCustomerDeliveryDates: false
  }),
  computed: {
    ...mapState('contact', ['currentContact']),
    ...mapGetters('contact', ['getContactId']),
    ...mapGetters('order', ['getOrderDraft']),

    getMinDateForDataPicker(): string {
      return this.minDateForDataPicker;
    },
    getSwitchCustomerDeliveryDates(): boolean {
      return this.switchCustomerDeliveryDates;
    },
    formatDate(): string {
      return convertDate(this.getOrderDraft.deliveryDate);
    }
  },
  methods: {
    ...mapActions('order', ['setOrderDraftDeliveryDate']),

    calcDisableDates(val: string): boolean {
      if (!this.getSwitchCustomerDeliveryDates) {
        return true;
      }
      if (this.availableDeliveryDates.length > 0) {
        return this.availableDeliveryDates.indexOf(val) !== -1;
      }
      // eslint-disable-next-line no-console
      console.log("Can't execute calcDisableDates - availableDeliveryDates is empty!");
      return false;
    },
    async fetchDeliveryDates(): Promise<void> {
      if (this.availableDeliveryDates.length === 0) {
        this.loadingCustomerDeliveryDates = true;
        const {
          data: { next_delivery_dates: dates }
        } = await contactsRepository.getDeliveryDates(this.getContactId);
        this.availableDeliveryDates = dates.map((item: string) => item.substring(0, 10));
        this.loadingCustomerDeliveryDates = false;
      }
    }
  },
  created() {
    this.setOrderDraftDeliveryDate(this.minDateForDataPicker);
  },
  watch: {
    getSwitchCustomerDeliveryDates: {
      async handler(newState) {
        if (newState === true) {
          this.fetchDeliveryDates();
          // TODO: in case when user will choose date and then switch to "customer dates" where this date is not available we should set first available date
          /* if (this.availableDeliveryDates.indexOf(this.getOrderDraft.deliveryDate) !== -1) {
            // if you can not use this delivery date, then set first available delivery date
            this.setOrderDraftDeliveryDate(this.availableDeliveryDates[0]);
          } */
        }
      },
      immediate: true
    }
  },
  i18n: {
    messages: {
      en: {
        orderDeliveryDate_customerSwitch_label: 'Customer specific delivery dates'
      },
      de: {
        orderDeliveryDate_customerSwitch_label: 'Kundenspezifische Liefertermine'
      }
    }
  }
});
