import lsService from '@/services/localStorage/lsService';

interface config {
  url: string;
}

interface error {
  message: string;
}

interface data {
  error: error;
}

interface response {
  status: number;
  headers: Record<string, unknown>;
  data: data;
}

interface httpError {
  config: config;
  response: response;
}

const tokenExpiredError: httpError = {
  config: {
    url: lsService.getItem('apiUrl') || ''
  },
  response: {
    status: 401,
    headers: {},
    data: {
      error: {
        message: 'Token expired, login!'
      }
    }
  }
};

export default tokenExpiredError;
