











import Vue from 'vue';

export default Vue.extend({
  name: 'Summary',
  props: ['summaryItems']
});
