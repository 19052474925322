export default {
  arrayRemove(array, value) {
    return array.filter(el => el !== value);
  },

  /**
   * Takes the objects array which contain fullname property by
   * which it will be sorted in alphabetical order
   * @param data array [{ fullname: "..." }, ...]
   * @param order boolean ASC or DESC. Default ASC
   */
  alphabeticSort(data, by, order = 'ASC') {
    data.sort((a, b) => {
      const valueA = typeof a[by] === 'string' ? a[by].toLowerCase() : a[by];
      const valueB = typeof b[by] === 'string' ? b[by].toLowerCase() : b[by];

      if (valueA > valueB) {
        return order === 'ASC' ? 1 : -1;
      }
      if (valueA < valueB) {
        return order === 'ASC' ? -1 : 1;
      }

      return 0;
    });

    return data;
  }
};
