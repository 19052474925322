



























/* eslint-disable class-methods-use-this,camelcase */
import Vue from 'vue';
import Factory from '@/services/api/RepositoryFactory';
import { mapState } from 'vuex';
import { ContactRepository } from '@/services/api/IEntityRepository';
import { Veterinary } from '@/store/modules/contact/types';
import InfoList from './DetailCard/InfoList.vue';

const factory = new Factory();

export default Vue.extend({
  name: 'LivestockTraderCard',
  components: {
    InfoList
  },
  computed: {
    ...mapState('contact', ['currentContact', 'livestockTrader']),
    livestockTraderLink(): string | undefined {
      return this.currentContact.links?.livestock_trade;
    },
    contactsRepository(): ContactRepository {
      return factory.get('contacts') as ContactRepository;
    },
    itemsFirst(): Record<string, unknown>[] {
      if (this.livestockTrader) {
        const { representative, umst_id, tax_id, tax_rate, tattoo_number, company_number } = this.livestockTrader;
        return [
          {
            title: 'supplier_customer',
            value: [this.currentContact.fullname],
            icon: 'mdi-truck'
          },
          {
            title: 'representative',
            value: [representative],
            icon: 'mdi-account'
          },
          {
            title: 'umst_id',
            value: [umst_id],
            icon: 'mdi-chart-line'
          },
          {
            title: 'tax_id',
            value: [tax_id],
            icon: 'mdi-cash-usd'
          },
          {
            title: 'tax_rate',
            value: [tax_rate],
            icon: 'mdi-chart-arc'
          },
          {
            title: 'tattoo_number',
            value: [tattoo_number],
            icon: 'mdi-brush'
          },
          {
            title: 'company_number',
            value: [company_number],
            icon: 'mdi-domain'
          }
        ];
      }
      return [];
    },
    itemsSeconds(): Record<string, unknown>[] {
      if (this.livestockTrader?.veterinaries) {
        return this.livestockTrader.veterinaries.map((veterinary: Veterinary) => {
          const { name, contact_person, street, city, zipcode, phone, mobile } = veterinary;
          return [
            {
              title: 'name',
              value: [name],
              icon: 'mdi-home'
            },
            {
              title: 'doctor_in_charge',
              value: [contact_person],
              icon: 'mdi-account'
            },
            {
              title: 'address',
              method() {
                const parameters = encodeURIComponent(`${street}, ${city}, ${zipcode}`);
                const url = `https://www.google.com/maps/search/?api=1&query=${parameters}`;
                window.open(url);
              },
              value: [street, `${zipcode} ${city}`],
              icon: 'mdi-map-marker'
            },
            {
              title: 'phone',
              method() {
                const tel = phone.replace('/', '');
                window.open(`tel:${tel}`, '_self');
              },
              value: [phone],
              icon: 'mdi-phone'
            },
            {
              title: 'mobile',
              method() {
                const tel = mobile.replace('/', '');
                window.open(`tel:${tel}`, '_self');
              },
              value: [mobile],
              icon: 'mdi-cellphone'
            }
          ];
        });
      }
      return [];
    },
    itemsThird(): Record<string, unknown>[] {
      if (this.livestockTrader) {
        const { qs_id, qp_name } = this.livestockTrader;
        return [
          {
            title: 'qs_id',
            value: [qs_id],
            icon: 'mdi-checkerboard'
          },
          {
            title: 'qp_name',
            value: [qp_name],
            icon: 'mdi-basket'
          }
        ];
      }
      return [];
    }
  },
  mounted() {
    if (this.livestockTraderLink) {
      this.contactsRepository.getLivestockTrader(this.livestockTraderLink);
    }
  }
});
