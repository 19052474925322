

































import Vue from 'vue';
import { mapState } from 'vuex';
import { OrdersRepository, ProductsRepository, ContactRepository } from '@/services/api/IEntityRepository';
import Factory from '@/services/api/RepositoryFactory';
import { ProductViewForm } from './types';

const factory = new Factory();

export default Vue.extend({
  name: 'ProductViewForm',
  components: {
    ProductDetails: () => import('@/views/Orders/ProductView/ProductViewDetails.vue'),
    InfoCardsWrapper: () => import('@/components/Common/InfoCards/InfoCardsWrapper.vue')
  },
  props: {
    product: {
      type: Object
    },
    amount: {
      type: Number
    },
    productId: {
      type: String
    },
    customerId: {
      type: String
    },
    accountId: {
      type: String
    },
    lastPrice: {
      type: Number
    }
  },
  data() {
    return {
      valid: false,
      amountRules: [
        (v: string) => !!v,
        (v: string) => !isNaN(parseFloat(v)) && parseFloat(v) !== 0,
        (v: string) => /^\s*\d+\.\d{1,2}\s*$/.test(v) || /^\s*\d+\s*$/.test(v),
        (v: string) => /^[1-9]/.test(v) || /0\.\d*/.test(v)
      ],
      priceRules: [
        (v: string) => !!v,
        (v: string) => !isNaN(parseFloat(v)) && parseFloat(v) !== 0,
        (v: string) => /^\s*\d+\.\d{1,2}\s*$/.test(v) || /^\s*\d+\s*$/.test(v),
        (v: string) => /^[1-9]/.test(v) || /0\.\d*/.test(v)
      ],
      date: '',
      order: {
        product_id: '',
        amount: 0,
        price: 0,
        sell_unit: '',
        additional_info: '',
        internal_info: ''
      }
    } as ProductViewForm;
  },
  computed: {
    ...mapState('global', ['loadingCounter']),
    contactRepository(): ContactRepository {
      return factory.get('contacts') as ContactRepository;
    },
    orderRepository(): OrdersRepository {
      return factory.get('order') as OrdersRepository;
    },
    productRepository(): ProductsRepository {
      return factory.get('products') as ProductsRepository;
    }
  },
  methods: {
    async createOrder() {
      const order = {
        account_id: this.customerId,
        delivery_date: new Date(this.date).toISOString(),
        products: [this.order]
      };
      const { data } = await this.orderRepository.create(order);
      this.$emit('setNewOrder', data);
      this.$emit('send');
    },
    setInternalInfo(value: string): void {
      this.order.internal_info = value;
    },
    setAdditionalInfo(value: string): void {
      this.order.additional_info = value;
    },
    async getPriceProduct(): Promise<void> {
      if (this.date.length) {
        const { data } = await this.productRepository.getProductPrice(this.product.links.price, this.date, this.accountId);
        this.order.price = data.price;
      }
    }
  },
  watch: {
    date() {
      this.getPriceProduct();
    },
    product: {
      handler() {
        this.order.sell_unit = this.product.sell_unit;
        this.getPriceProduct();
      }
    },
    amount: {
      handler() {
        this.order.amount = this.amount;
      },
      immediate: true
    },
    productId: {
      handler() {
        this.order.product_id = this.productId;
      },
      immediate: true
    }
  },
  async created() {
    const {
      data: { next_delivery_dates: dates }
    } = await this.contactRepository.getDeliveryDates(this.accountId, '1');
    const [signleDate] = dates;
    this.date = signleDate !== undefined ? signleDate : new Date().toISOString();
  }
});
