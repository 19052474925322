import { ProductsRepository } from '@/services/api/IEntityRepository';
import Axios from '@/services/api/Repository';
import { AxiosResponse } from 'axios';
import { GETProductsResponse } from '@/services/api/productsRepository/types';

const productsRepository: ProductsRepository = {
  resource: '/products',

  get(params = {}): Promise<AxiosResponse<GETProductsResponse>> {
    return Axios.get(this.resource, { params });
  },
  getProduct(productId: string) {
    return Axios.get(`${this.resource}/${productId}`);
  },
  getProductPrice(link, date, contactId) {
    return Axios.get(link, { params: { contact_id: contactId, date } });
  }
};

export default productsRepository;
