import lsService from '@/services/localStorage/lsService';
import introStore from '@/store/modules/intros';

class WizardService {
  // eslint-disable-next-line class-methods-use-this
  hideIntro(id: number) {
    const currentIntros = introStore.showIntros.filter(value => value !== id);
    introStore.changeShowIntros(currentIntros);

    const seenIntros = lsService.getSeenIntros();
    if (!seenIntros.includes(id)) {
      seenIntros.push(id);
      lsService.setSeenIntros(seenIntros);
    }
  }
}

export default new WizardService();
