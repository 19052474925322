import { RootState } from '@/store/types';
import { Module } from 'vuex';
import { OrderState } from '@/store/modules/order/types';
import actions from '@/store/modules/order/actions';
import getters from '@/store/modules/order/getters';
import state from './state';
import mutations from './mutations';

const order: Module<OrderState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default order;
