import { actionTree } from 'typed-vuex';
import initialState from './state';
import mutations from './mutations';
import getters from './getters';
import { Contact, LivestockTrader, OpenItems, Products, ValuesPerMonth } from './types';

const actions = actionTree(
  { state: initialState, mutations, getters },
  {
    resetState({ commit }) {
      commit('REMOVE_CONTACT');
      commit('REMOVE_CHILDREN');
      commit('REMOVE_VALUES_PER_MONTH');
      commit('REMOVE_PRODUCTS');
      commit('REMOVE_OPEN_ITEMS');
      commit('REMOVE_LIVESTOCK_TRADER');
    },
    addCurrentContact({ commit }, payload: Contact) {
      commit('ADD_CONTACT', payload);
    },
    removeCurrentContact({ commit }) {
      commit('REMOVE_CONTACT');
    },
    addChildren({ commit }, payload: Contact[]) {
      commit('ADD_CHILDREN', payload);
    },
    removeChildren({ commit }) {
      commit('REMOVE_CHILDREN');
    },
    addValuesPerMonth({ commit }, payload: ValuesPerMonth) {
      commit('ADD_VALUES_PER_MONTH', payload);
    },
    removeValuesPerMonth({ commit }) {
      commit('REMOVE_VALUES_PER_MONTH');
    },
    addProducts({ commit }, payload: Products) {
      commit('ADD_PRODUCTS', payload);
    },
    removeProducts({ commit }) {
      commit('REMOVE_PRODUCTS');
    },
    addOpenItems({ commit }, payload: OpenItems) {
      commit('ADD_OPEN_ITEMS', payload);
    },
    removeOpenItems({ commit }) {
      commit('REMOVE_OPEN_ITEMS');
    },
    addLivestockTrader({ commit }, payload: LivestockTrader) {
      commit('ADD_LIVESTOCK_TRADER', payload);
    },
    removeLivestockTrader({ commit }) {
      commit('REMOVE_LIVESTOCK_TRADER');
    }
  }
);

export default actions;
