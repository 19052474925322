/* eslint-disable no-console */

import { register } from 'register-service-worker';
import lsService from '@/services/localStorage/lsService';
import KACService from '@/services/KACService';
import intros from '@/store/modules/intros';
import VueI18n from '@/i18n';
import KACAxios from './services/apiKAC/Repository';
import Axios from './services/api/Repository';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // eslint-disable-next-line no-console
      console.log('App is being served from cache by a service worker.\n For more details, visit https://goo.gl/AFskqB');
    },
    registered() {
      // eslint-disable-next-line no-console
      console.log('Service worker has been registered.');
      const baseURL = lsService.getItem('apiUrl');
      if (baseURL) {
        Axios.get('/contacts?limit=0');
      }
      KACAxios.get(`/api/intros?app.code=${KACService.APP_CODE}`).then(res => {
        intros.addIntros(res.data);
        lsService.setIntros(res.data);
      });
    },
    cached() {
      // eslint-disable-next-line no-console
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      // eslint-disable-next-line no-console
      console.log('New content is downloading.');
    },
    updated() {
      // eslint-disable-next-line no-console
      console.log('New content is available; please refresh.');
      alert(VueI18n.t('new_version_KiratikApp_is_available,_please_close_and_reopen_app'));
    },
    offline() {
      // eslint-disable-next-line no-console
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });
}
