// import { AxiosResponse } from 'axios';
import { IGetPlanningListFORes } from '@/interfaces/FoodOffice/IGetPlanningListFORes';
import { IPlanningList } from '@/interfaces/IPlanningList';
import PlanningListItemFactory from '@/factories/PlanningListItemFactory';

export default class PlanningListFactory {
  public static create(getPlanningListRes: IGetPlanningListFORes): IPlanningList {
    const count = getPlanningListRes.total;
    const object = {
      count,
      items: []
    } as IPlanningList;

    if (count > 0) {
      const { data } = getPlanningListRes;
      for (let i = 0; i < count; i += 1) {
        const item = PlanningListItemFactory.create(data[i]);
        object.items.push(item);
      }
    }
    return object;
  }
}
