











import Vue from 'vue';
import Factory from '@/services/api/RepositoryFactory';
import { ContactRepository } from '@/services/api/IEntityRepository';
import { mapGetters, mapState, mapMutations } from 'vuex';
import ProductsList from '@/views/Contacts/ContactDetailsView/ProductsCard/ProductsList.vue';
import licenseStore from '@/store/modules/license';

const factory = new Factory();

export default Vue.extend({
  name: 'ProductsCard',
  components: {
    ProductsList
  },
  data() {
    return {
      selected: []
    };
  },
  computed: {
    ...mapState('contact', ['currentContact', 'products']),
    ...mapState('global', ['showLoader']),
    ...mapGetters('contact', ['isProduct']),
    contactsRepository(): ContactRepository {
      return factory.get('contacts') as ContactRepository;
    },
    isOrderAppModuleActive() {
      return licenseStore.isOrderAppModuleActive;
    }
  },
  methods: {
    ...mapMutations('global', ['changeView', 'showLoadingScreen']),
    async setProducts(): Promise<void> {
      if (this.currentContact.links?.products) {
        if (this.isProduct) {
          this.showLoadingScreen(false);
        }
        await this.contactsRepository.getProductsStatistics(this.currentContact.links.products);
      }
    }
  },
  watch: {
    currentContact: {
      handler() {
        this.setProducts();
      },
      immediate: true
    },
    isOrderAppModuleActive() {
      if (!this.isOrderAppModuleActive) {
        this.$router.push('/contacts/search');
      }
    }
  }
});
