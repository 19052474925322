import Axios, { AxiosRequestConfig } from 'axios';
import KACErrHandler from '@/services/errorHandler/KACErrHandler';
import AxiosInterceptor from '../axiosInterceptor';

const axiosConfig: AxiosRequestConfig = {};

const baseURL: string = process.env.VUE_APP_KAC_API;

axiosConfig.baseURL = baseURL;

const AxiosInstance = Axios.create(axiosConfig);
AxiosInstance.defaults.headers.common.Accept = 'application/json';

const axin = new AxiosInterceptor();
const errHandler = new KACErrHandler();
axin.setAxiosInterceptor(AxiosInstance, errHandler);

export default AxiosInstance;
