<template>
  <div class="contact-detail-info">
    <InfoList v-bind:items="itemsWithData" @changeNumber="setPhoneNumber"></InfoList>
    <v-dialog v-model="dialog" max-width="230">
      <v-card>
        <v-card-title>
          {{ this.phoneNumber }}
        </v-card-title>
        <v-card-actions class="d-flex justify-space-around">
          <v-btn color="secondary" @click="openCall" width="100" class="pa-2">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
          <v-btn color="secondary" @click="openSMS" width="100" class="pa-2">
            <v-icon>mdi-message</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable class-methods-use-this */
import { Component, Vue } from 'vue-property-decorator';
import InfoList from './InfoList.vue';

@Component({
  props: {
    contact: Object
  },
  components: {
    InfoList
  }
})
export default class Info extends Vue {
  dialog = false;

  currentContact = {};

  contactsRepository = this.$repositoryFactory.get('contacts');

  phoneNumber = '';

  get items() {
    return [
      {
        title: 'company',
        method: this.routeToContact,
        value: [this.actualContact.parent_contact_name],
        icon: 'mdi-domain'
      },
      {
        title: 'phone',
        method: this.openDialog,
        value: [this.actualContact.phone, this.actualContact.mobile],
        icon: 'mdi-phone'
      },
      {
        title: 'email',
        method: this.openMail,
        value: [this.actualContact.email],
        icon: 'mdi-email'
      },
      {
        title: 'address',
        method: this.openMap,
        value: [this.actualContact.additional_info, this.actualContact.street, `${this.actualContact.zipcode} ${this.actualContact.city}`],
        icon: 'mdi-map-marker'
      },
      {
        title: 'id',
        value: [this.actualContact.contact_id],
        icon: 'mdi-key'
      }
    ];
  }

  get actualContact() {
    return this.contact ? this.contact : {};
  }

  get itemsWithData() {
    return this.items.filter(info => {
      info.value = info.value.filter(x => x);
      return info.value.length;
    });
  }

  openDialog() {
    this.dialog = true;
  }

  setPhoneNumber(number) {
    this.phoneNumber = number;
  }

  openCall() {
    this.dialog = false;
    const tel = this.prepareTelNo(this.phoneNumber);
    window.open(`tel:${tel}`, '_self');
  }

  openSMS() {
    this.dialog = false;
    const tel = this.prepareTelNo(this.phoneNumber);
    window.open(`sms:${tel}`, '_self');
  }

  openMail() {
    window.open(`mailto:${this.actualContact.email}`, '_self');
  }

  openMap() {
    const parameters = encodeURIComponent(`${this.actualContact.street}, ${this.actualContact.city}, ${this.actualContact.zipcode}`);
    const url = `https://www.google.com/maps/search/?api=1&query=${parameters}`;

    window.open(url);
  }

  prepareTelNo(telNo) {
    return telNo.replace('/', '');
  }

  routeToContact() {
    this.$router.push(`/contacts/contact_detail/${this.actualContact.parent_contact_id}/detail`);
  }
}
</script>

<style scoped />
