var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-list-item',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-list-item-subtitle',{staticClass:"text--brand-black"},[_vm._v(_vm._s(_vm.product.name)+" ( "+_vm._s(_vm.product.product_id)+" ) ")]),(_vm.showCart)?_c('button',{on:{"click":_vm.redirectToCreateOrder}},[_c('v-img',{attrs:{"src":require("@/assets/icon/cart.png")}})],1):_vm._e()],1),(_vm.isProductPriceLoaded)?_c('v-container',[_c('v-row',_vm._l((_vm.productDetails),function(ref,index){
var icon = ref.icon;
var value = ref.value;
var unit = ref.unit;
return _c('v-col',{key:index},[_c('ProductProperty',{attrs:{"icon":icon,"value":value,"unit":unit}})],1)}),1),_c('v-row',[_vm._l((_vm.productAverage),function(ref,index){
var icon = ref.icon;
var value = ref.value;
var unit = ref.unit;
return _c('v-col',{key:index},[_c('ProductProperty',{attrs:{"icon":icon,"value":value,"unit":unit}})],1)}),_c('v-col',[_c('ProductProperty',{attrs:{"icon":_vm.productPrice.icon,"value":_vm.productPrice.value}})],1)],2)],1):_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}}),_c('v-list-item',{attrs:{"dense":""}},[_c('span',{staticClass:"key brand-caption"},[_vm._v(_vm._s(_vm.$t('last_order')))]),_c('span',{staticClass:"brand-caption ml-6"},[_vm._v(" "+_vm._s(_vm.$t('last_order_details', { lastAmount: _vm.lastAmount, lastPrice: _vm.lastPrice, lastPriceDate: _vm.lastPriceDate }))+" ")])])],1),_c('v-divider',{staticClass:"mx-3"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }