






































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import convertDate from '@/utils/ConvertDate';
import ProductViewDetailsRow from '@/views/Orders/ProductView/ProductViewDetailsRow.vue';
import Factory from '@/services/api/RepositoryFactory';
import { ContactRepository } from '@/services/api/IEntityRepository';
import { ProductViewDetails } from './types';

const factory = new Factory();

export default Vue.extend({
  name: 'ProductViewDetails',
  props: {
    sellUnit: {
      type: String
    },
    price: {
      type: String
    },
    amount: {
      type: String
    },
    date: {
      type: String
    },
    amountRules: {
      type: Array
    },
    priceRules: {
      type: Array
    },
    lastPrice: {
      type: Number
    }
  },

  components: {
    ProductViewDetailsRow
  },
  data() {
    return {
      deliveryDates: [],
      today: new Date().toISOString().substr(0, 10),
      showCalendar: false
    } as ProductViewDetails;
  },
  computed: {
    ...mapState('global', ['loadingCounter']),
    ...mapGetters('contact', ['getSingleProduct']),
    accountId(): string {
      const { account_id: accountId } = this.$route.query;
      return typeof accountId === 'string' ? accountId : '';
    },
    productId(): string {
      const { product_id: productId } = this.$route.query;
      return typeof productId === 'string' ? productId : '';
    },
    defaultAmount(): string {
      const productStat = this.getSingleProduct(this.productId);
      const amount = productStat ? productStat.last_amount : '';
      return amount ? amount.replace(/\D+/g, '') : this.amount;
    },
    differentPrice(): boolean {
      const currentPrice = parseFloat(this.price);
      return this.lastPrice !== currentPrice;
    },
    contactRepository(): ContactRepository {
      return factory.get('contacts') as ContactRepository;
    },
    formatDate(): string {
      return convertDate(this.date);
    }
  },
  methods: {
    disableDates(val: string): boolean {
      return this.deliveryDates.indexOf(val) !== -1;
    },
    setPrice(val: string): void {
      this.$emit('setPrice', val);
    },
    setAmount(val: string): void {
      this.$emit('setAmount', val);
    },
    setDate(val: string): void {
      this.$emit('setDate', new Date(val).toISOString());
    },
    setUnit(val: string): void {
      this.$emit('setUnit', val);
    },
    setLastPrice(): void {
      this.setPrice(`${this.lastPrice}`);
    },
    async getDeliveryDates(count?: string): Promise<void> {
      const {
        data: { next_delivery_dates: dates }
      } = await this.contactRepository.getDeliveryDates(this.accountId, count);
      const newDates = dates.map((item: string) => item.substring(0, 10));
      this.deliveryDates = newDates;
    }
  },
  watch: {
    showCalendar: {
      handler(value: boolean) {
        if (value && this.deliveryDates.length === 0) {
          this.getDeliveryDates();
        }
      }
    }
  }
});
