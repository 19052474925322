<template>
  <div class="note">
    <NoteForm />
    <NotesList :notes="notes" :contactId="currentContact.id" />
  </div>
</template>

<script>
import noteStore from '@/store/modules/note';
import Factory from '@/services/api/RepositoryFactory';
import { mapState } from 'vuex';
import Vue from 'vue';
import NoteForm from './NoteForm.vue';
import NotesList from './NotesList.vue';

const factory = new Factory();

export default Vue.extend({
  name: 'ContactDetailNotes',
  components: {
    NotesList,
    NoteForm
  },
  computed: {
    ...mapState('contact', ['currentContact']),
    notesRepository() {
      return factory.get('notes');
    },
    notes() {
      return noteStore.all;
    }
  },
  methods: {
    getNotes() {
      this.notesRepository.get(this.currentContact.id);
    },
    addNotes(newName) {
      const payload = {
        title: newName.title,
        text: newName.text,
        user: this.user
      };

      const callback = data => {
        data.data.unshift(payload);
        return data;
      };

      noteStore.update_notes(payload);

      this.$store.dispatch('pwaService/modifyResponse', {
        // update response in storage PWA
        path: `notes?contact_id=${this.currentContact.id}`,
        storageName: 'api-cache',
        callback
      });

      const note = {
        title: newName.title,
        text: newName.text
      };
      this.notesRepository.create(note, this.currentContact.id).then(() => {
        this.getNotes();
      });
    }
  },
  mounted() {
    this.getNotes();
  },
  watch: {
    currentContact() {
      this.getNotes();
    }
  }
});
</script>
