<template>
  <div class="img-component" :style="dynamicStyle"></div>
</template>

<script>
export default {
  name: 'ImageComponent',
  props: {
    iri: { type: String, required: true }
  },
  data() {
    return {
      link: ''
    };
  },
  computed: {
    linkComputed() {
      return this.link;
    },
    dynamicStyle() {
      return {
        backgroundImage: `url('${this.linkComputed}')`
      };
    }
  },
  methods: {
    async getLink() {
      this.$kacRepositoryFactory
        .get('slide')
        .getImgUrl(this.iri)
        .then(contentUrl => {
          this.link = contentUrl;
        });
    }
  },
  watch: {
    iri() {
      this.getLink();
    }
  },
  created() {
    this.getLink();
  }
};
</script>

<style scoped>
.img-component {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
</style>
