



























import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import RepositoryFactory from '@/services/api/RepositoryFactory';
import { ContactRepository } from '@/services/api/IEntityRepository';
import EmployeeList from '../../../../components/Common/ContactList.vue';
import Info from './Info.vue';

const factory = new RepositoryFactory();

export default Vue.extend({
  name: 'ContactDetail',
  components: {
    Info,
    EmployeeList
  },
  props: ['id'],
  computed: {
    ...mapState('contact', ['currentContact', 'children']),
    ...mapGetters('contact', ['isContact']),
    contactsRepo(): ContactRepository {
      return factory.get('contacts') as ContactRepository;
    }
  },
  methods: {
    getChildren(): void {
      if (this.id) {
        this.contactsRepo.getChildren(this.id);
      }
    }
  },
  created() {
    this.getChildren();
  },
  watch: {
    id() {
      this.getChildren();
    }
  }
});
