







import Vue from 'vue';

export default Vue.extend({
  name: 'ProductProperty.vue',
  props: ['icon', 'value', 'unit']
});
