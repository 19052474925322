import { GetPermissions, GetPermissionResponse, PermissionRepository } from '@/services/api/IEntityRepository';
import { AxiosResponse } from 'axios';
import globalStore from '@/store/modules/global';
import Axios from './Repository';

class PermissionsRepository implements PermissionRepository {
  resource = '/permissions';

  async get(params: GetPermissions): Promise<AxiosResponse<GetPermissionResponse> | void> {
    const initParams = `?user=${params.user}&context=${params.context}&action_type=${params.action_type}`;

    try {
      const res = await Axios.get(`${this.resource}${initParams}`);
      const { allowed } = res.data;
      globalStore.setPermission(allowed);
    } catch (error) {
      globalStore.setPermission(false);
    }
  }
}

export default new PermissionsRepository();
