






















import Vue from 'vue';

export default Vue.extend({
  name: 'Popup',
  props: {
    show: {
      type: Boolean
    },
    headline: {
      type: String
    },
    cancelButton: {
      type: Boolean
    },
    additionalText: {
      type: String
    },
    continueOrdering: {
      type: Boolean
    },
    processOrder: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    orderConfirmed: {
      type: Boolean
    }
  },
  methods: {
    confirm(): void {
      this.$emit('confirm');
    },
    cancel(): void {
      this.$emit('cancel');
    },
    continueShopping(): void {
      this.$emit('continueOrdering');
    }
  }
});
