/* eslint-disable camelcase */
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';

@Module({
  namespaced: true,
  name: 'global',
  dynamic: true,
  store,
  stateFactory: true
})
class Global extends VuexModule {
  online = true;

  detailPage = false;

  nestedRoute = false;

  current_title = '';

  current_view = 'search';

  searchInputIsVisible = true;

  contactDetailMenuIsVisible = false;

  loadingCounter = 0;

  cloudDrawer = false;

  filterDrawer = false;

  showLoader = false;

  showError = false;

  errorText = '';

  errorFrom = '';

  permissionTag = '';

  permission = false;

  @Mutation
  setErrorFrom(payload: string) {
    this.errorFrom = payload;
  }

  @Mutation
  setErrorText(payload: string) {
    this.errorText = payload;
  }

  @Mutation
  changeErrorStatus(payload: boolean) {
    this.showError = payload;
  }

  @Mutation
  setNestedRoute(payload: boolean) {
    this.nestedRoute = payload;
  }

  @Mutation
  showCloudDrawer(payload: boolean) {
    this.cloudDrawer = payload;
  }

  @Mutation
  changeTitle(payload: string) {
    this.current_title = payload;
  }

  @Mutation
  changeView(payload: string) {
    this.current_view = payload;
  }

  @Mutation
  changeConnectionStatus(payload: boolean) {
    this.online = payload;
  }

  @Mutation
  loadDetailPage(payload: boolean) {
    this.detailPage = payload;
  }

  @Mutation
  showSearchInput() {
    this.searchInputIsVisible = true;
  }

  @Mutation
  hideSearchInput() {
    this.searchInputIsVisible = false;
  }

  @Mutation
  showContactDetailMenu() {
    this.contactDetailMenuIsVisible = true;
  }

  @Mutation
  hideContactDetailMenu() {
    this.contactDetailMenuIsVisible = false;
  }

  @Mutation
  increaseLoadingCounter() {
    this.loadingCounter += 1;
  }

  @Mutation
  decreaseLoadingCounter() {
    this.loadingCounter -= 1;
  }

  @Mutation
  showFilterDrawer(payload: boolean) {
    this.filterDrawer = payload;
  }

  @Mutation
  showLoadingScreen(payload: boolean) {
    this.showLoader = payload;
  }

  @Mutation
  setPermissionTag(payload: string) {
    this.permissionTag = payload;
  }

  @Mutation
  setPermission(payload: boolean) {
    this.permission = payload;
  }
}

export default getModule(Global);
