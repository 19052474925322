/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueI18n from '@/i18n';
import { AxiosError } from 'axios';
import global from '@/store/modules/global';

abstract class baseErrHandler {
  abstract prepareError: (status: number, message: string) => string;

  abstract errorStatus: (error: AxiosError) => number;

  abstract errorMessage: (error: AxiosError) => string;

  abstract prefix: string;

  handle(error: AxiosError): void {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // eslint-disable-next-line no-console
      global.changeErrorStatus(true);
      global.setErrorFrom(this.prefix);
      global.setErrorText(this.prepareError(this.errorStatus(error), this.errorMessage(error)));
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // eslint-disable-next-line no-console
      //   alert(this.prefix + this.prepareError(error.request.status, error.request.message));
      // eslint-disable-next-line no-console
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      alert(VueI18n.t('Unknown Error Occured. Server response not received. Please contact with administrator'));
      // eslint-disable-next-line no-console
      console.log('Error', this.errorMessage(error));
    }
  }

  getErrorMessage = (errorStatus: number, errorMessage: string): string => {
    switch (errorStatus) {
      case 0:
        return 'No connection to the network can be established, please check your network settings';
      case 400:
        return 'Please contact with KiratikApp administrator. Error: 400 Bad Request';
      case 401:
        Vue.prototype.$authService.logout();
        return 'Invalid user or password';
      case 403:
        Vue.prototype.$authService.logout();
        return 'Login timeout, Please log in.';
      case 404:
        return 'Not Found!';
      case 408:
        return '408';
      case 422:
        return errorMessage;
      case 500:
        return 'Please contact with KiratikApp administrator. Error: 500 Server response not received.';
      case 504:
        return 'FoodOffice API is not reachable. Please try again later or contact support.';
      default:
        return `${errorStatus}`;
    }
  };
}

export default baseErrHandler;
