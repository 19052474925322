import { actionTree } from 'typed-vuex';
import initalGetters from '@/store/modules/order/getters';
import mutations from '@/store/modules/order/mutations';
import { Order } from '@/services/api/ordersRepository/types';
import { Product } from '@/services/api/productsRepository/types';
import { IOrderDraft } from '@/store/modules/order/types';
import initialState from './state';

const actions = actionTree(
  { state: initialState, mutations, getters: initalGetters },
  {
    setOrder({ commit, getters }, payload: Order) {
      const orderId: string = payload.id;
      const exist = getters.getOrderById(orderId);

      if (exist) {
        commit('UPDATE_ORDER', payload);
      } else {
        commit('SET_ORDER', payload);
      }
    },
    setOrders({ commit }, payload: Order[]) {
      commit('SET_ORDERS', payload);
    },
    showOrderDetail({ commit }) {
      commit('SET_ORDER_DETAIL', true);
    },
    hideOrderDetail({ commit }) {
      commit('SET_ORDER_DETAIL', false);
    },
    addProductsToOrder({ commit }, payload: { products: Product[]; orderId: string }) {
      commit('SET_ORDERS_PRODUCTS', payload);
    },
    setOrderDraft({ commit }, payload: IOrderDraft): void {
      commit('SET_ORDER_DRAFT', payload);
    },
    clearOrderDraft({ commit }): void {
      commit('CLEAR_ORDER_DRAFT');
    },
    setOrderDraftItemDetails(
      { commit },
      payload: {
        productId: string;
        internalInfo: string;
        additionalInfo: string;
      }
    ): void {
      commit('SET_ORDER_DRAFT_ITEM_DETAILS', payload);
    },
    setOrderDraftItemPrice(
      { commit },
      payload: {
        productId: string;
        price: number;
      }
    ): void {
      commit('SET_ORDER_DRAFT_ITEM_PRICE', payload);
    },
    setOrderDraftDeliveryDate({ commit }, payload: string): void {
      commit('SET_ORDER_DRAFT_DELIVERY_DATE', payload);
    },
    setOrderDraftAdditionalInfo({ commit }, payload: string): void {
      commit('SET_ORDER_DRAFT_ADDITIONAL_INFO', payload);
    },
    setOrderDraftFromLS({ commit }, payload: IOrderDraft): void {
      commit('SET_ORDER_DRAFT_FROM_LS', payload);
    }
  }
);

export default actions;
