import { mutationTree } from 'typed-vuex';
import { Order } from '@/services/api/ordersRepository/types';
import { Product } from '@/services/api/productsRepository/types';
import Vue from 'vue';
import OrderDraftService from '@/services/Order/OrderDraftService';
import NumberUtils from '@/utils/NumberUtils';
import initialState from './state';
import { IDates, IOrderDraft } from './types';

const mutations = mutationTree(initialState, {
  SET_ORDER(state, payload: Order) {
    state.orders.push(payload);
  },
  UPDATE_ORDER(state, payload: Order) {
    const differentOrders = state.orders.filter(order => order.id !== payload.id);
    state.orders = [...differentOrders, payload];
  },
  REMOVE_ORDER(state, payload: string) {
    state.orders = state.orders.filter(order => order.id !== payload);
  },
  SET_ORDERS(state, payload: Order[]) {
    state.orders.push(...payload);
  },
  SET_ORDER_DETAIL(state, payload: boolean) {
    state.orderDetail = payload;
  },
  SET_ORDERS_PRODUCTS(state, payload: { products: Product[]; orderId: string }) {
    const { orderId, products } = payload;
    const orderIndex = state.orders.findIndex(order => order.id === orderId);
    Vue.set(state.orders[orderIndex], 'products', products);
  },
  SET_OFFSET(state, payload: number) {
    state.offset = payload;
  },
  CLEAR_ORDERS(state) {
    state.orders.length = 0;
  },
  SET_STATUS(state, payload: string) {
    state.status = payload;
  },
  SET_DATES(state, payload: IDates) {
    state.dates = payload;
  },
  CLEAR_DATES(state) {
    state.dates = { dateFrom: '', dateTo: '' };
  },
  SET_COUNT(state, payload: number) {
    state.count = payload;
  },
  CLEAR_COUNT(state) {
    state.count = 0;
  },
  SET_SORTBY(state, payload: string) {
    state.sortBy = payload;
  },
  SET_SORTDESC(state, payload: boolean) {
    state.sortDesc = payload;
  },
  SET_ORDER_DRAFT(state, payload: IOrderDraft) {
    state.orderDraft = payload;
  },
  SET_ORDER_DRAFT_DELIVERY_DATE(state, payload: string) {
    const { orderDraft } = state;
    if (orderDraft) {
      orderDraft.deliveryDate = payload;
    }
  },
  SET_ORDER_DRAFT_ADDITIONAL_INFO(state, payload: string) {
    const { orderDraft } = state;
    if (orderDraft) {
      orderDraft.additionalInfo = payload;
    }
  },
  CLEAR_ORDER_DRAFT(state) {
    const { orderDraft } = state;
    if (orderDraft) {
      const orderDraftItems = orderDraft.orderItems;
      if (orderDraftItems && orderDraftItems.length > 0) {
        // Reset/Clear values
        orderDraftItems.forEach(item => {
          item.amount = 0;
          item.internalInfo = null;
          item.additionalInfo = null;
          if (item.sellUnit === 'kg') {
            item.formattedAmount = '0,000';
            item.rawAmount = '0,000';
          } else {
            item.formattedAmount = '0';
            item.rawAmount = '0';
          }
        });
      }
    }
  },
  SET_ORDER_DRAFT_ITEM_DETAILS(state, payload: { productId: string; internalInfo: string; additionalInfo: string }) {
    if (state.orderDraft && state.orderDraft.orderItems) {
      const orderDraftItemIndex = OrderDraftService.getIndexOfOrderDraftItem(state.orderDraft.orderItems, payload.productId);
      if (orderDraftItemIndex >= 0) {
        state.orderDraft.orderItems[orderDraftItemIndex].internalInfo = payload.internalInfo;
        state.orderDraft.orderItems[orderDraftItemIndex].additionalInfo = payload.additionalInfo;
      } else {
        // eslint-disable-next-line no-console
        console.log("SET_ORDER_DRAFT_ITEM_DETAILS failed! Can't find orderDraftItemIndex!");
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('SET_ORDER_DRAFT_ITEM_DETAILS failed!');
    }
  },
  SET_ORDER_DRAFT_ITEM_PRICE(state, payload: { productId: string; price: number }) {
    if (state.orderDraft && state.orderDraft.orderItems) {
      const orderDraftItemIndex = OrderDraftService.getIndexOfOrderDraftItem(state.orderDraft.orderItems, payload.productId);
      if (orderDraftItemIndex >= 0) {
        state.orderDraft.orderItems[orderDraftItemIndex].price = payload.price;
        state.orderDraft.orderItems[orderDraftItemIndex].rawPrice = String(payload.price);
        state.orderDraft.orderItems[orderDraftItemIndex].formattedPrice = NumberUtils.castPriceFromDecimalNumberToString(payload.price);
      } else {
        // eslint-disable-next-line no-console
        console.log("SET_ORDER_DRAFT_ITEM_PRICE failed! Can't find orderDraftItem with this index!");
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('SET_ORDER_DRAFT_ITEM_PRICE failed!');
    }
  },
  SET_ORDER_DRAFT_FROM_LS(state, payload: IOrderDraft) {
    state.orderDraft = payload;
  }
});

export default mutations;
