/* eslint-disable camelcase */
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import lsService from '@/services/localStorage/lsService';
import { AuthData } from '../models';

@Module({
  namespaced: true,
  name: 'auth',
  dynamic: true,
  store,
  stateFactory: true
})
class AuthModule extends VuexModule {
  status = '';

  token: string = lsService.getItem('tokenLm') || '';

  baseUrl: string = lsService.getItem('baseURL') || '';

  user = '';

  get isLoggedIn(): boolean {
    return !!this.token;
  }

  get hasBaseUrl(): boolean {
    return !!this.baseUrl;
  }

  get authStatus(): string {
    return this.status;
  }

  @Mutation
  auth_request() {
    this.status = 'loading';
  }

  @Mutation
  auth_success(authData: AuthData): void {
    this.status = 'success';
    this.token = authData.token;
    this.user = authData.user;
  }

  @Mutation
  auth_error() {
    this.status = 'error';
  }

  @Mutation
  logout() {
    this.status = '';
    this.token = '';
    this.user = '';
  }
}

export default getModule(AuthModule);
