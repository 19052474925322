import lsService from '@/services/localStorage/lsService';

function isRequestMatchToApiVersion(requiredApiVersion: string): boolean {
  const apiVersion = lsService.getItem('apiVersion');
  if (apiVersion) {
    const apiVersionArray = apiVersion.split('.');
    const apiVersionArrayRequest = requiredApiVersion.split('.');
    if (Number(apiVersionArray[0]) >= Number(apiVersionArrayRequest[0]) && Number(apiVersionArray[1]) >= Number(apiVersionArrayRequest[1])) {
      return true;
    }
  }
  return false;
}

export default isRequestMatchToApiVersion;
