/* eslint-disable no-shadow,no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */

const state = {
  storageName: 'api-cache',
  responsePath: ''
};

const mutations = {
  // eslint-disable-next-line no-shadow
  addResponsePath(state, data) {
    state.responsePath = data;
  }
};

const actions = {
  replayQueueRequests() {
    // eslint-disable-next-line no-prototype-builtins
    if (navigator.serviceWorker.hasOwnProperty('controller')) {
      // call message event in service workers to replay request in indexedDB queue
      navigator.serviceWorker.controller.postMessage('replayRequests');
    }
  },
  modifyResponse({ dispatch }, payload) {
    const path = `${process.env.VUE_APP_BASE_URL}/${payload.path}`;
    caches.has(payload.storageName).then(boolean => {
      if (boolean) {
        caches
          // open storage with response
          .open(payload.storageName)
          .then(cache => {
            cache
              // match response with cache response and get it
              .match(path)
              .then(response => {
                // create new response
                const newResponse = dispatch('createNewResponse', {
                  response,
                  callback: payload.callback
                });
                return newResponse;
              })
              .then(newResponse => {
                // replace response with new data
                cache.put(path, newResponse);
              });
          });
      }
    });
  },
  isStorage({ state }, callback) {
    caches
      .has(state.storageName)
      .then(boolean => {
        if (boolean) {
          callback();
        } else {
          alert(`${state.storageName} doesn't exist!`);
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createNewResponse({ state }, payload) {
    const init = {
      status: 200,
      statusText: payload.response.statusText,
      headers: payload.response.headers
    };
    return payload.response.json().then(data => {
      // change data (add, delete, update, create)
      const newData = payload.callback(data);
      return new Response(JSON.stringify(newData), init);
      // create new response
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
