import { IEntityRepository } from '@/services/apiKAC/IEntityRepository';
import slideRepository from '@/services/apiKAC/slideRepository';
import LicenceRepository from './licenceRepository';
import IntroRepositoryClass from './introRepository';

export default class RepositoryFactory {
  repositories: { [index: string]: IEntityRepository } = {
    licence: LicenceRepository,
    intro: IntroRepositoryClass,
    slide: slideRepository
  };

  get(name: string): IEntityRepository {
    return this.repositories[name];
  }
}
