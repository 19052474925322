


















import Vue from 'vue';
import { FilterInterface } from './types';

export default Vue.extend({
  name: 'FilterDrawerRadios',
  props: {
    selected: {
      type: String,
      required: true
    },
    filters: {
      type: Array as () => FilterInterface[],
      required: true
    }
  },
  methods: {
    changeSelected(value: string): void {
      this.$emit('changeSelected', value);
    }
  }
});
