









import Vue from 'vue';
import packageJson from '@/../package.json';

export default Vue.extend({
  name: 'Footer',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    currentVersion() {
      return packageJson.version;
    }
  }
});
