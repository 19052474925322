import Vue from 'vue';

// eslint-disable-next-line import/prefer-default-export
export const formatToEuro = (value: number | string): string => {
  const convertedToNumber = +value;

  if (isNaN(convertedToNumber)) {
    return '';
  }

  const preparedValue = convertedToNumber
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  return `${preparedValue} €`;
};

Vue.filter('formatToEuro', formatToEuro);
