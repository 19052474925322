/* eslint-disable class-methods-use-this,no-unused-vars,@typescript-eslint/no-explicit-any */
import Axios from './Repository';
import { IEntityRepository } from './IEntityRepository';

class SlideRepository implements IEntityRepository {
  resource = 'api/slides';

  async get(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${this.resource}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getImgUrl(iri: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get(`${iri}`)
        .then(res => {
          resolve(Axios.defaults.baseURL + res.data.contentUrl);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export default new SlideRepository();
