








import Vue from 'vue';
import eventBus from '@/services/eventBus';

export default Vue.extend({
  name: 'ContactsFilter',
  components: {
    FilterDrawerRadios: () => import('@/components/Common/FilterDrawer/FilterDrawerRadios.vue')
  },
  data() {
    return {
      contactTag: 'show-all',
      contactType: 'show-all',
      contactsFilter: [
        {
          title: 'show_all',
          name: '',
          value: 'show-all',
          icon: 'mdi-account-multiple'
        },
        {
          title: 'customer',
          name: 'customer',
          value: 'customer',
          icon: 'mdi-basket'
        },
        {
          title: 'employee',
          name: 'employee',
          value: 'employee',
          icon: 'mdi-account'
        },
        {
          title: 'supplier',
          name: 'supplier',
          value: 'supplier',
          icon: 'mdi-truck'
        }
      ],
      contactsType: [
        { title: 'show_all', value: 'show-all', name: '' },
        {
          title: 'only_person',
          value: 'additional_contact',
          name: 'additional_contact'
        },
        {
          title: 'only_companies',
          value: 'contact',
          name: 'contact'
        }
      ]
    };
  },
  methods: {
    changeContactTag(value: string): void {
      this.contactTag = value;
      const name = value === 'show-all' ? '' : value;
      eventBus.$emit('tagChanged', { name, checked: true });
    },
    changeContactType(value: string): void {
      this.contactType = value;
      const name = value === 'show-all' ? '' : value;
      eventBus.$emit('typeChanged', { name, checked: true });
    }
  }
});
