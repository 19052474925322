






import Vue from 'vue';
import globalStore from '@/store/modules/global';
import Factory from '@/services/api/RepositoryFactory';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ContactRepository } from '@/services/api/IEntityRepository';
import lsService from '@/services/localStorage/lsService';

const factory = new Factory();

export default Vue.extend({
  name: 'ContactDetailsView',
  props: ['id'],
  computed: {
    ...mapState('contact', ['currentContact']),
    ...mapGetters('contact', ['isContact']),
    contactsRepository(): ContactRepository {
      return factory.get('contacts') as ContactRepository;
    }
  },
  methods: {
    ...mapActions('contact', ['resetState']),
    async setContact() {
      await this.contactsRepository.getOne(this.id);
      if (this.isContact) {
        globalStore.changeTitle(this.currentContact.description);
      }
    }
  },
  watch: {
    id: {
      handler(val: string) {
        globalStore.hideSearchInput();
        if (this.currentContact.id !== val) {
          lsService.setItem('currentContactId', this.id);
          this.setContact();
        }
      },
      immediate: true
    }
  },
  beforeRouteLeave(to, from, next) {
    globalStore.hideContactDetailMenu();
    if (to.name !== 'createOrder') {
      this.resetState();
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    globalStore.showContactDetailMenu();
    next();
  }
});
