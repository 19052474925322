/* eslint-disable class-methods-use-this */
import AppModulesEnums from '@/enums/AppModulesEnums';
import { Intro } from '@/store/models';

/**
 * Local Storage Service
 */
class LsService {
  prefix = 'kiratikMC';

  localeName = 'locale';

  seenIntrosName = 'seenIntros';

  introsName = 'intros';

  setItem(name: string, value: string) {
    localStorage.setItem(`${this.prefix}_${name}`, value);
  }

  getItem(name: string): string | null {
    return localStorage.getItem(`${this.prefix}_${name}`);
  }

  removeItem(name: string) {
    localStorage.removeItem(`${this.prefix}_${name}`);
  }

  clearStorage() {
    localStorage.clear();
  }

  clearStorageWithoutEmail() {
    this.removeItem('userId');
    this.removeItem('userRole');
    this.removeItem('apiUrl');
    this.removeItem('modules');
    this.removeItem('seenIntros');
    this.removeItem('token');
    this.removeItem('user');
    this.removeItem('intros');
    this.removeItem('apiVersion');
    this.removeItem('dbVersion');
  }

  setIntros(intros: Intro[]) {
    this.setItem(this.introsName, JSON.stringify(intros));
  }

  getIntros(): Intro[] | null {
    return this.getItem(this.introsName) ? JSON.parse(this.getItem(this.introsName) as string) : null;
  }

  setSeenIntros(introIds: number[]) {
    this.setItem(this.seenIntrosName, JSON.stringify(introIds));
  }

  cleanSeenIntros() {
    this.removeItem(this.seenIntrosName);
  }

  getSeenIntros(): number[] {
    return this.getItem(this.seenIntrosName) ? JSON.parse(this.getItem(this.seenIntrosName) as string) : [];
  }

  setLocale(value: string) {
    this.setItem(this.localeName, value);
  }

  getLocale(): string {
    return this.getItem(this.localeName) || process.env.VUE_APP_I18N_LOCALE;
  }

  getAppModules(): string[] {
    let modules = [];
    const localStorageModules = this.getItem('modules');

    if (localStorageModules) {
      modules = JSON.parse(localStorageModules);
      return modules;
    }

    return [];
  }

  isOrderAppModuleActive(): boolean {
    const localStorageModules = this.getAppModules();
    return localStorageModules.includes(AppModulesEnums.ORDER);
  }
}

export default new LsService();
