import { IPlanningList } from '@/interfaces/IPlanningList';
import { IPlanningListItem } from '@/interfaces/IPlanningListItem';

// Types - begin
type TPlanningListByProductId = {
  index: string;
  indexType: string; // product-id // TODO: could be as a enums/PlanningList/IndexType enum?
  groupName: string;
  items: IPlanningListItem[];
  sumQuantity: number;
  sumQuantityToLocaleString: string;
  sumWeight: number;
  sumWeightToLocaleString: string;
};

type TPlanningListByProductCategory = {
  index: string;
  indexType: string; // 'product-category' // TODO: could be as a enums/PlanningList/IndexType enum?
  groupName: string;
  items: TPlanningListByProductId[];
  sumQuantity: number;
  sumQuantityToLocaleString: string;
  sumWeight: number;
  sumWeightToLocaleString: string;
};

type TPlanningListByDate = {
  index: string;
  indexType: string; // date // TODO: could be as a enums/PlanningList/IndexType enum?
  items: TPlanningListByProductCategory[];
};

type TGroupedPlanningList = {
  items: TPlanningListByDate[];
};

export default class PlanningListMapperService {
  public static groupBy(inputPlanningList: IPlanningList): TGroupedPlanningList {
    const groupedPlanningList: TGroupedPlanningList = {
      items: []
    };

    inputPlanningList.items.forEach(item => {
      // Prepare PlanningListByDateType
      const date: string = item.deliveryDate;
      let isGroupByDateExist = groupedPlanningList.items.find(v => v.index === date);
      if (!isGroupByDateExist) {
        const newGroupByDate: TPlanningListByDate = {
          index: date,
          indexType: 'date',
          items: []
        };
        groupedPlanningList.items.push(newGroupByDate);
        isGroupByDateExist = newGroupByDate;
      }

      // Prepare PlanningListByProductCategoryType
      let isGroupByProductCategoryExist = isGroupByDateExist.items.find(v => v.index === item.productGroupId);
      if (!isGroupByProductCategoryExist) {
        const newGroupByProductCategory: TPlanningListByProductCategory = {
          index: item.productGroupId,
          indexType: 'product-category',
          groupName: `${item.productGroupId} - ${item.productGroupName}`,
          items: [],
          sumQuantity: 0,
          sumQuantityToLocaleString: '',
          sumWeight: 0,
          sumWeightToLocaleString: ''
        };
        isGroupByDateExist.items.push(newGroupByProductCategory);
        isGroupByProductCategoryExist = newGroupByProductCategory;
      }
      isGroupByProductCategoryExist.sumWeight += item.sumWeight;
      isGroupByProductCategoryExist.sumWeightToLocaleString = new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: item.sellUnitName === 'kg' ? 3 : 2,
        maximumFractionDigits: item.sellUnitName === 'kg' ? 3 : 2
      }).format(isGroupByProductCategoryExist.sumWeight);
      isGroupByProductCategoryExist.sumQuantity += item.sumQuantity;
      isGroupByProductCategoryExist.sumQuantityToLocaleString = isGroupByProductCategoryExist.sumQuantity.toLocaleString('de-DE');

      // Prepare PlanningListByProductIdType
      let isGroupByProductIdExist = isGroupByProductCategoryExist.items.find(v => v.index === item.productId);
      if (!isGroupByProductIdExist) {
        const newGroupByProductId: TPlanningListByProductId = {
          index: item.productId,
          indexType: 'product-id',
          groupName: `${item.productId} - ${item.productName}`,
          items: [],
          sumQuantity: 0,
          sumQuantityToLocaleString: '',
          sumWeight: 0,
          sumWeightToLocaleString: ''
        };
        isGroupByProductCategoryExist.items.push(newGroupByProductId);
        isGroupByProductIdExist = newGroupByProductId;
      }

      // TODO: remove duplicates?
      isGroupByProductIdExist.items.push(item);
      isGroupByProductIdExist.sumWeight += item.sumWeight;
      isGroupByProductIdExist.sumWeightToLocaleString = new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: item.sellUnitName === 'kg' ? 3 : 2,
        maximumFractionDigits: item.sellUnitName === 'kg' ? 3 : 2
      }).format(isGroupByProductIdExist.sumWeight);
      isGroupByProductIdExist.sumQuantity += item.sumQuantity;
      isGroupByProductIdExist.sumQuantityToLocaleString = isGroupByProductIdExist.sumQuantity.toLocaleString('de-DE');
    });

    // Sorting
    groupedPlanningList.items = groupedPlanningList.items.sort((obj1, obj2) => {
      if (obj1.index > obj2.index) {
        return 1;
      }
      if (obj1.index < obj2.index) {
        return -1;
      }
      return 0;
    });

    return groupedPlanningList;
  }
}
