import { mutationTree } from 'typed-vuex';
import { ValuesPerMonth, Contact, LivestockTrader, OpenItems, Products } from '@/store/modules/contact/types';
import initialState from './state';

const mutations = mutationTree(initialState, {
  ADD_CONTACT(state, payload: Contact) {
    state.currentContact = payload;
  },
  REMOVE_CONTACT(state) {
    state.currentContact = {};
  },
  ADD_CHILDREN(state, payload: Contact[]) {
    state.children = payload;
  },
  REMOVE_CHILDREN(state) {
    state.children = [];
  },
  ADD_VALUES_PER_MONTH(state, payload: ValuesPerMonth) {
    state.valuesPerMonth = payload;
  },
  REMOVE_VALUES_PER_MONTH(state) {
    state.valuesPerMonth = {
      customer_id: '',
      month_from: '',
      month_to: '',
      values_per_month: [
        {
          month: '',
          total_quantity: '',
          total_weight: '',
          total_value: ''
        }
      ]
    };
  },
  ADD_PRODUCTS(state, payload: Products) {
    state.products = payload;
  },
  REMOVE_PRODUCTS(state) {
    state.products = null;
  },
  ADD_OPEN_ITEMS(state, payload: OpenItems) {
    state.openItems = payload;
  },
  REMOVE_OPEN_ITEMS(state) {
    state.openItems = null;
  },
  ADD_LIVESTOCK_TRADER(state, payload: LivestockTrader) {
    state.livestockTrader = payload;
  },
  REMOVE_LIVESTOCK_TRADER(state) {
    state.livestockTrader = null;
  }
});

export default mutations;
