/* eslint-disable class-methods-use-this */
import licenseStore from '@/store/modules/license';
import { IApplicationLicense } from '@/store/models';
import AppModulesEnums from '@/enums/AppModulesEnums';
import lsService from '@/services/localStorage/lsService';

/**
 * Authorization Service
 */
export default class AuthorizationService {
  private async getAppModules(): Promise<string[]> {
    try {
      const appCode = process.env.VUE_APP_APP_CODE;
      const kiratikAppLicense = (await licenseStore.getLicenseByApp(appCode)) as IApplicationLicense;

      let kiratikAppLicenseModules = [] as string[];
      if (kiratikAppLicense) {
        kiratikAppLicenseModules = kiratikAppLicense.modules;
      }

      return new Promise(resolve => {
        resolve(kiratikAppLicenseModules);
      });
    } catch (error) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }

  public async isModuleGranted(module: AppModulesEnums): Promise<boolean> {
    const storageModules = await this.getAppModules();
    return storageModules.includes(module);
  }

  public async isAppLicenseActive(): Promise<boolean> {
    return lsService.getItem('isLicenseActive') === 'true';
  }
}
