<template>
  <div class="note-form pa-4">
    <v-btn width="100%" v-show="!formActive" @click="formActive = !formActive" color="secondary" class="font-weight-bold">{{ $t('add_note') }}</v-btn>
    <v-form v-show="formActive">
      <v-text-field v-model="input.title" :label="$t('note_title')" filled />
      <v-textarea v-model="input.text" :label="$t('note_text')" filled />
      <v-row no-gutters justify="end">
        <v-btn @click="cancel" color="grey lighten-2" class="grey--text text--darken-1 mr-2">{{ $t('cancel') }}</v-btn>
        <v-btn @click="addNote">{{ $t('add_note') }}</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    title: String
  }
})
export default class NoteForm extends Vue {
  formActive = false;

  input = {
    title: '',
    text: ''
  };

  addNote() {
    if (this.input.title === '') {
      alert(this.$t('please_add_note_title'));
      return;
    }
    if (this.input.text === '') {
      alert(this.$t('please_add_note_text'));
      return;
    }
    this.$parent.addNotes(this.input); // TODO refactor to emit event
    this.cancel();
  }

  cancel() {
    this.formActive = !this.formActive;
    this.input = {};
  }
}
</script>
