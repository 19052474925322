












import Vue from 'vue';

export default Vue.extend({
  name: 'productCardInfo',
  props: {
    product: {
      type: Object,
      required: true
    }
  }
});
