
























import Vue from 'vue';
import NavBottomBtn from '@/views/Contacts/ContactsNavBottom/NavBottomBtn.vue';

export default Vue.extend({
  name: 'ContactsNavBottom',
  components: {
    NavBottomBtn
  }
});
