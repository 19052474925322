


















import Vue from 'vue';
import eventBus from '@/services/eventBus';
import lsService from '@/services/localStorage/lsService';
import globalState from '@/store/modules/global';

export default Vue.extend({
  name: 'NavSearchInput',
  props: ['openDrawer'],
  data() {
    return {
      searchString: ''
    };
  },
  computed: {
    filterDrawer: {
      get() {
        return globalState.filterDrawer;
      },
      set(value: boolean) {
        globalState.showFilterDrawer(value);
      }
    },
    page(): string | undefined | null {
      return this.$route.name;
    },

    cleanedInput(): string {
      return this.searchString.trim().toLowerCase();
    }
  },
  methods: {
    search(): void {
      eventBus.$emit('searchContact', this.cleanedInput);
    },

    removeInputString(): void {
      this.searchString = '';
      this.search();
    },

    listViewCreatedCallback(viewName: string | undefined | null) {
      const cachedSearchStr = lsService.getItem(`searchStr_${viewName}`);
      this.searchString = cachedSearchStr || '';
    }
  },

  created() {
    eventBus.$on('pagination', this.search);
  },

  beforeDestroy() {
    eventBus.$off('pagination', this.search);
  },
  watch: {
    page() {
      this.listViewCreatedCallback(this.page);
    }
  },
  mounted() {
    this.listViewCreatedCallback(this.page);
  }
});
