


































import Vue from 'vue';
import convertDate from '@/utils/ConvertDate';

export default Vue.extend({
  name: 'OpenItem.vue',
  props: ['openItem'],
  methods: {
    formatDate(dateToFormat: Date | string | undefined) {
      return convertDate(dateToFormat);
    }
  }
});
