


























































































import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { ContactRepository, OrdersRepository } from '@/services/api/IEntityRepository';
import { NewOrderPayload, Order } from '@/services/api/ordersRepository/types';
import RepositoryFactory from '@/services/api/RepositoryFactory';
import { ProductWithAverages } from '@/store/modules/contact/types';
import { IOrderDraft } from '@/store/modules/order/types';
import { ItmpOrderDraftItemDetails } from '@/components/Ordering/types';
import OrderDraftService from '@/services/Order/OrderDraftService';
import lsService from '@/services/localStorage/lsService';
import OrderingItem from './OrderingItem.vue';
import OrderingDeliveryDate from './OrderingDeliveryDate.vue';
import OrderingNote from './OrderingNote.vue';

const repositoryFactory = new RepositoryFactory();
const contactsRepository: ContactRepository = repositoryFactory.get('contacts') as ContactRepository;
const orderRepository: OrdersRepository = repositoryFactory.get('order') as OrdersRepository;

export default Vue.extend({
  name: 'Ordering',
  components: {
    OrderingItem,
    OrderingDeliveryDate,
    OrderingNote
  },
  data: () => ({
    formName: 'ordering-form',
    isLoading: true,
    isLoadingCalendar: true,
    isLoadingFullPage: false,
    lastCreatedOrder: {} as Order,
    tmpOrderDraftItemDetails: {
      productId: '',
      internalInfo: '',
      additionalInfo: ''
    } as ItmpOrderDraftItemDetails,

    // Form init data source
    productsData: [] as ProductWithAverages[],

    // Modal/Dialog
    modalOrderCreationConfirm: false,
    modalOrderSuccessfullyCreated: false,

    // Validation and validation rules
    isOrderDraftItemsCountValid: false, // case: is at least one OrderDraftItem has more than 0 to order ?
    isOrderingFormInputsValid: false // case: vue input form fields validation - ex. decimals in price
  }),
  computed: {
    ...mapState('contact', ['currentContact', 'products']),
    ...mapState('order', ['orderDraft']),
    ...mapGetters('contact', ['getProducts', 'isProduct', 'getCustomerId', 'getContactId']),
    ...mapGetters('order', ['getOrderDraft']),

    getIsLoading(): boolean {
      return this.isLoading;
    },
    getIsOrderDraftItemsCountValid(): boolean {
      return this.isOrderDraftItemsCountValid;
    },
    getIsOrderingFormInputsValid(): boolean {
      return this.isOrderingFormInputsValid;
    },
    getIsFormValid(): boolean {
      return (this.getIsOrderingFormInputsValid && this.getIsOrderDraftItemsCountValid) as boolean;
    }
  },
  methods: {
    ...mapMutations('global', ['showLoadingScreen']),
    ...mapActions('order', ['setOrderDraft', 'setOrder', 'clearOrderDraft']),

    async fetchProductsStatisticsFromApi(): Promise<void> {
      if (this.currentContact.links?.products) {
        if (this.isProduct) {
          this.showLoadingScreen(false);
        }
        await contactsRepository.getProductsStatistics(this.currentContact.links.products);
        this.productsData = this.getProducts;
      }
    },

    async initializeOrderDraft(): Promise<void> {
      if (this.currentContact.links?.products) {
        if (this.isProduct) {
          this.showLoadingScreen(false);
        }

        let orderDraft: IOrderDraft = this.getOrderDraft;
        if (!orderDraft && this.getProducts) {
          orderDraft = {
            accountId: this.getCustomerId,
            deliveryDate: '',
            additionalInfo: '',
            orderItems: OrderDraftService.createOrderDraftItemsFromProducts(this.getProducts)
          };
          this.setOrderDraft(orderDraft);
        }
      }
    },

    async createOrder(): Promise<void> {
      this.modalOrderCreationConfirm = true;
    },

    async confirmOrderCreation(): Promise<void> {
      this.modalOrderCreationConfirm = false;
      this.isLoadingFullPage = true;

      const orderDraft = this.getOrderDraft;

      const orderToCreate = {
        account_id: orderDraft.accountId,
        delivery_date: orderDraft.deliveryDate,
        products: OrderDraftService.getOrderDraftItemsForPOSTRequest(orderDraft)
      } as NewOrderPayload;
      const { data } = await orderRepository.create(orderToCreate);

      this.lastCreatedOrder = data;

      this.clearOrderDraft();
      if (!this.getOrderDraft) {
        // Initialize OrderDraft only on first page load
        await this.initializeOrderDraft();
      }

      this.tmpOrderDraftItemDetails = {
        productId: '',
        internalInfo: '',
        additionalInfo: ''
      };

      this.$emit('forceOrderingComponentRerender');

      this.isLoadingFullPage = false;
      this.isOrderDraftItemsCountValid = false;
      this.modalOrderSuccessfullyCreated = true;
    },

    redirectToOrderDetail(item: Order): void {
      this.$router.push(`/orders/${item.id}`);
    },

    redirectToAllOrders(): void {
      this.$router.push('/orders/all');
    }
  },
  watch: {
    currentContact: {
      async handler() {
        await Promise.all([this.fetchProductsStatisticsFromApi()]).then(() => {
          this.initializeOrderDraft();
          this.isLoading = false;
          this.isOrderDraftItemsCountValid = OrderDraftService.isAtLeastOneOrderDraftItemToOrder(this.getOrderDraft);
        });
      },
      immediate: true
    },
    getCustomerId: {
      handler() {
        // Update stored in Vuex OrderDraft with new accountId
        const orderDraft = this.getOrderDraft;
        if (orderDraft) {
          if (this.getCustomerId) {
            orderDraft.accountId = this.getCustomerId;
            this.setOrderDraft(orderDraft);
          }
        }
      },
      immediate: true
    },
    orderDraft: {
      handler(newVal) {
        if (newVal) {
          const user = lsService.getItem('email');
          const currentContactId = lsService.getItem('currentContactId');
          lsService.setItem(`orderDraft_${user}_${currentContactId}`, JSON.stringify(this.orderDraft));
        }
      },
      deep: true
    }
  },
  i18n: {
    messages: {
      en: {
        productsList_loading: 'Loading product list...',
        productsList_empty: 'Sorry. Product list is empty.',
        orderCreationConfirm_dialogTitle: 'Order confirmation',
        orderCreationConfirm_dialogQuestion: 'Are you sure you want to finalize this order?',
        orderCreated_dialogTitle: 'Order created!',
        orderCreated_dialogText: 'Order ({orderId}) was successfully created!',
        orderCreated_dialogBtn_close: 'Close',
        orderCreated_dialogBtn_orderDetails: 'Order list',
        orderDraftItemsCount_dialogText_notValid: 'You have to set amount with value higher than 0 for at least one order item.'
      },
      de: {
        productsList_loading: 'Lade Produktliste...',
        productsList_empty: 'Es konnten keine Produkte gefunden werden.',
        orderCreationConfirm_dialogTitle: 'Bestellbestätigung',
        orderCreationConfirm_dialogQuestion: 'Sind Sie sich sicher, dass sie die Bestellung erstellen wollen?',
        orderCreated_dialogTitle: 'Bestellung erstellt!',
        orderCreated_dialogText: 'Bestellung ({orderId}) wurde erfolgreich erstellt!',
        orderCreated_dialogBtn_close: 'Schließen',
        orderCreated_dialogBtn_orderDetails: 'Bestellungsliste',
        orderDraftItemsCount_dialogText_notValid: 'Sie müssen einen Wert größer 0 für die Menge bei mindestens einem Produkt angeben.'
      }
    }
  }
});
