









import Vue from 'vue';
import globalStore from '@/store/modules/global';
import { mapGetters, mapState } from 'vuex';
import { Tab } from '@/store/modules/contact/types';
import licenseStore from '@/store/modules/license';
import lsService from '@/services/localStorage/lsService';
import isRequestMatchToApiVersion from '@/utils/ApiVersionCheck';

export default Vue.extend({
  name: 'NavTabs',
  computed: {
    ...mapState('contact', ['currentContact']),
    ...mapGetters('contact', ['tabs', 'showNotes', 'showLivestockTrade']),
    online() {
      return globalStore.online;
    },

    detailsTabMenu(): Tab[] {
      const { id, links } = this.currentContact;

      /*
        Description:
        title - link name; if we use i18n it should be the key of translation
        link - define link to these section
        linkExists - check if currentContact contains 'links' for this scope
        showModule - display or not view/content depends on license/module definition in license manager
        availableOffline - [probably no in use; it's not fully supported]
       */

      const tabs = [
        {
          title: 'contact_tab_detail',
          link: `/contacts/contact_detail/${id}/detail`,
          linkExists: !!links?.self,
          showModule: true,
          availableOffline: true
        },
        {
          title: 'contact_tab_notes',
          link: `/contacts/contact_detail/${id}/notes`,
          linkExists: !!links?.notes,
          showModule: this.showNotes,
          availableOffline: false
        },
        {
          title: 'contact_tab_livestock_trade',
          link: `/contacts/contact_detail/${id}/livestock_trade`,
          linkExists: !!links?.livestock_trade,
          showModule: this.showLivestockTrade,
          availableOffline: true
        },
        {
          title: 'contact_tab_sales',
          link: `/contacts/contact_detail/${id}/sales`,
          linkExists: !!links?.values_per_month,
          showModule: this.isOrderAppModuleActive,
          availableOffline: false
        },
        {
          title: 'contact_tab_products',
          link: `/contacts/contact_detail/${id}/products`,
          linkExists: !!links?.products,
          showModule: this.isOrderAppModuleActive,
          availableOffline: false
        },
        {
          title: 'contact_tab_orders',
          link: `/contacts/contact_detail/${id}/orders`,
          linkExists: !!links?.orders,
          showModule: this.isOrderAppModuleActive,
          availableOffline: false
        },
        {
          title: 'contact_tab_open_items',
          link: `/contacts/contact_detail/${id}/open_items`,
          linkExists: !!links?.open_items,
          showModule: this.isTabVisible,
          availableOffline: false
        }
      ];

      return tabs.filter((item: Tab) => item.linkExists && item.showModule && (this.online || item.availableOffline));
    },

    isOrderAppModuleActive() {
      return licenseStore.isOrderAppModuleActive;
    },

    isTabVisible(): boolean {
      if (isRequestMatchToApiVersion('2.16.0')) {
        if (globalStore.permission) {
          return this.isOrderAppModuleActive;
        }
        return false;
      }
      return this.isOrderAppModuleActive;
    }
  },

  methods: {
    getPermission() {
      const permissionsRepository = this.$repositoryFactory.get('permissions');
      const username = lsService.getItem('user');
      if (isRequestMatchToApiVersion('2.16.0')) {
        permissionsRepository.get({
          user: username,
          context: 'offeneposten',
          action_type: 'lesezugriff'
        });
      } else {
        lsService.removeItem('permission');
      }
    }
  },

  mounted() {
    const modules = lsService.getItem('modules');
    const isOrderModuleActive = modules && modules.includes('order');
    if (isOrderModuleActive) this.getPermission();
  }
});
