/* eslint-disable camelcase */
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import ArrayUtils from '@/utils/ArrayUtils';
import { Contact } from '../models';

@Module({
  namespaced: true,
  name: 'fave',
  dynamic: true,
  store,
  stateFactory: true
})
class FavoriteModule extends VuexModule {
  favorites: Contact[] = [];

  sorted_fave: Contact[] = [];

  fave_input = '';

  fave_tag = '';

  fave_type = '';

  fave_total = 0;

  fave_offset = 0;

  fave_limit: number | null = null;

  fave_next_offset = 0;

  fave_request_path = '';

  @Mutation
  add_favorites(contacts: Contact[]) {
    this.favorites = contacts;
  }

  @Mutation
  sort_fave(contacts: Contact[]) {
    this.sorted_fave = ArrayUtils.alphabeticSort(contacts, 'fullname');
  }

  @Mutation
  append_fave(payload: Contact[]) {
    const all_contacts = this.sorted_fave.concat(payload);
    this.sorted_fave = ArrayUtils.alphabeticSort(all_contacts, 'fullname');
  }

  @Mutation
  add_fave_input(payload: string) {
    this.fave_input = payload;
  }

  @Mutation
  add_fave_tag(payload: string) {
    this.fave_tag = payload;
  }

  @Mutation
  add_fave_type(payload: string) {
    this.fave_type = payload;
  }

  @Mutation
  delete_fave(payload: { id: string }) {
    if (Array.isArray(this.sorted_fave)) {
      this.sorted_fave = this.sorted_fave.filter(contact => contact.id !== payload.id);
    }
  }

  @Mutation
  add_fave_total(payload: number) {
    this.fave_total = payload;
  }

  @Mutation
  add_fave_offset(payload: number) {
    this.fave_offset = payload;
  }

  @Mutation
  add_fave_limit(payload: number) {
    this.fave_limit = payload;
  }

  @Mutation
  add_fave_next_offset(payload: number) {
    this.fave_next_offset = payload;
  }

  @Mutation
  add_fave_request_path(payload: string) {
    this.fave_request_path = payload;
  }
}

export default getModule(FavoriteModule);
