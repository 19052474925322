/* eslint-disable @typescript-eslint/no-explicit-any */
import noteStore from '@/store/modules/note';
import { INote } from '@/store/models';
import { IEntityRepository } from '@/services/api/IEntityRepository';
import Axios from './Repository';

class NotesRepository implements IEntityRepository {
  resource = '/notes';

  /**
   * @param {number} contactId
   * @returns {void}
   */
  async get(contactId: any) {
    const initParams = `?contact_id=${contactId}`;
    await Axios.get(`${this.resource}${initParams}`).then(res => {
      noteStore.add_notes(res.data.data);
    });
  }

  /**
   * @param {object} note
   * @param {number} contactId
   * @returns {void>}
   */
  async create(note: INote, contactId: string) {
    const payload = {
      contact_id: contactId,
      title: note.title,
      text: note.text
    };

    await Axios.post(`${this.resource}`, payload);
  }

  async delete(noteId: string) {
    return Axios.delete(`${this.resource}/${noteId}`);
  }
}

export default new NotesRepository();
