


























import Vue from 'vue';

export default Vue.extend({
  props: {
    items: {
      type: Array
    }
  },
  name: 'InfoList',
  methods: {
    doNothing() {
      return null;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setNewNumber(number: string, fn: any) {
      if (number) {
        this.$emit('changeNumber', number);
        fn();
      }
    }
  }
});
