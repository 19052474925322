























import Vue from 'vue';
import OrderProductInfoRow from '@/views/Orders/Order/OrderProductInfoRow.vue';
import OrderProductInfoValueTypeEnums from '@/enums/OrderProductInfoValueTypeEnums';

export default Vue.extend({
  name: 'OrderProductInfo',
  props: ['product', 'deliveryDate'],
  components: { OrderProductInfoRow },
  computed: {
    amountValueType(): string {
      return OrderProductInfoValueTypeEnums.AMOUNT;
    },
    priceValueType(): string {
      return OrderProductInfoValueTypeEnums.PRICE;
    },
    dateValueType(): string {
      return OrderProductInfoValueTypeEnums.DATE;
    }
  }
});
