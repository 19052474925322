<template>
  <div class="contact-list mx-3">
    <v-list>
      <v-subheader v-if="title !== undefined">{{ title }}</v-subheader>
      <v-item-group v-for="(contacts, letter) in sortedContacts" v-bind:key="letter">
        <v-list-item-title v-if="showLetter" class="list-letter py-3 mt-3">{{ letter }}</v-list-item-title>
        <v-list-item app v-for="contact in contacts" :key="contact.id" class="px-0 py-2">
          <v-list-item-content>
            <div class="d-flex pa-0">
              <div class="mr-4 contact_type">
                <v-icon v-if="contact.tag === 'supplier'">mdi-truck</v-icon>
                <v-icon v-if="contact.tag === 'employee' || contact.type === 'additional_contact'">mdi-account</v-icon>
                <v-icon v-if="contact.tag === 'customer'">mdi-basket</v-icon>
                <v-icon v-if="!contact.tag && contact.type !== 'additional_contact'">mdi-account-box</v-icon>
              </div>
              <router-link router :to="`/contacts/contact_detail/${contact.id}/detail`">
                {{ contact.description }}
              </router-link>
            </div>
          </v-list-item-content>

          <v-list-item-icon @click="addToFavorite(contact)" class="fave">
            <v-icon v-if="contact.favorite === true" class="text--brand-primary">mdi-star</v-icon>
            <v-icon class="text--brand-primary" v-else>mdi-star-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-item-group>
    </v-list>

    <Popup
      :show="dialog"
      :headline="'delete'"
      :additionalText="additionalText"
      :cancelButton="true"
      @cancel="dialog = false"
      @confirm="addToFavorite(currentContact, true)"
    />
  </div>
</template>

<script>
/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import eventBus from '@/services/eventBus';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Popup from '@/components/Common/Popup.vue';
import infiniteScroll from '@/utils/InfiniteScroll';

@Component({
  props: {
    parentContactId: String,
    contacts: Array,
    title: String,
    letter: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Popup
  }
})
export default class ContactList extends Vue {
  dialog = false;

  currentContact = {};

  contactsRepository = this.$repositoryFactory.get('contacts');

  bottom = false;

  get additionalText() {
    return this.$t('confirm_removing_from_fave', { name: this.currentContact.description });
  }

  get computedParentContactId() {
    return this.parentContactId;
  }

  get computedContacts() {
    return this.contacts;
  }

  get sortedContacts() {
    const sorted = {};
    let tmpObj = [];
    if (this.computedContacts && this.computedContacts.length > 0) {
      this.computedContacts.forEach((el, index) => {
        const currentElChar = el.description.charAt(0).toUpperCase();
        let nextElChar = '';
        if (this.computedContacts[index + 1] !== undefined) {
          nextElChar = this.computedContacts[index + 1].description.charAt(0).toUpperCase();
        }
        tmpObj.push(el);
        if (nextElChar !== currentElChar || this.computedContacts[index + 1] === undefined) {
          sorted[currentElChar] = tmpObj;
          tmpObj = [];
        }
      });
    }
    return sorted;
  }

  get showLetter() {
    return this.letter;
  }

  addToFavorite(contact, confirmed = false) {
    const { id } = contact;
    let { favorite } = contact;
    if (favorite === true) {
      if (confirmed) {
        favorite = false;
      } else {
        this.getConfirmation(contact);
        return;
      }
    } else {
      favorite = true;
    }
    const payload = {
      id,
      favorite
    };

    this.dialog = false;
    this.changeFave(payload);
    this.currentContact = {};
  }

  changeFave({ id, favorite }) {
    this.contactsRepository
      .changeFavoriteStatus(id, favorite)
      .then(() => {
        eventBus.$emit('faveChanged');
        this.getChildren();
      })
      .catch(() => {
        eventBus.$emit('faveChanged');
        this.getChildren();
      });
  }

  getChildren() {
    if (this.computedParentContactId) {
      this.contactsRepository.getChildren(this.computedParentContactId);
    }
  }

  getConfirmation(contact) {
    this.currentContact = contact;
    this.dialog = true;
  }

  bottomVisible() {
    return infiniteScroll();
  }

  created() {
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible();
    });
  }

  @Watch('bottom')
  fBottom() {
    if (this.bottom && this.pagination) {
      eventBus.$emit('pagination');
    }
  }
}
</script>

<style lang="scss" scoped>
.v-list-item {
  border-bottom: 1px solid $brand-new-black-30;

  a {
    &,
    &:hover,
    &:focus,
    &:visited {
      @extend .brand-title;
      color: $brand-black;
    }
  }
}

.list-letter {
  @extend .brand-headline;
  color: $brand-primary;
}

.contact_type {
  .v-icon {
    color: $brand-black !important;
  }
}
</style>
