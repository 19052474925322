




















import Vue from 'vue';
import OrderProductInfoValueTypeEnums from '@/enums/OrderProductInfoValueTypeEnums';
import NumberUtils from '@/utils/NumberUtils';

export default Vue.extend({
  name: 'OrderProductInfoRow',
  props: {
    translateText: {
      type: String
    },
    twoColumns: {
      type: Boolean
    },
    textOne: {
      type: [String, Number]
    },
    textTwo: {
      type: [String, Number]
    },
    price: {
      type: Boolean
    },
    valueType: {
      type: String
    },
    sellUnit: {
      type: String
    }
  },
  computed: {
    formattedTextOne() {
      if (typeof this.textOne === 'number') {
        if (this.valueType === OrderProductInfoValueTypeEnums.PRICE) {
          return NumberUtils.castPriceFromDecimalNumberToString(this.textOne);
        }
        if (this.valueType === OrderProductInfoValueTypeEnums.AMOUNT && this.sellUnit) {
          // return NumberUtils.castAmountFromNumberToString(this.textOne, this.sellUnit);
          return NumberUtils.castAmountFromNumberToString(this.textOne, this.sellUnit);
        }
      }
      // eslint-disable-next-line no-console
      console.log(typeof this.textOne);
      return this.textOne;
    }
  }
});
