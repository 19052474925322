import { OrderState } from '@/store/modules/order/types';
import lsService from '@/services/localStorage/lsService';

const user = lsService.getItem('email');
const currentContactId = lsService.getItem('currentContactId');
const orderDraftFromLs = lsService.getItem(`orderDraft_${user}_${currentContactId}`);

const state: OrderState = {
  orders: [],
  orderDetail: false,
  limit: 15,
  offset: 0,
  status: '0',
  dates: {
    dateFrom: '',
    dateTo: ''
  },
  count: 0,
  sortBy: '',
  sortDesc: true,
  orderDraft: orderDraftFromLs ? JSON.parse(orderDraftFromLs) : null
};

export default state;
