/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import VueI18n from '@/i18n';
import { IErrorHandler } from './IErrorHandler';
import baseErrHandler from './baseErrHandler';

class KACErrHandler extends baseErrHandler implements IErrorHandler {
  prefix = 'License Manager';

  errorMessage = ({ response }: AxiosError): string => response?.data.detail;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  errorStatus = ({ response }: AxiosError): number => response!.status;

  prepareError = (errorStatus: number, errorMessage: string): string => {
    // 404
    const errorReg1 = /FO API version on endpoint: .+ not match to any existing in the KAC\. FO API endpoint version: .+, KAC available versions: .+/g;
    const errorReg2 = /User .+ does not have any licenses for application ID:.+/g;
    const errorReg3 = /Modules selected in licence for .+ app not exist in version .+ Please add needed modules to version if FO API version supports those modules./g;
    const errorReg4 = /No modules selected in licence for app: .+/g;
    const errorReg5 = /Key .+ not found in response from .+/g;
    // 400
    const errorReg6 = /There is some problem with external FO API on .+\. Status code from FO API: .+ More information: .+/g;
    const errorReg7 = /There is some problem with external FO API on .+\. More information: .+/g;
    const errorReg8 = /Couldn't resolve host name for .+/g;
    const errorReg9 = /HTTP\/1.1 400 Bad Request returned for .+/g;
    const hardcodeErrors = [
      'Invalid email address',
      'User not found!',
      'Please contact KiratikApp administrator. Error: Network Error',
      'Invalid user or password',
      'Login timeout, Please log in.',
      'Please contact with KiratikApp administrator. Error: 500 Server response not received.',
      'This user does not have any licences.',
      'KAC has no versions entered. Please specify versions in KAC panel.',
      'Unknown Error Occured. Server response not received. Please contact with administrator',
      'Please contact with KiratikApp administrator. Error: 400 Bad Request',
      'Email should not be empty.',
      'App should not be empty.',
      'App should be a type of integer.'
    ];

    switch (true) {
      case new RegExp(errorReg1).test(errorMessage): {
        const keys = ['url', 'foApiVersion', 'versions'];
        return this.prepareTranslation(errorReg1, errorMessage, keys);
      }
      case new RegExp(errorReg2).test(errorMessage): {
        const keys = ['email', 'nr'];
        return this.prepareTranslation(errorReg2, errorMessage, keys);
      }
      case new RegExp(errorReg3).test(errorMessage): {
        const keys = ['app', 'version'];
        return this.prepareTranslation(errorReg3, errorMessage, keys);
      }
      case new RegExp(errorReg4).test(errorMessage): {
        const keys = ['app'];
        return this.prepareTranslation(errorReg4, errorMessage, keys);
      }
      case new RegExp(errorReg5).test(errorMessage): {
        const keys = ['apiVersion', 'apiUrl'];
        return this.prepareTranslation(errorReg5, errorMessage, keys);
      }
      case new RegExp(errorReg6).test(errorMessage): {
        const keys = ['apiUrl', 'status', 'message'];
        return this.prepareTranslation(errorReg6, errorMessage, keys);
      }
      case new RegExp(errorReg7).test(errorMessage): {
        const keys = ['apiUrl', 'message'];
        return this.prepareTranslation(errorReg7, errorMessage, keys);
      }
      case new RegExp(errorReg8).test(errorMessage): {
        const keys = ['url'];
        return this.prepareTranslation(errorReg8, errorMessage, keys);
      }
      case new RegExp(errorReg9).test(errorMessage): {
        const keys = ['url'];
        return this.prepareTranslation(errorReg9, errorMessage, keys);
      }
      default:
        if (hardcodeErrors.indexOf(errorMessage) > -1) {
          return String(VueI18n.t(errorMessage));
        }
        return String(VueI18n.t(this.getErrorMessage(errorStatus, errorMessage)));
    }
  };

  prepareTranslation = (regExp: RegExp, str: string, keys: Array<string>): string => {
    const regArray = regExp.source.split('.+');
    let copyErr = ` ${str}`.slice(1);
    regArray.forEach(reg => {
      copyErr = copyErr.replace(new RegExp(reg), ';');
    });
    const values = copyErr.split(';').filter(el => el !== '');
    const payload: any = {};
    keys.forEach((el, index) => {
      payload[el] = values[index];
    });
    return String(VueI18n.t(regExp.source, payload));
  };
}

export default KACErrHandler;
