<template>
  <div class="intro">
    <CarouselDetail v-for="intro in introsToShow" :intro="intro" v-bind:key="intro.id" />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import CarouselDetail from '@/views/Global/WizardCarouselView/CarouselDetail.vue';
import lsService from '@/services/localStorage/lsService';
import introStore from '@/store/modules/intros';

@Component({
  components: { CarouselDetail },
  props: {
    intros: {}
  }
})
export default class WizardCarousel extends Vue {
  name = 'index.vue';

  get introsToShow() {
    const allIntros = introStore.intros;
    const toShow = [];
    allIntros.forEach(intro => {
      if (!this.wasSeen(intro.id)) {
        toShow.push(intro);
      }
    });

    return toShow;
  }

  wasSeen = introId => {
    const seenIntros = lsService.getSeenIntros();
    let found = false;

    seenIntros.forEach(seenIntroId => {
      if (introId === seenIntroId) {
        found = true;
      }
    });

    return found;
  };
}
</script>
