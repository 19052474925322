



















import Vue from 'vue';
import NavSearchInput from '@/views/Contacts/ContactsNavbar/NavSearchInput.vue';
import NavDrawer from '@/components/Layout/NavDrawer.vue';
import NavTitleBar from '@/components/Layout/NavTitleBar.vue';
import NavTabs from '@/views/Contacts/ContactsNavbar/NavTabs.vue';
import ContactsFilter from '@/views/Contacts/ContactsFilter/ContactsFilter.vue';
import FilterDrawer from '@/components/Common/FilterDrawer/FilterDrawer.vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'NavBar',
  components: {
    NavSearchInput,
    NavDrawer,
    NavTitleBar,
    NavTabs,
    FilterDrawer,
    ContactsFilter
  },
  computed: {
    ...mapState('global', ['searchInputIsVisible', 'contactDetailMenuIsVisible']),
    extHeight() {
      if (this.searchInputIsVisible) {
        return 56;
      }
      if (this.contactDetailMenuIsVisible) {
        return 35;
      }
      return 0;
    }
  }
});
