import UnitsEnums from '@/enums/UnitsEnums';

export default class NumberUtils {
  public static setDecimals(value: number, fractionDigits = 2, locale = 'de'): string {
    return value.toLocaleString(locale, { minimumFractionDigits: fractionDigits });
  }

  public static setDecimalsForNumberAsString(value: string, fractionDigits = 2, locale = 'de'): string {
    return parseFloat(value).toLocaleString(locale, { minimumFractionDigits: fractionDigits });
  }

  /**
   * Filter price from decimal value (type: string) with one of valid pattern (###,## || ###.## || ###,# || ###,#) to a number type
   * Examples:
   * value: '150,20' should return number type: 150.2
   * More examples in unit tests.
   */
  public static castPriceFromStringToDecimalNumber(value: string): number {
    // remove white space from string
    let inputValue = value.toString().replace(/\s/g, '');
    // replace all commas to dots
    inputValue = inputValue.toString().replace(/,/g, '.');
    // explode strings to an array of substrings separated by dots
    const explodedInputValue = inputValue.split('.');

    let resultAsString = '';

    if (explodedInputValue.length > 1) {
      const lastElementFromExplodedInputValue = explodedInputValue.pop() as string;
      // check if decimal part is bigger than 0
      if (Number(lastElementFromExplodedInputValue) > 0) {
        // join with dot
        resultAsString = `${explodedInputValue.join('')}.${lastElementFromExplodedInputValue}`;
      } else {
        // join without .0
        resultAsString = `${explodedInputValue.join('')}`;
      }
    } else {
      resultAsString = inputValue;
    }

    const resultAsNumber = parseFloat(resultAsString);
    return Math.round((resultAsNumber + Number.EPSILON) * 100) / 100;
  }

  /**
   * Filter price from decimal value (type: number) to a formatted string with pattern ######,##
   *
   * Ex. value: 1000.2 should return 1000,20.
   * More examples in unit tests.
   */
  public static castPriceFromDecimalNumberToString(value: number): string {
    return value.toFixed(2).replace(/\./g, ',');
  }

  public static castAmountFromStringToDecimalNumber(value: string, sellUnit: string): number {
    // remove white space from string
    let inputValue: string = value.toString().replace(/\s/g, '');
    // replace all commas to dots
    inputValue = inputValue.replace(/,/g, '.');
    // fractional part has max 3 digits
    inputValue = parseFloat(inputValue).toFixed(3);
    // explode strings to an array of substrings separated by dots
    const explodedInputValue: string[] = inputValue.split('.');
    const integerPart = explodedInputValue[0];
    const explodedInputValueWithoutFractionalPart = [...explodedInputValue];
    const fractionPart = explodedInputValueWithoutFractionalPart.pop() as string;

    let resultAsString = '';
    let result = 0;

    switch (sellUnit) {
      case UnitsEnums.PCS:
      default:
        // TODO: here should be UnitsEnums.PCS:
        result = Number(integerPart);
        break;
      case UnitsEnums.KG:
        // if input value has a fractional part?
        if (explodedInputValue.length > 1) {
          const fractionPartOfInputValueAsNumber = Number(fractionPart);
          // check if decimal part is bigger than 0
          if (fractionPartOfInputValueAsNumber > 0) {
            // join with dot
            resultAsString = `${explodedInputValueWithoutFractionalPart.join('')}.${fractionPart}`;
          } else {
            // join without .0
            resultAsString = `${explodedInputValueWithoutFractionalPart.join('')}`;
          }
        } else {
          resultAsString = inputValue.toString();
        }
        result = parseFloat(resultAsString);
        break;
    }

    return result;
  }

  public static castAmountFromNumberToString(value: number, sellUnit: string, dotReturn = false): string {
    let result = '';
    const formattedValue: string = value.toFixed(3).replace(/\./g, ',');

    switch (sellUnit) {
      case UnitsEnums.PCS:
      default:
        result = Math.floor(value).toString();
        if (dotReturn) {
          result = result.replace(/,/g, '.');
        }
        break;
      case UnitsEnums.KG:
        // check how many 0 we have after fractional separator
        result = formattedValue;
        if (dotReturn) {
          result = result.replace(/,/g, '.');
        }
        break;
    }

    return result;
  }
}
