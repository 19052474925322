












import NavTitleBar from '@/components/Layout/NavTitleBar.vue';
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import global from '@/store/modules/global';
import NavDrawer from '@/components/Layout/NavDrawer.vue';
import OrdersNavTabs from '@/views/Orders/OrdersNavbar/index.vue';

export default Vue.extend({
  name: 'OrdersApp',
  components: {
    OrdersNavTabs,
    NavTitleBar,
    NavDrawer
  },
  data() {
    return {
      title: 'orders'
    };
  },
  computed: {
    ...mapState('order', ['orderDetail']),
    fromView(): string | undefined {
      const { from } = this.$route.query;
      return typeof from === 'string' ? from : undefined;
    },
    extHeight() {
      return 35;
    }
  },
  methods: {
    ...mapMutations('global', ['changeView']),
    ...mapMutations('order', ['SET_OFFSET', 'CLEAR_ORDERS', 'SET_STATUS', 'CLEAR_DATES']),
    setView(): void {
      if (this.fromView) {
        this.changeView(this.fromView);
      } else {
        this.changeView(this.title);
      }
    }
  },
  created() {
    global.changeTitle(this.title);
    this.setView();
  },
  beforeDestroy() {
    this.SET_OFFSET(0);
    this.SET_STATUS('0');
    this.CLEAR_DATES();
    this.CLEAR_ORDERS();
  }
});
