import { IPlanningListsRepository } from '@/services/api/IEntityRepository';
import Axios from '@/services/api/Repository';
import { IPlanningList } from '@/interfaces/IPlanningList';
import PlanningListFactory from '@/factories/PlanningListFactory';
import { IGetPlanningListFORes } from '@/interfaces/FoodOffice/IGetPlanningListFORes';

/* eslint-disable camelcase */
export type TGetPlanningListFromFOParams = {
  date_from?: string;
  date_to?: string;
};

export default class PlanningListsRepository implements IPlanningListsRepository {
  readonly resource = '/planning_list'; // Can't use private here :(

  // TODO: IMPLEMENT VUEX HERE!!!
  // TODO: store.dispatch('order/setOrders', planningList);
  // TODO: store.commit('order/SET_COUNT', count);
  public async get(params?: TGetPlanningListFromFOParams): Promise<IPlanningList> {
    return Axios.get<IGetPlanningListFORes>(this.resource, {
      params
    }).then(response => PlanningListFactory.create(response.data));

    /* return new Promise((resolve, reject) => {
      Axios.get<IGetPlanningListFORes>(this.resource, { params })
        .then(response => {
          const planningList = PlanningListFactory.create(response.data);
          resolve(planningList);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    }); */
  }
}
