import Axios, { AxiosRequestConfig } from 'axios';
import lsService from '@/services/localStorage/lsService';
import FOErrHandler from '@/services/errorHandler/FOErrHandler';
import AxiosInterceptor from '../axiosInterceptor';

const axiosConfig: AxiosRequestConfig = {};

const baseURL: string = lsService.getItem('apiUrl') || '';
const tokenFo: string = lsService.getItem('tokenFo') || '';

axiosConfig.baseURL = baseURL;

axiosConfig.headers = {
  common: {
    Authorization: `Bearer ${tokenFo}`
  }
};

const AxiosInstance = Axios.create(axiosConfig);

const axin = new AxiosInterceptor();
const errHandler = new FOErrHandler();
axin.setAxiosInterceptor(AxiosInstance, errHandler);

export default AxiosInstance;
