<script>
import { Bar, mixins } from 'vue-chartjs';
import { Component, Vue } from 'vue-property-decorator';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  extends: Bar,
  ChartDataLabels,
  mixins: [mixins.reactiveProp],
  props: ['chartData']
})
export default class CommitChart extends Vue {
  mounted() {
    this.renderChart(this.chartData, {
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            // display: false,
            gridLines: {
              display: true,
              color: 'rgba(0, 0, 0, 0)',
              offsetGridLines: false,
              zeroLineWidth: 2,
              zeroLineColor: '#005572',
              z: 1
            },
            ticks: {
              display: false
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              fontColor: '#005572',
              fontStyle: 'bold'
            },
            gridLines: {
              display: false
            }
          }
        ]
      },
      plugins: {
        datalabels: {
          color: '#005572',
          display(context) {
            const index = context.dataIndex;
            const value = context.dataset.data[index];
            return value > 0;
          }
        }
      }
    });
  }
}
</script>
