





















import Vue from 'vue';

export default Vue.extend({
  name: 'LicenseProblemView',
  components: {},
  i18n: {
    messages: {
      en: {
        message: 'You have no active module or license for the KIRATIK app. Please contact your KIRATIK sales person.'
      },
      de: {
        message:
          'Leider haben Sie kein aktives Modul oder keine Lizenz für die KIRATIK App. Bitte kontaktieren Sie Ihren KIRATIK Vertriebsmitarbeiter.'
      }
    }
  }
});
