/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import { AxiosInstance } from 'axios';
import globalStore from '@/store/modules/global';
import auth from '@/store/modules/auth';
import lsService from '@/services/localStorage/lsService';
import TokenServices from '@/services/tokenServices';
import tokenExpiredError from '@/services/errorHandler/customHttpErrors';
import { IErrorHandler } from '@/services/errorHandler/IErrorHandler';

export default class AxiosInterceptor {
  setAxiosInterceptor(Axios: AxiosInstance, errHandler: IErrorHandler): void {
    Axios.interceptors.request.use(
      config => {
        // change isLoading to true when api request is executed
        try {
          this.logoutIfTokenTimeout();
        } catch (e) {
          return e;
        }
        globalStore.increaseLoadingCounter();
        return config;
      },
      error => {
        if (((error || {}).config || {}).url) {
          errHandler.handle(error);
          globalStore.decreaseLoadingCounter();
        }
        return Promise.reject(error);
      }
    );

    Axios.interceptors.response.use(
      response => {
        // change isLoading to false when getted response or catch error
        globalStore.decreaseLoadingCounter();
        // TODO: IMPORTANT CHECK IT LATER
        if (!globalStore.showLoader) {
          globalStore.showLoadingScreen(true);
        }
        return response;
      },
      error => {
        if (((error || {}).config || {}).url) {
          errHandler.handle(error);
          globalStore.decreaseLoadingCounter();
        }
        return Promise.reject(error);
      }
    );
  }

  apiUrlExist(): void {
    const apiUrl = lsService.getItem('apiUrl');
    const isLoginIn = auth.isLoggedIn || false;
    if (!apiUrl && isLoginIn) {
      Vue.prototype.$authService.logout();
    }
  }

  logoutIfTokenTimeout(): void {
    const token = lsService.getItem('tokenLm');
    const isLoginIn = auth.isLoggedIn || false;
    const TokenServicesInst = new TokenServices();
    if (!TokenServicesInst.tokenIsCurrent(token) && isLoginIn) {
      Vue.prototype.$authService.logout();
      throw tokenExpiredError;
    }
  }
}
