/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-cycle,@typescript-eslint/no-explicit-any */
import auth from '@/store/modules/auth';
import { AxiosError, AxiosInstance } from 'axios';
import lsService from '@/services/localStorage/lsService';
import KACRepositoryFactory from '@/services/apiKAC/RepositoryFactory';
import { IEntityRepository } from '@/services/apiKAC/IEntityRepository';
import jwtDecode from 'jwt-decode';
import { IDecodedFoApiToken } from '@/interfaces/FoodOffice/IDecodedFoApiToken';
import { IDecodedLmToken } from '@/interfaces/IDecodedLmToken';
import KACService from './KACService';
import router from '../router';

/**
 * Authentication Service
 */
export default class AuthService {
  axios: AxiosInstance;

  kac = new KACService();

  introRepository: IEntityRepository;

  constructor(Axios: AxiosInstance) {
    this.axios = Axios;
    const RepFactory = new KACRepositoryFactory();
    this.introRepository = RepFactory.get('intro');
  }

  loginByLink(email: string, token: string): Promise<void> {
    const { customerId } = jwtDecode(token) as IDecodedLmToken;
    return this.kac
      .getLicences(customerId, process.env.APP_CODE, token)
      .then(() => {
        this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        console.log('loginByLink: ', token);
        auth.auth_success({ token, user: email });
        lsService.setItem('token', token);
        lsService.setItem('email', email);
      })
      .catch((e: AxiosError) => {
        this.logout();
        throw e;
      });
  }

  login(email: string, password: string) {
    this.axios.defaults.baseURL = process.env.VUE_APP_KAC_API;
    this.axios
      .post('api/authenticate', { email, password, appCode: process.env.VUE_APP_APP_CODE })
      .then(async res => {
        if (res.data !== '') {
          const { token } = res.data;
          const decodedLMToken = jwtDecode(token) as IDecodedLmToken;
          const decodedFoApiToken = jwtDecode(decodedLMToken.foApiToken) as IDecodedFoApiToken;

          lsService.setItem('tokenLm', token);
          lsService.setItem('tokenFo', decodedLMToken.foApiToken);
          lsService.setItem('user', decodedFoApiToken.nr);
          lsService.setItem('email', email);
          this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
          auth.auth_success({ token, user: decodedFoApiToken.nr });
          const appCode = process.env.VUE_APP_APP_CODE;
          const { customerId } = decodedLMToken;
          await this.introRepository.get({ appCode, email });
          await this.kac.getLicences(customerId, appCode, token);
          // this.axios.get(`api/licences?customer.id=21&app.code=${appCode}`).then(() => {
          //   this.introRepository.get({ appCode, email });
          // });
          this.axios.defaults.baseURL = lsService.getItem('apiUrl') || '';
          this.axios.defaults.headers.common.Authorization = `Bearer ${decodedLMToken.foApiToken}`;
          await router.push('/contacts/search');
        } else {
          this.logout();
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  logout() {
    this.kac.clear();
    delete this.axios.defaults.baseURL;
    new Promise<void>(resolve => {
      lsService.clearStorageWithoutEmail();
      delete this.axios.defaults.headers.common.Authorization;
      resolve();
    }).then(async () => {
      auth.logout();
      // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-empty-function
      await router.push('/login').catch(err => {});
    });
  }
}
