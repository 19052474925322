

















import Vue from 'vue';
import Footer from '@/components/Layout/Footer.vue';
import Notification from '@/views/Global/LoginView/LoginNotification.vue';
import LoginForm from '@/views/Global/LoginView/LoginForm.vue';

export default Vue.extend({
  name: 'LoginView',
  components: {
    Footer,
    Notification,
    LoginForm
  }
});
