import global from '@/store/modules/global';
import store from '@/store';

export const handleConnectionChange = (event: Event): void => {
  // set connection status
  if (event.type === 'offline') {
    global.changeConnectionStatus(false);
  }
  if (event.type === 'online') {
    store.dispatch('pwaService/replayQueueRequests');
    location.reload();
  }
};

export const setConnectionStatus = (): void => {
  global.changeConnectionStatus(navigator.onLine);
};
