<template>
  <v-container>
    <PlanningList />
  </v-container>
</template>

<script>
import Vue from 'vue';
import PlanningList from '@/components/PlanningList/PlanningList.vue';

export default Vue.extend({
  components: {
    PlanningList
  }
});
</script>
