






import Vue from 'vue';
import Product from '@/components/Common/ProductCard/Product.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ProductsList.vue',
  components: {
    Product
  },
  computed: {
    ...mapGetters('contact', ['getProducts'])
  }
});
