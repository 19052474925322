import { IGetPlanningListItemFORes } from '@/interfaces/FoodOffice/IGetPlanningListFORes';
import { IPlanningListItem } from '@/interfaces/IPlanningListItem';
import PlanningListItem from '@/models/PlanningListItem';

export default class PlanningListItemFactory {
  public static create(planningListItemData: IGetPlanningListItemFORes): IPlanningListItem {
    const object = {} as IPlanningListItem;
    object.productId = planningListItemData.product_id;
    object.productName = planningListItemData.product_name;
    object.productWeight = planningListItemData.product_weight;
    object.productGroupId = planningListItemData.product_group_id;
    object.productGroupName = planningListItemData.product_group_name;
    object.customerId = planningListItemData.customer_id;
    object.customerName = planningListItemData.customer_name;
    object.weight = planningListItemData.weight;
    object.deliveryDate = planningListItemData.delivery_date;
    object.sellUnit = planningListItemData.sell_unit;
    object.sellUnitName = planningListItemData.sell_unit_name;
    object.sumAmount = planningListItemData.sum_amount;
    object.sumWeight = planningListItemData.sum_weight;
    object.quantity = planningListItemData.quantity;
    object.sumQuantity = planningListItemData.sum_quantity;
    return new PlanningListItem(object);
  }
}
