














import Vue from 'vue';

export default Vue.extend({
  name: 'InfoCard',
  props: {
    cardTitle: {
      type: String,
      required: true
    },
    cardInfo: {
      type: String,
      required: false
    },
    edit: {
      type: Boolean
    }
  },
  methods: {
    textInput(text: string): void {
      this.$emit('text-input', text);
    }
  }
});
