<template>
  <div class="fave-content">
    <ContactsList v-bind:contacts="this.filtered" @fave="fave = $event" :pagination="this.pagination" />
  </div>
</template>

<script>
/* eslint-disable class-methods-use-this */
import globalStore from '@/store/modules/global';
import faveStore from '@/store/modules/fave';
import eventBus from '@/services/eventBus';
import lsService from '@/services/localStorage/lsService';
import { Component, Watch, Vue } from 'vue-property-decorator';
import ContactsList from '../../components/Common/ContactList.vue';

@Component({
  components: {
    ContactsList
  }
})
export default class Favorites extends Vue {
  name = 'Favorites';

  title = 'favorites';

  contactsRepository = this.$repositoryFactory.get('contacts');

  get filtered() {
    return faveStore.sorted_fave;
  }

  get contacts() {
    return faveStore.favorites;
  }

  get params() {
    return {
      searchString: this.faveInput,
      type: this.faveType,
      tag: this.faveTag,
      offset: this.faveNextOffset
    };
  }

  get faveInput() {
    return faveStore.fave_input;
  }

  set faveInput(value) {
    faveStore.add_fave_input(value);
  }

  get faveTag() {
    return faveStore.fave_tag;
  }

  set faveTag(value) {
    faveStore.add_fave_tag(value);
  }

  get faveType() {
    return faveStore.fave_type;
  }

  set faveType(value) {
    faveStore.add_fave_type(value);
  }

  get faveOffset() {
    return faveStore.fave_offset;
  }

  get faveLimit() {
    return faveStore.fave_limit;
  }

  get faveNextOffset() {
    return faveStore.fave_next_offset;
  }

  set faveNextOffset(value) {
    faveStore.add_fave_next_offset(value);
  }

  get faveTotal() {
    return faveStore.fave_total;
  }

  get pagination() {
    return this.faveNextOffset < this.faveTotal;
  }

  getFavorites(limit) {
    this.contactsRepository.getFavorites(this.params, limit);
  }

  filteringByParameters() {
    this.contactsRepository.getFavorites(this.params);
  }

  changeFave({ favorite, id }) {
    this.contactsRepository
      .changeFavoriteStatus(id, favorite)
      .then(() => this.getFavorites())
      .catch(() => this.getFavorites());

    // TODO update search store
  }

  searchCallback(searchString) {
    lsService.setItem(`searchStr_${this.$route.name}`, searchString);
    this.faveInput = searchString !== undefined ? searchString : this.faveInput;
    this.filteringByParameters();
  }

  tagChangedCallback(tagData) {
    this.faveTag = tagData.name;
  }

  typeChangedCallback(typeData) {
    this.faveType = typeData.name;
  }

  faveChangedCallback() {
    this.faveNextOffset = 0;
    const limit = this.faveOffset + this.faveLimit;
    this.getFavorites(limit);
  }

  mounted() {
    globalStore.changeTitle(this.title);
    globalStore.changeView(this.title);
    globalStore.showSearchInput();
    this.faveNextOffset = 0;
    this.getFavorites();
  }

  created() {
    eventBus.$on('searchContact', this.searchCallback);
    eventBus.$on('tagChanged', this.tagChangedCallback);
    eventBus.$on('typeChanged', this.typeChangedCallback);
    eventBus.$on('faveChanged', this.faveChangedCallback);
  }

  beforeDestroy() {
    eventBus.$off('searchContact', this.searchCallback);
    eventBus.$off('typeChanged', this.tagChangedCallback);
    eventBus.$off('tagChanged', this.typeChangedCallback);
    eventBus.$off('faveChanged', this.faveChangedCallback);
  }

  @Watch('faveTag')
  favoriteTagChanged() {
    this.filteringByParameters();
  }

  @Watch('faveType')
  favoriteTypeChanged() {
    this.filteringByParameters();
  }
}
</script>

<style scoped lang="scss">
.fave-content {
  padding: 104px 0 56px 0;
}
</style>
