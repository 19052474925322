/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import lsService from '@/services/localStorage/lsService';
import { IEntityRepository } from '@/services/apiKAC/IEntityRepository';
import Axios from './Repository';

export class IntroRepository implements IEntityRepository {
  resource = 'api/intros';

  async get(params: any): Promise<unknown> {
    return new Promise<void>((resolve, reject) => {
      Axios.get(`${this.resource}?app.code=${params.appCode}&active=1`)
        .then(res => {
          // KA-186 - intro is not used at this moment. Consider to add this when is needed
          // intros.addIntros(res.data);
          lsService.setIntros(res.data);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async setSeenIntro(introId: number, userId: any): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const payload = {
        usersWhoSawIt: [`/api/users/${userId}`]
      };
      Axios.patch(`${this.resource}/${introId}`, payload, {
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export default new IntroRepository();
