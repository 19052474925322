import { AxiosError } from 'axios';
import { IErrorHandler } from './IErrorHandler';
import VueI18n from '../../i18n';
import baseErrHandler from './baseErrHandler';

class FOErrHandler extends baseErrHandler implements IErrorHandler {
  // prefix = '[FO]: ';

  prefix = 'Food Office';

  errorMessage = ({ response }: AxiosError): string => response?.data.message;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  errorStatus = ({ response }: AxiosError): number => response!.status;

  prepareError = (errorStatus: number, errorMessage: string): string => {
    const hardcodeErrors = [
      'Invalid email address',
      'User not found!',
      'Please contact KiratikApp administrator. Error: Network Error',
      'Invalid user or password',
      'Login timeout, Please log in.',
      'Please contact with KiratikApp administrator. Error: 500 Server response not received.',
      'This user does not have any licences.',
      'KAC has no versions entered. Please specify versions in KAC panel.',
      'Unknown Error Occured. Server response not received. Please contact with administrator',
      'Please contact with KiratikApp administrator. Error: 400 Bad Request',
      'Email should not be empty.',
      'App should not be empty.',
      'App should be a type of integer.'
    ];
    switch (true) {
      default:
        if (hardcodeErrors.indexOf(errorMessage) > -1) {
          return String(VueI18n.t(errorMessage));
        }
        return String(VueI18n.t(this.getErrorMessage(errorStatus, errorMessage)));
    }
  };
}

export default FOErrHandler;
