import { IOrderDraft, IOrderDraftItem } from '@/store/modules/order/types';
import { NewOrderProduct } from '@/services/api/ordersRepository/types';
import { ProductWithAverages } from '@/store/modules/contact/types';
import UnitsEnums from '@/enums/UnitsEnums';

export default class OrderDraftService {
  public static findOrderDraftItemByProductId(orderDraft: IOrderDraft, productId: string): IOrderDraftItem | undefined {
    return orderDraft.orderItems?.find(item => item.productId === productId);
  }

  public static isAtLeastOneOrderDraftItemToOrder(orderDraft: IOrderDraft): boolean {
    const searchResult = orderDraft.orderItems?.findIndex(item => item.amount > 0) as number;
    return searchResult >= 0;
  }

  public static getOrderDraftItemsForPOSTRequest(orderDraft: IOrderDraft): NewOrderProduct[] {
    const tmpTransformedOrderItems: NewOrderProduct[] = [];

    const { orderItems } = orderDraft;
    if (orderItems) {
      orderItems.forEach(item => {
        if (item.amount > 0) {
          const newItem = {
            product_id: item.productId,
            amount: item.amount,
            sell_unit: item.sellUnit
          } as NewOrderProduct;

          // Optional fields
          if (item.additionalInfo) {
            newItem.additional_info = item.additionalInfo;
          }
          if (item.internalInfo) {
            newItem.internal_info = item.internalInfo;
          }

          tmpTransformedOrderItems.push(newItem);
        }
      });

      return tmpTransformedOrderItems;
    }
    return [];
  }

  public static getIndexOfOrderDraftItem(orderDraftItems: IOrderDraftItem[], productId: string): number {
    return orderDraftItems.findIndex(item => item.productId === productId) as number;
  }

  public static createOrderDraftItemsFromProducts(products: ProductWithAverages[]): IOrderDraftItem[] {
    const $tmpOrderDraftItems: IOrderDraftItem[] = [];
    products.forEach(product => {
      let defaultAmountString = '';
      switch (product.sell_unit) {
        case UnitsEnums.KG:
          defaultAmountString = '0,000';
          break;
        default:
          defaultAmountString = '0';
      }

      const newOrderDraftItem = {
        additionalInfo: '',
        productId: product.product_id,
        price: 0,
        rawPrice: '0,00',
        formattedPrice: '0,00',
        internalInfo: '',
        amount: 0,
        rawAmount: defaultAmountString,
        formattedAmount: defaultAmountString,
        sellUnit: product.sell_unit
      } as IOrderDraftItem;
      $tmpOrderDraftItems.push(newOrderDraftItem);
    });
    return $tmpOrderDraftItems;
  }
}
