/* eslint-disable camelcase,quote-props */
import { getterTree } from 'typed-vuex';
import lsService from '@/services/localStorage/lsService';
import { MonthValue, OpenItemElement, Datasets, SalesDataToChart, ProductWithAverages } from '@/store/modules/contact/types';
import { TranslateResult } from 'vue-i18n';
import VueI18n from '@/i18n';
import initialState from './state';

function overdue(data: string): boolean {
  const today = new Date();
  const dueData = new Date(data);
  return today > dueData;
}

const monthName: { [key: string]: string } = {
  '01': 'month_abbreviation_jan',
  '02': 'month_abbreviation_feb',
  '03': 'month_abbreviation_mar',
  '04': 'month_abbreviation_apr',
  '05': 'month_abbreviation_may',
  '06': 'month_abbreviation_jun',
  '07': 'month_abbreviation_jul',
  '08': 'month_abbreviation_aug',
  '09': 'month_abbreviation_sept',
  '10': 'month_abbreviation_oct',
  '11': 'month_abbreviation_nov',
  '12': 'month_abbreviation_dec'
};

const mainGetters = getterTree(initialState, {
  isContact: state => {
    const hasProperties = Object.keys(state.currentContact).length !== 0;
    const isObject = state.currentContact.constructor === Object;
    return hasProperties && isObject;
  },

  getSingleProduct: state => (productId: string) => {
    const { products } = state;
    let product = null;
    if (products) {
      const { products: customerProducts } = products;
      product = customerProducts.find(({ product_id: id }) => id === productId);
    }
    return product;
  },

  // TODO: we don't want to keep localStorage support in Vuex getter! Do we still need this? Move this to the lsService!
  // eslint-disable-next-line no-unused-vars
  modules: state => {
    let modules;
    try {
      const lsModules = lsService.getItem('modules');
      if (lsModules) {
        modules = JSON.parse(lsModules);
      }
    } catch (e) {
      modules = lsService.getItem('modules');
    }
    return modules || [];
  },

  showNotes: (state, getters) => {
    const notesName = process.env.VUE_APP_NOTES || 0;
    const notesId = process.env.VUE_APP_NOTES_ID || 0;
    if (Array.isArray(getters.modules)) {
      return getters.modules.indexOf(notesName) !== -1;
    }
    return getters.modules.split(',').indexOf(notesId) !== -1;
  },

  showLivestockTrade: (state, getters) => {
    const livestockTradeName = process.env.VUE_APP_LIVESTOCK_TRADE || 0;
    const livestockTradeId = process.env.VUE_APP_LIVESTOCK_TRADE_ID || 0;
    if (Array.isArray(getters.modules)) {
      return getters.modules.indexOf(livestockTradeName) !== -1;
    }
    return getters.modules.split(',').indexOf(livestockTradeId) !== -1;
  },

  preparatedOpenItems: (state): OpenItemElement[] => {
    const data = state.openItems?.data || [];
    return data.map(item => {
      const newItem = { ...item } as OpenItemElement;
      newItem.amount = +item.amount;
      newItem.overdue = overdue(newItem.due_date);
      return newItem;
    });
  },

  summaryOpenItems: (state, getters): number => {
    const { preparatedOpenItems } = getters;
    return preparatedOpenItems.reduce((a: number, { amount }: { amount: number }) => a + amount, 0);
  },

  monthNameArr: (state): TranslateResult[] => {
    if (state.valuesPerMonth?.values_per_month) {
      return state.valuesPerMonth.values_per_month.map((el: MonthValue) => {
        const monthNum = el.month.split('-')[1];

        return VueI18n.t(monthName[monthNum]);
      });
    }
    return [];
  },

  getChartSummary: ({ valuesPerMonth }) => (type: 'total_value' | 'total_quantity' | 'total_weight'): string => {
    if (valuesPerMonth?.values_per_month) {
      return valuesPerMonth.values_per_month.reduce((sum: number, el: MonthValue) => (el[type] ? sum + +el[type] : sum), 0).toFixed(2);
    }
    return '0.00';
  },

  chartData: ({ valuesPerMonth }) => (type: 'total_value' | 'total_quantity' | 'total_weight'): string[] => {
    if (valuesPerMonth?.values_per_month) {
      return valuesPerMonth.values_per_month.map((el: MonthValue) => (el[type] ? el[type] : '0'));
    }
    return [];
  },

  getDataset: (state, { chartData }) => (type: 'total_value' | 'total_quantity' | 'total_weight'): Datasets[] => [
    {
      backgroundColor: '#ADCE6D',
      data: chartData(type)
    }
  ],

  salesDataToChart: (state, { getDataset, monthNameArr }) => (type: 'total_value' | 'total_quantity' | 'total_weight'): SalesDataToChart => ({
    labels: monthNameArr,
    datasets: getDataset(type)
  }),

  getProducts({ products }): ProductWithAverages[] {
    if (products?.products) {
      return products.products.map(product => {
        const { total_value, quantity, weight } = product;
        const TOTAL = +total_value;
        const QUANITY = +quantity;
        const WEIGHT = +weight;
        const quanityPrice = QUANITY ? TOTAL / QUANITY : 0;
        const weightPrice = WEIGHT ? TOTAL / WEIGHT : 0;

        const priceByQuantity = Math.round((quanityPrice + Number.EPSILON) * 100) / 100;
        const priceByWeight = Math.round((weightPrice + Number.EPSILON) * 100) / 100;
        return { ...product, priceByQuantity, priceByWeight } as ProductWithAverages;
      });
    }
    return [];
  },

  isProduct({ products }): boolean {
    return !!products?.products.length;
  },

  // Pattern ex.: 20009
  getCustomerId(state): string {
    if (state.currentContact.customer_id) {
      return state.currentContact.customer_id;
    }
    return '';
  },

  // Pattern ex.: 0220009
  getContactId(state): string {
    if (state.currentContact) {
      return state.currentContact.id;
    }
    return '';
  }
});

export default mainGetters;
