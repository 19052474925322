








import Vue from 'vue';
import authStore from '@/store/modules/auth';
import Component from 'vue-class-component';
import Navbar from '@/views/Contacts/ContactsNavbar/index.vue';
import NavBottom from '@/views/Contacts/ContactsNavBottom/index.vue';

@Component({
  components: {
    Navbar,
    NavBottom
  }
})
export default class ContactsApp extends Vue {
  // eslint-disable-next-line class-methods-use-this
  get isLoggedIn(): boolean {
    return authStore.isLoggedIn;
  }
}
