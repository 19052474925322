import { ProductState } from '@/store/modules/product/types';

const state: ProductState = {
  availableDeliveryDates: [],
  availableProducts: [],
  defaultProduct: null,
  defaultPrice: 0,
  deliveryDate: null,
  product_id: '',
  amount: null,
  additional_info: null,
  internal_info: null
};

export default state;
