








import Vue from 'vue';
import eventBus from '@/services/eventBus';
import { mapState, mapMutations } from 'vuex';

export default Vue.extend({
  name: 'OrdersFilter',
  components: {
    FitlerDrawerRadios: () => import('@/components/Common/FilterDrawer/FilterDrawerRadios.vue'),
    FilterDrawerDates: () => import('@/components/Common/FilterDrawer/FilterDrawerDates.vue')
  },
  data() {
    return {
      date: {
        dateFrom: '',
        dateTo: ''
      },
      selectedStatus: '0',
      statusFilter: [
        {
          title: 'show_all',
          name: 'show-all',
          value: '0'
        },
        {
          title: 'reservation',
          name: 'reservation',
          value: '100'
        },
        {
          title: 'order',
          name: 'order',
          value: '200'
        }
      ]
    };
  },
  computed: {
    ...mapState('order', ['status', 'dates'])
  },
  methods: {
    ...mapMutations('order', ['SET_STATUS', 'SET_DATES']),
    changeStatus(value: string): void {
      this.selectedStatus = value;
      eventBus.$emit('tagChanged', value);
    },
    searchByDates(): void {
      const { dateFrom, dateTo } = this.date;
      const isoDates = {
        dateFrom: dateFrom ? new Date(dateFrom).toISOString() : '',
        dateTo: dateTo ? new Date(dateTo).toISOString() : ''
      };
      this.SET_DATES(isoDates);
      eventBus.$emit('findByDates');
    },
    changeFromDate(value: string): void {
      this.date.dateFrom = value;
    },
    changeToDate(value: string): void {
      this.date.dateTo = value;
    }
  },
  created() {
    this.selectedStatus = this.status;
    this.date = this.dates;
  }
});
