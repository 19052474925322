import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { Intro, IntroSlide } from '../models';

@Module({
  namespaced: true,
  name: 'intros',
  dynamic: true,
  store,
  stateFactory: true
})
class IntrosModule extends VuexModule {
  slide: IntroSlide = {
    title: '',
    description: '',
    intro: '',
    image: ''
  };

  slides: IntroSlide[] = [this.slide];

  intro: Intro = {
    app: '',
    location: '',
    name: '',
    slides: this.slides
  };

  intros: Intro[] = [this.intro];

  showIntros: number[] = [];

  @Mutation
  addIntros(intros: Intro[]) {
    // eslint-disable-next-line no-unused-vars
    let rest;
    this.intros = intros;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [this.intro, ...rest] = this.intros;
    this.slides = this.intro && 'slides' in this.intro ? this.intro.slides : [];

    this.intros.forEach((intro: Intro) => {
      if (intro.id) {
        this.showIntros.push(intro.id);
      }
    });
  }

  @Mutation
  changeShowIntros(payload: number[]) {
    this.showIntros = payload;
  }
}

export default getModule(IntrosModule);
