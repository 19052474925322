/* eslint-disable camelcase */

interface IParam {
  status?: number;
  date_from?: string;
  date_to?: string;
  limit?: number;
  offset?: number;
}

interface IObjectKeys extends IParam {
  [key: string]: string | number | undefined;
}

function paramBuilder(params: IObjectKeys): IParam {
  const param: IObjectKeys = {};
  Object.keys(params).forEach(key => {
    const value = params[key];
    if (value) {
      param[key] = value;
    }
  });
  return param;
}

export default paramBuilder;
