import { GetApiVersionResponse, ApiVersionRepository } from '@/services/api/IEntityRepository';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import lsService from '@/services/localStorage/lsService';

class ApiVersionsRepository implements ApiVersionRepository {
  resource = '/test';

  async get(): Promise<AxiosResponse<GetApiVersionResponse> | void> {
    const axiosConfig: AxiosRequestConfig = {};
    const tokenLm: string = lsService.getItem('tokenLm') || '';
    const tokenFo: string = lsService.getItem('tokenFo') || '';

    axiosConfig.baseURL = lsService.getItem('apiUrl') as string;

    if (axiosConfig.baseURL.includes('licensemanager')) {
      axiosConfig.headers = {
        common: {
          Authorization: `Bearer ${tokenLm}`
        }
      };
    } else {
      axiosConfig.headers = {
        common: {
          Authorization: `Bearer ${tokenFo}`
        }
      };
    }

    const axios = Axios.create(axiosConfig);
    axios.get(`${this.resource}`).then(res => {
      const { apiVersion, dbVersion } = res.data;
      lsService.setItem('apiVersion', apiVersion);
      lsService.setItem('dbVersion', dbVersion);
    });
  }
}

export default new ApiVersionsRepository();
