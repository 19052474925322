

















































































import Vue from 'vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import convertDate from '@/utils/ConvertDate';
import Sortable from 'sortablejs';
import Factory from '@/services/api/RepositoryFactory';
import { OrdersRepository } from '@/services/api/IEntityRepository';
import global from '@/store/modules/global';
import { Order } from '@/services/api/ordersRepository/types';
import watchTable from '@/utils/watchTable';
import lsService from '@/services/localStorage/lsService';
import FilterDrawer from '@/components/Common/FilterDrawer/FilterDrawer.vue';
import OrdersFilter from '@/views/Orders/OrdersFilter/OrdersFilter.vue';
import eventBus from '@/services/eventBus';
import paramBuilder from '@/utils/ParamBuilder';
import infiniteScroll from '@/utils/InfiniteScroll';

const factory = new Factory();

export default Vue.extend({
  name: 'OrdersList',
  components: {
    FilterDrawer,
    OrdersFilter
  },
  data() {
    return {
      bottom: false,
      showButton: false,
      anIncresingNumber: 0,
      search: '',
      title: 'orders',
      view: 'allOrders',
      headersValue: ['delivery_date', 'account_id', 'account_name', 'order_nr', 'order_status', 'pos', 'creation_date'],
      tableHeaders: [
        {
          text: 'delivery_date',
          align: 'center',
          value: 'delivery_date',
          width: '100px'
        },
        {
          text: 'customer_nr',
          align: 'center',
          value: 'account_id',
          width: '100px'
        },
        {
          text: 'customer',
          align: 'center',
          value: 'account_name',
          width: '100px'
        },
        {
          text: 'order_nr',
          align: 'center',
          value: 'id',
          width: '100px'
        },
        {
          text: 'order_status',
          align: 'center',
          value: 'status_name',
          width: '100px'
        },
        {
          text: 'pos',
          align: 'center',
          value: 'order_item_total',
          width: '100px'
        },
        {
          text: 'creation_date',
          align: 'center',
          value: 'creation_date',
          width: '100px'
        }
      ]
    };
  },
  computed: {
    ...mapState('order', ['orders', 'limit', 'offset', 'status', 'dates', 'count', 'sortBy', 'sortDesc']),
    ...mapState('global', ['loadingCounter']),
    orderRepository(): OrdersRepository {
      return factory.get('order') as OrdersRepository;
    },
    filterDrawer: {
      get() {
        return global.filterDrawer;
      },
      set(value: boolean) {
        global.showFilterDrawer(value);
      }
    },
    canFetch(): boolean {
      return this.count === this.limit;
    }
  },

  methods: {
    ...mapActions('order', ['hideOrderDetail']),
    ...mapMutations('order', ['SET_OFFSET', 'CLEAR_ORDERS', 'SET_STATUS', 'SET_SORTBY', 'SET_SORTDESC']),
    changeSortBy(value: string) {
      this.SET_SORTBY(value);
    },
    changeSortDesc(value: boolean) {
      this.SET_SORTDESC(value);
    },
    tagChangedCallback(name: string) {
      this.SET_STATUS(name);
      this.CLEAR_ORDERS();
      this.SET_OFFSET(0);
      this.getOrders();
    },
    getOrders(): void {
      const { dateFrom, dateTo } = this.dates;
      const { limit, offset, status: storeStatus } = this;
      const status = parseInt(storeStatus, 10);
      const query = paramBuilder({ date_from: dateFrom, date_to: dateTo, status, limit, offset });
      this.orderRepository.get(query);
    },
    searchByDates() {
      this.CLEAR_ORDERS();
      this.SET_OFFSET(0);
      this.getOrders();
    },
    redirectToOrderDetail(item: Order): void {
      this.$router.push(`${item.id}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortHeaders(event: any): void {
      const headersTmp = this.tableHeaders;
      const { oldIndex } = event;
      const { newIndex } = event;

      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.tableHeaders = headersTmp;
      lsService.setItem('tableLayout', JSON.stringify(this.tableHeaders));
      this.anIncresingNumber += 1;
    },
    getTableLayout(): void {
      const layout = lsService.getItem('tableLayout');
      if (layout) {
        this.tableHeaders = JSON.parse(layout);
      }
    },
    scrollToTop(): void {
      const target = 0;
      const options = {
        duration: 500
      };
      this.$vuetify.goTo(target, options);
    },
    onScroll() {
      const currentScrollPosition = window.pageYOffset;
      if (currentScrollPosition < 150) {
        this.showButton = false;
      } else {
        this.showButton = true;
      }
    },
    convertData(orderDate: Date) {
      return convertDate(orderDate);
    },
    getTextColor(status: string) {
      if (status === 'reservation') {
        return 'statusText--secondary';
      }
      if (status === 'order') {
        return 'statusText--primary';
      }
      return '';
    },
    bottomVisible() {
      this.bottom = infiniteScroll();
    }
  },

  directives: {
    'sortable-table': {
      inserted: (el, binding) => {
        const vibrate = () => {
          navigator.vibrate(250);
        };
        // eslint-disable-next-line arrow-parens
        el.querySelectorAll('th').forEach(draggableEl => {
          watchTable(draggableEl, 'sortHandle');
          draggableEl.classList.add('sortHandle');
        });
        const tr = el.querySelector('tr');
        if (tr) {
          Sortable.create(tr, binding.value ? { ...binding.value, handle: '.sortHandle', delay: 250, onChoose: vibrate } : {});
        }
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    this.getOrders();
    if (this.sortBy === '') {
      this.SET_SORTBY(this.tableHeaders[6].value);
    }
    this.hideOrderDetail();
    global.changeTitle('orders');
    global.changeView(this.view);
    global.setNestedRoute(false);
    this.getTableLayout();
  },
  created() {
    window.addEventListener('scroll', this.bottomVisible);
    eventBus.$on('tagChanged', this.tagChangedCallback);
    eventBus.$on('findByDates', this.searchByDates);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.bottomVisible);
    window.removeEventListener('scroll', this.onScroll);
    eventBus.$off('tagChanged', this.tagChangedCallback);
    eventBus.$off('findByDates', this.searchByDates);
  },
  watch: {
    bottom: {
      handler(newValue) {
        if (newValue && !this.loadingCounter && this.canFetch) {
          this.SET_OFFSET(this.offset + this.limit);
          this.getOrders();
        }
      }
    }
  }
});
