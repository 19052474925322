import { getterTree } from 'typed-vuex';
import lsService from '@/services/localStorage/lsService';
import initialState from './state';

const mainGetters = getterTree(initialState, {
  getOrderById: state => (id: string) => state.orders.find(order => order.id === id),

  // TODO: we don't want to keep localStorage support in Vuex getter! Do we still need this? Move this to the lsService!
  // eslint-disable-next-line no-unused-vars
  modules: state => {
    let modules;
    try {
      const lsModules = lsService.getItem('modules');
      if (lsModules) {
        modules = JSON.parse(lsModules);
      }
    } catch (e) {
      modules = lsService.getItem('modules');
    }
    return modules || [];
  },

  tabs: () => [
    {
      title: 'orders',
      link: '/orders/all'
    },
    {
      title: 'planning-list',
      link: '/orders/planning-list'
    }
  ],

  getOrderDraft: state => state.orderDraft,

  getOrderDraftByIndex: state => (index: number) => {
    if (state.orderDraft && state.orderDraft.orderItems && state.orderDraft.orderItems.length > 0) {
      return state.orderDraft.orderItems[index];
    }
    return null;
  }
});

export default mainGetters;
