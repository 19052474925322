





















































import Vue from 'vue';
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import lsService from '@/services/localStorage/lsService';
import VueI18n from '@/i18n';
import { mapState } from 'vuex';

extend('required', {
  ...required,
  message: VueI18n.t('required') as string
});

extend('email', {
  ...email,
  message: VueI18n.t('Please enter an E-Mail') as string
});

export default Vue.extend({
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: ''
    };
  },
  computed: {
    ...mapState('global', ['online', 'loadingCounter'])
  },
  methods: {
    login(): void {
      this.$authService.login(this.email.trim(), this.password);
    },
    setEmail() {
      const emailFromLS = lsService.getItem('email');
      if (emailFromLS) {
        this.email = emailFromLS;
      }
    }
  },
  mounted() {
    this.setEmail();
  }
});
