



























import Vue from 'vue';
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { TranslateResult } from 'vue-i18n';
import ProductViewFrom from '@/views/Orders/ProductView/ProductViewForm.vue';
import Popup from '@/components/Common/Popup.vue';
import Product from '@/components/Common/ProductCard/Product.vue';
import Factory from '@/services/api/RepositoryFactory';
import { ContactRepository, OrdersRepository, ProductsRepository, StatisticsRepository } from '@/services/api/IEntityRepository';
import global from '@/store/modules/global';
import { ProductStatistics } from '@/services/api/statisticsRepository/types';
import { ProductViewState } from './types';

const factory = new Factory();

export default Vue.extend({
  components: {
    Popup,
    Product,
    ProductViewFrom
  },
  data() {
    return {
      showConfirmPopup: false,
      loading: false,
      product: {},
      productStatistic: {},
      newOrder: {}
    } as ProductViewState;
  },
  computed: {
    ...mapState('contact', ['currentContact', 'products']),
    ...mapGetters('contact', ['isContact', 'getSingleProduct']),
    defaultPrice(): string {
      return this.productStatistic ? this.productStatistic.last_price : '';
    },
    defaultAmount(): string {
      const amount = this.productStatistic?.last_amount;
      return amount ? amount.replace(/\D+/g, '') : '';
    },
    contactRepository(): ContactRepository {
      return factory.get('contacts') as ContactRepository;
    },
    productRepository(): ProductsRepository {
      return factory.get('products') as ProductsRepository;
    },
    orderRepository(): OrdersRepository {
      return factory.get('order') as OrdersRepository;
    },
    statisticsRepositry(): StatisticsRepository {
      return factory.get('statistics') as StatisticsRepository;
    },
    accountName(): string {
      return this.currentContact.description;
    },
    accountId(): string {
      const { account_id: accountId } = this.$route.query;
      return typeof accountId === 'string' ? accountId : '';
    },
    productId(): string {
      const { product_id: productId } = this.$route.query;
      return typeof productId === 'string' ? productId : '';
    },
    customerId(): string {
      const { customer_id: customerId } = this.$route.query;
      return typeof customerId === 'string' ? customerId : '';
    },
    headline(): TranslateResult {
      const orderBasic = this.$t('order-taken');
      return orderBasic;
    },
    additionalText(): TranslateResult {
      const orderId = this.newOrder.id;
      const orderTextBasic = this.$t('order-taken-additional', { orderId });
      return orderTextBasic;
    },
    lastPrice(): number {
      const lastPrice = this.productStatistic?.last_price || '0';
      const value = parseFloat(lastPrice);
      return !isNaN(value) ? value : 0;
    }
  },
  methods: {
    ...mapMutations('global', ['setNestedRoute']),
    ...mapActions('order', ['showOrderDetail']),

    setView(): void {
      this.setNestedRoute(true);
      this.showOrderDetail();
    },
    async getContactDetails(): Promise<void> {
      if (!this.isContact) {
        await this.contactRepository.getOne(this.accountId);
      }
      global.changeTitle(this.currentContact.description);
    },
    async getProduct(): Promise<void> {
      const { data } = await this.productRepository.getProduct(this.productId);
      this.product = data;
    },
    async processOrder(): Promise<void> {
      this.loading = true;
      await this.orderRepository.processOrder(this.newOrder.links.process_order);
      this.loading = false;
      this.showConfirmPopup = false;
    },
    async getProductStatistic(): Promise<void> {
      const productStat = this.getSingleProduct(this.productId);
      if (productStat) {
        this.productStatistic = productStat;
      } else {
        const {
          data: { products }
        } = await this.statisticsRepositry.get(this.customerId);
        const stat: ProductStatistics | undefined = products.find((product: ProductStatistics) => product.product_id === this.productId);
        this.productStatistic = stat;
      }
    },

    backToProducts(): void {
      this.showConfirmPopup = false;
      this.$router.push({ path: `/contacts/contact_detail/${this.accountId}/products` });
    }
  },
  created() {
    this.getContactDetails();
    this.getProduct();
    this.getProductStatistic();
    this.setView();
  },

  beforeDestroy() {
    this.setNestedRoute(false);
  }
});
