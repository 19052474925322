<template>
  <v-list class="notes-list">
    <v-list-item v-for="note in notes" :key="note.id">
      <Note :note="note" :contact_id="contactId" />
    </v-list-item>
  </v-list>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import Note from './Note.vue';

@Component({
  components: {
    Note
  },
  props: {
    notes: Array,
    contactId: String
  }
})
export default class NotesList extends Vue {}
</script>
