

























/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import Notification from '@/views/Contacts/ContactDetailsView/OpenItemsCard/OpenItemsNotification.vue';
import Summary from '@/views/Contacts/ContactDetailsView/OpenItemsCard/Summary.vue';
import OpenItem from '@/views/Contacts/ContactDetailsView/OpenItemsCard/OpenItem.vue';
import ArrayUtils from '@/utils/ArrayUtils';
import Factory from '@/services/api/RepositoryFactory';
import { ContactRepository } from '@/services/api/IEntityRepository';
import { mapActions, mapGetters, mapState } from 'vuex';
import { OpenItemElement } from '@/store/modules/contact/types';
import licenseStore from '@/store/modules/license';

const factory = new Factory();

export default Vue.extend({
  name: 'OpenItemsCard',
  components: {
    Notification,
    Summary,
    OpenItem
  },
  data() {
    return {
      selected: [],
      selectedSort: 'amount',
      sortOptions: [
        { label: this.$t('open_items.receipt_id'), value: 'receipt_id' },
        { label: this.$t('open_items.date'), value: 'date' },
        { label: this.$t('open_items.due_date'), value: 'due_date' },
        { label: this.$t('open_items.amount'), value: 'amount' }
      ],
      sortType: 'DESC',
      sortIcons: {
        ASC: 'mdi-sort-ascending',
        DESC: 'mdi-sort-descending'
      } as { [key: string]: string }
    };
  },
  computed: {
    ...mapState('contact', ['currentContact']),
    ...mapGetters('contact', ['preparatedOpenItems', 'summaryOpenItems']),
    contactsRepository(): ContactRepository {
      return factory.get('contacts') as ContactRepository;
    },
    openItemsSorted(): OpenItemElement[] {
      return ArrayUtils.alphabeticSort(this.preparatedOpenItems, this.selectedSort, this.sortType);
    },
    outerIcon(): string {
      return this.sortIcons[this.sortType];
    },
    isOrderAppModuleActive() {
      return licenseStore.isOrderAppModuleActive;
    }
  },
  methods: {
    ...mapActions('contact', ['removeOpenItems']),
    setSortType(): void {
      if (this.sortType === 'ASC') {
        this.sortType = 'DESC';
      } else {
        this.sortType = 'ASC';
      }
    },

    setOpenItems(): void {
      if (this.currentContact.links?.open_items) {
        this.contactsRepository.getOpenItems(this.currentContact.links.open_items);
      } else {
        this.removeOpenItems();
      }
    }
  },
  mounted() {
    this.setOpenItems();
  },
  watch: {
    currentContact() {
      this.setOpenItems();
    },
    isOrderAppModuleActive() {
      if (!this.isOrderAppModuleActive) {
        this.$router.push('/contacts/search');
      }
    }
  },
  beforeDestroy() {
    this.removeOpenItems();
  }
});
