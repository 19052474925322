import { RootState } from '@/store/types';
import { Module } from 'vuex';
import { ProductState } from '@/store/modules/product/types';
import state from '@/store/modules/product/state';
import mutations from '@/store/modules/product/mutations';
import actions from '@/store/modules/product/actions';
import getters from '@/store/modules/product/getters';

const product: Module<ProductState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default product;
