import Axios from '@/services/api/Repository';
import { StatisticsRepository } from '@/services/api/IEntityRepository';

const statisticsRepository: StatisticsRepository = {
  resource: 'statistics/',

  get(customerId) {
    return Axios.get(`${this.resource}products`, { params: { customer_id: customerId } });
  }
};

export default statisticsRepository;
