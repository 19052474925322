import Axios from '@/services/apiKAC/Repository';
import { VersionRepositry } from './IEntityRepository';

const versionRepository: VersionRepositry = {
  resource: 'api/docs.json',

  async getVersion() {
    return Axios(this.resource).then(res => res.data.info.version);
  }
};

export default versionRepository;
