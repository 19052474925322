

















import Vue from 'vue';
import globalStore from '@/store/modules/global';

export default Vue.extend({
  name: 'FilterDrawer',
  computed: {
    drawer: {
      get() {
        return globalStore.filterDrawer;
      },
      set(value: boolean) {
        globalStore.showFilterDrawer(value);
      }
    }
  }
});
