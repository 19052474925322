






import Vue from 'vue';
import Ordering from '@/components/Ordering/Ordering.vue';
import licenseStore from '@/store/modules/license';

export default Vue.extend({
  name: 'OrderingView',
  components: {
    Ordering
  },
  data: () => ({}),
  computed: {
    isOrderAppModuleActive() {
      return licenseStore.isOrderAppModuleActive;
    }
  },
  watch: {
    isOrderAppModuleActive() {
      if (!this.isOrderAppModuleActive) {
        this.$router.push('/contacts/search');
      }
    }
  }
});
