<template>
  <div class="carousel-detail" v-if="shouldShowIntro">
    <v-carousel height="100vh" v-model="model" @change="checkInput" :prev-icon="false" :next-icon="showNext" :touch="{}">
      <v-carousel-item v-for="(slide, i) in slides" :key="i" style="height: 100%">
        <v-card height="100%" tile color="primary">
          <v-row align="center" justify="center" style="height: 60%">
            <ImageComponent v-if="slide.image" :iri="slide.image" />
          </v-row>
          <v-row no-gutters style="height: 40%">
            <v-col class="pa-2">
              <v-row no-gutters class="pb-2">
                <v-col>
                  <span class="slide-title brand-headline">{{ slide.title }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="slide-description pt-0">
                  <span class="brand-body">{{ slide.description }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-carousel-item>

      <v-btn rounded icon @click="finish" v-show="showNext" text class="skip font-weight-light"><v-icon>mdi-close</v-icon></v-btn>
      <v-btn rounded icon @click="finish" v-show="!showNext" text class="finish"><v-icon>mdi-close</v-icon></v-btn>
    </v-carousel>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import wizardService from '@/services/wizardService';
import introsStore from '@/store/modules/intros';
import lsService from '@/services/localStorage/lsService';
import ImageComponent from './WizardImage.vue';

@Component({
  components: { ImageComponent },
  props: {
    intro: {}
  }
})
export default class WizardCarousel extends Vue {
  name = 'CarouselDetail';

  model = 0;

  showRightIndicator = '$next';

  get showNext() {
    return this.showRightIndicator;
  }

  get slides() {
    return this.intro.slides;
  }

  get shouldShowIntro() {
    const inIntros = introsStore.showIntros.includes(this.intro.id);
    const wasNotSeen = !this.wasSeen(this.intro.id);
    const validLocation = this.$router.currentRoute.path === this.intro.location;
    return inIntros && wasNotSeen && validLocation;
  }

  finish() {
    wizardService.hideIntro(this.intro.id);
  }

  wasSeen = introId => {
    const seenIntros = lsService.getSeenIntros();
    const allIntros = lsService.getIntros();
    let found = false;

    if (!(seenIntros && allIntros)) {
      return false;
    }

    seenIntros.forEach(seenIntroId => {
      if (introId === seenIntroId) {
        found = true;
      }
    });

    return found;
  };

  checkInput(slideIndex) {
    if (!Array.isArray(this.slides) || this.slides.length === 0) {
      return;
    }

    if (this.slides.length - 1 === slideIndex) {
      this.showRightIndicator = '';
    }
  }

  created() {
    if (!Array.isArray(this.slides) || this.slides.length === 1) {
      this.showRightIndicator = '';
    }
  }
}
</script>

<style scoped></style>
