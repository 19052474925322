/* eslint-disable camelcase */
import { getModule, Module, Mutation, Action, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { IApplicationLicense } from '../models';

/**
 * Store information about licenses for current logged-in user context
 */
@Module({
  namespaced: true,
  name: 'license',
  dynamic: true,
  store,
  stateFactory: true
})
class LicenseModule extends VuexModule {
  licenses: Array<IApplicationLicense> = [];

  isOrderAppModuleActive = false;

  @Mutation
  add_license(payload: IApplicationLicense) {
    const index = this.licenses.findIndex(license => license.app === payload.app);
    if (index !== -1) {
      // If already found, then override license with new data and use the same index.
      this.licenses.splice(index, 1, payload);
    } else {
      this.licenses.push(payload);
    }
  }

  @Mutation
  order_module_activation(value: boolean) {
    this.isOrderAppModuleActive = value;
  }

  @Action
  async orderModuleActivate() {
    this.order_module_activation(true);
  }

  @Action
  async orderModuleDeactivate() {
    this.order_module_activation(false);
  }

  @Action
  async getLicenseByApp(appCode: string) {
    const licensesByApp = this.licenses.filter(license => license.app === appCode);
    if (licensesByApp.length !== 1) {
      // If more app with the same APP_CODE or app does not exist
      return null;
    }
    // Return first matched license app
    return licensesByApp[0];
  }
}

export default getModule(LicenseModule);
