import { OrdersRepository } from '@/services/api/IEntityRepository';
import Axios from '@/services/api/Repository';
import store from '@/store/index';
import { GetOrdersProductsResponse, GetOrdersResponse, SingleOrderResponse } from '@/services/api/ordersRepository/types';
import { AxiosResponse } from 'axios';

const ordersRepository: OrdersRepository = {
  resource: '/orders',

  // eslint-disable-next-line camelcase
  get(params: { search?: string; status?: number; date_from?: string; date_to?: string } = {}) {
    return Axios.get(this.resource, { params }).then((res: AxiosResponse<GetOrdersResponse>) => {
      const {
        data: { count, data: orders }
      } = res;
      store.dispatch('order/setOrders', orders);
      store.commit('order/SET_COUNT', count);
    });
  },
  getById(orderId: string) {
    return Axios.get(`${this.resource}/${orderId}`).then((res: AxiosResponse<SingleOrderResponse>) => {
      const { data } = res;
      store.dispatch('order/setOrder', data);
    });
  },

  create(newOrder) {
    return Axios.post(this.resource, newOrder);
  },

  getOrdersProducts(link: string, orderId: string) {
    return Axios.get(link).then((res: AxiosResponse<GetOrdersProductsResponse>) => {
      const { data } = res;
      store.dispatch('order/addProductsToOrder', { products: data.data, orderId });
    });
  },
  cancelOrder(link: string) {
    return Axios.delete(link);
  },
  processOrder(link: string) {
    return Axios.post(link);
  }
};

export default ordersRepository;
