


















import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';

export default Vue.extend({
  name: 'ErrorPopup',
  computed: {
    ...mapState('global', ['showError', 'errorFrom', 'errorText'])
  },
  methods: {
    ...mapMutations('global', ['changeErrorStatus', 'setErrorText', 'setErrorFrom']),
    closeErrorModel() {
      this.changeErrorStatus(false);
      this.setErrorText('');
      this.setErrorFrom('');
    }
  }
});
