/* eslint-disable @typescript-eslint/no-explicit-any */
import global from '@/store/modules/global';
import VueI18n from '@/i18n';
import { IDecodedLmToken } from '@/interfaces/IDecodedLmToken';
import jwtDecode from 'jwt-decode';
import Axios from './Repository';
import { IEntityRepository } from './IEntityRepository';

class LicenceRepository implements IEntityRepository {
  resource = 'api/licences';

  async get(params: any): Promise<any> {
    try {
      const res = await Axios.get(`${this.resource}?customer.id=${params.customerId}&app.code=${params.appCode}`, {
        headers: {
          Authorization: `Bearer ${params.token}`
        }
      });
      const decodedLmToken = jwtDecode(params.token) as IDecodedLmToken;

      const userRoles = decodedLmToken.roles as Array<string>;
      if (this.isRoleSuperAdminUser(userRoles)) {
        // Login to KIRATIK App is blocked for ROLE_SUPER_ADMIN
        global.setErrorFrom('Authorization problem!');
        global.setErrorText(String(VueI18n.t('not_allowed_to_login_as_role_super_user')));
        global.changeErrorStatus(true);
        return new Promise((resolve, reject) => {
          reject(new Error(String(VueI18n.t('not_allowed_to_login_as_role_super_user'))));
        });
      }
      return new Promise(resolve => {
        resolve(res);
      });
    } catch (error) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private isRoleSuperAdminUser(roles: Array<string>): boolean {
    const userRoles = roles;
    if (userRoles.length > 0) {
      const isRoleSuperAdmin = userRoles.find(role => role === 'ROLE_SUPER_ADMIN');
      return !!isRoleSuperAdmin;
    }
    return false;
  }
}

export default new LicenceRepository();
