/* eslint-disable camelcase */
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { INote } from '../models';

@Module({
  namespaced: true,
  name: 'note',
  dynamic: true,
  store,
  stateFactory: true
})
class NoteModule extends VuexModule {
  all: INote[] = [];

  @Mutation
  add_notes(payload: []) {
    this.all = payload;
  }

  @Mutation
  update_notes(payload: INote) {
    this.all.unshift(payload);
  }

  @Mutation
  delete_note(id: string) {
    this.all = this.all.filter((note: INote) => note.id !== id);
  }
}
export default getModule(NoteModule);
