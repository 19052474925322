import '@mdi/font/css/materialdesignicons.css';
import 'font-awesome/css/font-awesome.min.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        success: '#ADCE6D',
        primary: '#005572',
        secondary: '#ADCE6D'
      }
    }
  }
});
