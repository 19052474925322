<template>
  <v-card width="100%" class="pt-4 mb-4">
    <v-row no-gutters>
      <v-col cols="10">
        <v-card-title class="pt-0">{{ actualNote.title }}</v-card-title>
        <v-card-subtitle>a {{ actualNote.user }} {{ actualNote.created }} </v-card-subtitle>
      </v-col>
      <v-col cols="2">
        <v-card-actions class="pa-0 ma-0 justify-center">
          <v-icon @click="deleteNote" v-show="online && actualNote.user == user">mdi-delete</v-icon>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-card-text>{{ actualNote.text }}</v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable class-methods-use-this */
import lsService from '@/services/localStorage/lsService';
import globalStore from '@/store/modules/global';
import noteStore from '@/store/modules/note';

import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    note: Object,
    contactId: String
  }
})
export default class Note extends Vue {
  notesRepository = this.$repositoryFactory.get('notes');

  get online() {
    return globalStore.online;
  }

  get user() {
    return lsService.getItem('user');
  }

  get actualNote() {
    return this.note;
  }

  deleteNote() {
    const callback = data => {
      data.data = data.data.filter(note => note.id !== this.note.id);
      return data;
    };

    if (confirm('Are you sure delete note?')) {
      noteStore.delete_note(this.note.id);

      this.$store.dispatch('pwaService/modifyResponse', {
        path: `notes?contact_id=${this.contactId}`,
        storageName: 'api-cache',
        callback
      });

      this.notesRepository.delete(this.note.id);
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.v-card__title {
  @extend .brand-subheader;
  color: $brand-black !important;
}

::v-deep.v-card__subtitle {
  @extend .brand-caption;
  color: $brand-new-black-30 !important;
  padding: 4px 16px;
}

::v-deep.v-card__text {
  color: $brand-black !important;
  padding: 4px 16px 12px 16px;
}
</style>
