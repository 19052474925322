<template>
  <v-app>
    <v-main class="pa-0">
      <router-view />
      <WizardCarousel :intros="intros" />
    </v-main>
    <ErrorPopup />
  </v-app>
</template>

<script>
/* eslint-disable class-methods-use-this */
import globalStore from '@/store/modules/global';
import introsStore from '@/store/modules/intros';
import { Component, Vue } from 'vue-property-decorator';
import versionRepository from '@/services/apiKAC/versionRepository';
import WizardCarousel from '@/views/Global/WizardCarouselView/index.vue';
import ErrorPopup from '@/components/Common/ErrorPopup.vue';

@Component({
  components: {
    WizardCarousel,
    ErrorPopup
  },
  async created() {
    await this.checkVersion();
  }
})
export default class App extends Vue {
  name = 'KiratikCloud';

  get intros() {
    return introsStore.intros;
  }

  get isLoading() {
    return globalStore.loadingCounter !== 0;
  }

  get showLoader() {
    return globalStore.showLoader;
  }

  async checkVersion() {
    const version = await versionRepository.getVersion();
    const minVersion = `${process.env.VUE_APP_KAC_MIN_VERSION}`;
    const maxVersion = `${process.env.VUE_APP_KAC_MAX_VERSION}`;

    const modVersion = parseInt(version.replace(/\./g, '').replace('v', ''), 10);
    const ModMinVersion = parseInt(minVersion.replace(/\./g, '').replace('v', ''), 10);
    const ModMaxVersion = parseInt(maxVersion.replace(/\./g, '').replace('v', ''), 10);

    if (modVersion < ModMinVersion || modVersion > ModMaxVersion) {
      // eslint-disable-next-line no-console
      console.log(
        `[Error] Unsupported version of Kiratik App - Admin Console.\n Current version: ${version}\n Supported API version: from ${minVersion} to ${maxVersion}.`
      );
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans-Light.ttf) format('truetype');
  font-weight: 300; // light
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
  font-weight: 400; // regular
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans-Bold.ttf) format('truetype');
  font-weight: 700; // bold
  font-style: normal;
}

@import './assets/scss/index.scss';
</style>
