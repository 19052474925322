import { ContactState } from '@/store/modules/contact/types';

const state: ContactState = {
  currentContact: {},
  children: [],
  valuesPerMonth: {
    customer_id: '',
    month_from: '',
    month_to: '',
    values_per_month: [
      {
        month: '',
        total_quantity: '',
        total_weight: '',
        total_value: ''
      }
    ]
  },
  products: null,
  openItems: null,
  livestockTrader: null
};

export default state;
