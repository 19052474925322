import moment from 'moment';
import i18n from '@/i18n';

function convertDate(date: Date | string | undefined): string {
  if (typeof date === 'undefined' || date === '') {
    return '';
  }
  const { locale } = i18n;
  const dateFormat = locale === 'de' ? 'DD.M.YYYY' : 'DD/MM/YYYY';
  return moment(date).format(dateFormat);
}

export default convertDate;
