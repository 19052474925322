

















/* eslint-disable class-methods-use-this,quote-props */
import Vue from 'vue';
import Factory from '@/services/api/RepositoryFactory';
import { ContactRepository } from '@/services/api/IEntityRepository';
import { mapGetters, mapState } from 'vuex';
import licenseStore from '@/store/modules/license';
import SalesChart from './SalesChart.vue';

const factory = new Factory();

type DataTypes = {
  key: string;
  title: string;
  sign: string;
};

export default Vue.extend({
  name: 'ContactDetailSales',
  components: {
    SalesChart
  },
  data() {
    return {
      dataTypes: [
        {
          key: 'total_value',
          title: 'tab_title_total_value',
          sign: '€'
        },
        {
          key: 'total_quantity',
          title: 'tab_title_total_quantity',
          sign: 'Stück'
        },
        {
          key: 'total_weight',
          title: 'tab_title_total_weight',
          sign: 'kg'
        }
      ] as DataTypes[]
    };
  },
  computed: {
    ...mapState('contact', ['currentContact']),
    ...mapGetters('contact', ['getChartSummary', 'salesDataToChart']),

    contactsRepository(): ContactRepository {
      return factory.get('contacts') as ContactRepository;
    },
    isOrderAppModuleActive() {
      return licenseStore.isOrderAppModuleActive;
    }
  },
  methods: {
    setValuesPerMonth(): void {
      if (this.currentContact.links?.values_per_month) {
        this.contactsRepository.getValuesPerMonthStatitics(this.currentContact.links.values_per_month);
      }
    }
  },
  mounted(): void {
    this.setValuesPerMonth();
  },
  watch: {
    currentContact(): void {
      this.setValuesPerMonth();
    },
    isOrderAppModuleActive() {
      if (!this.isOrderAppModuleActive) {
        this.$router.push('/contacts/search');
      }
    }
  }
});
