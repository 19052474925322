

































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  name: 'OrderingNote',
  components: {},
  props: {},
  data: () => ({
    // Modal/Dialog
    modalOrderDraftNote: false,

    orderDraftAdditionalInfo: ''
  }),
  computed: {
    ...mapGetters('order', ['getOrderDraft'])
  },
  methods: {
    ...mapActions('order', ['setOrderDraftAdditionalInfo']),

    createOrderNote() {
      this.modalOrderDraftNote = true;
      this.orderDraftAdditionalInfo = this.getOrderDraft.additionalInfo;
    },
    cancelOrderNote() {
      this.modalOrderDraftNote = false;
      this.orderDraftAdditionalInfo = this.getOrderDraft.additionalInfo;
    },
    saveOrderNote() {
      this.modalOrderDraftNote = false;
      this.setOrderDraftAdditionalInfo(this.orderDraftAdditionalInfo);
    }
  },
  watch: {},
  i18n: {
    messages: {
      en: {
        orderNote_dialogTitle: 'Additional information'
      },
      de: {
        orderNote_dialogTitle: 'Zusatzinformation Bestellung'
      }
    }
  }
});
