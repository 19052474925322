/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable class-methods-use-this,@typescript-eslint/no-explicit-any */
import lsService from '@/services/localStorage/lsService';
// import { AxiosInstance } from 'axios';
import KACRepositoryFactory from '@/services/apiKAC/RepositoryFactory';
import { IEntityRepository } from '@/services/apiKAC/IEntityRepository';
// eslint-disable-next-line import/no-cycle
import licenseStore from '@/store/modules/license';
import { IApplicationLicense } from '@/store/models';
import AppModulesEnums from '@/enums/AppModulesEnums';
// eslint-disable-next-line import/no-cycle
import AuthorizationService from '@/services/authorizationService';
import jwtDecode from 'jwt-decode';
import { IDecodedLmToken } from '@/interfaces/IDecodedLmToken';
import ApiVersionsRepository from '@/services/api/apiVersionRepository';

interface moduleContext {
  id: number;
  name: string;
}

class KACService {
  static APP_CODE = process.env.VUE_APP_APP_CODE;

  licenceRepository: IEntityRepository;

  constructor() {
    const RepFactory = new KACRepositoryFactory();
    this.licenceRepository = RepFactory.get('licence');
  }

  async getLicences(customerId: number, appCode: string, token: string): Promise<unknown> {
    try {
      const res = (await this.licenceRepository.get({ customerId, appCode, token })) as any;
      await this.addToStorage(res.data[0], token);
      if (!lsService.getItem('apiVersion')) {
        await this.getFoApiVersion();
      }

      return new Promise(resolve => {
        resolve(res.data[0]);
      });
    } catch (error) {
      this.clear();
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }

  async getFoApiVersion() {
    await ApiVersionsRepository.get();
  }

  async addToStorage(res: any, token: string) {
    // Local Storage - support
    const decodedLmToken = jwtDecode(token) as IDecodedLmToken;
    const modules = res.modules.map((module: moduleContext) => module.name);
    lsService.setItem('userId', res.id);
    lsService.setItem('userRole', decodedLmToken.roles[0]);
    lsService.setItem('apiUrl', res.api);
    lsService.setItem('modules', JSON.stringify(modules));
    lsService.setItem('isLicenseActive', res.active);
    // KA-186 intros is not used in KA. Leave a comment until something changes
    // lsService.setSeenIntros(res.data.seenIntros);

    // Vuex Storage - support - begin
    const applicationLicense: IApplicationLicense = {
      app: KACService.APP_CODE,
      modules
    };
    await licenseStore.add_license(applicationLicense);

    const authorizationService = new AuthorizationService();
    if (await authorizationService.isModuleGranted(AppModulesEnums.ORDER)) {
      await licenseStore.orderModuleActivate();
    } else {
      await licenseStore.orderModuleDeactivate();
    }
    // Vuex Storage - support - end

    return new Promise(resolve => {
      resolve(true);
    });
  }

  clear() {
    lsService.removeItem('userId');
    lsService.removeItem('userRole');
    lsService.removeItem('apiUrl');
    lsService.removeItem('modules');
    lsService.cleanSeenIntros();
  }
}

export default KACService;
