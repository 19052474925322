/* eslint-disable class-methods-use-this */
import { TokenPayload } from '../store/models';

class tokenServices {
  tokenIsCurrent(token: string | null): boolean {
    const currentData = this.parseDataNow();
    const tokenExp: number = token ? this.encodeToken(token).exp : 0;
    return currentData < tokenExp;
  }

  parseDataNow(): number {
    const seconds = 1000;
    const now = Date.now();
    const shiftTimeZoneInSeconds = 60 * 60 * 2; // 2 hours in seconds
    return Math.round(now / seconds) + shiftTimeZoneInSeconds;
  }

  encodeToken(token: string): TokenPayload {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}

export default tokenServices;
