var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification"},[_c('v-bottom-sheet',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","absolute":"","left":"","top":""}},on),[_c('v-icon',[_vm._v(" mdi-help-circle ")])],1)]}}]),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-card',[_c('v-row',{staticClass:"pt-1"},[_c('v-col',[_c('v-btn',{attrs:{"absolute":"","left":"","top":"","icon":""},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',{staticClass:"headline text-center"},[_vm._v(" "+_vm._s(_vm.$t('open_items.notification.sheet.title'))+" ")]),_c('v-col')],1),_vm._l((_vm.contactInfo),function(ref,index){
var action = ref.action;
var context = ref.context;
var type = ref.type;
var icon = ref.icon;
return _c('v-list',{key:index,attrs:{"width":"100%","two-line":""}},[_c('v-list-item',{staticClass:"pl-6",on:{"click":function($event){return action(context)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t(("" + type))))]),_c('v-list-item-title',[_vm._v(_vm._s(context))])],1)],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }