import {
  IEntityRepository,
  ContactRepository,
  ProductsRepository,
  OrdersRepository,
  IPlanningListsRepository,
  PermissionRepository,
  ApiVersionRepository
} from '@/services/api/IEntityRepository';
import PlanningListsRepository from '@/services/api/planningListsRepository/PlanningListsRepository';
import permissionsRepository from '@/services/api/permissionsRepository';
import apiVersionRepository from '@/services/api/apiVersionRepository';
import ordersRepository from './ordersRepository/ordersRepository';
import contactsRepository from './contactsRepository';
import NotesRepository from './notesRepository';
import productsRepository from './productsRepository/productsRepository';
import statisticsRepository from './statisticsRepository/statisticsRepository';

export default class RepositoryFactory {
  private repositories: { [index: string]: IEntityRepository } = {
    contacts: contactsRepository,
    notes: NotesRepository,
    products: productsRepository,
    order: ordersRepository,
    statistics: statisticsRepository,
    permissions: permissionsRepository,
    apiversion: apiVersionRepository
  };

  private static repositories: { [index: string]: IEntityRepository } = {
    planninglists: new PlanningListsRepository()
  };

  public get(
    name: string
  ):
    | IEntityRepository
    | ContactRepository
    | ProductsRepository
    | OrdersRepository
    | IPlanningListsRepository
    | PermissionRepository
    | ApiVersionRepository {
    return this.repositories[name];
  }

  public static getStatic(name: string): IPlanningListsRepository {
    return this.repositories[name];
  }
}
