















































/* eslint-disable camelcase */
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Factory from '@/services/api/RepositoryFactory';
import { OrdersRepository } from '@/services/api/IEntityRepository';
import { TranslateResult } from 'vue-i18n';
import { Order } from '@/services/api/ordersRepository/types';
import global from '@/store/modules/global';
import { Product } from '@/services/api/productsRepository/types';
import ProductCardInfo from '@/views/Orders/Order/OrderProductCardInfo.vue';
import InfoCardsWrapper from '@/components/Common/InfoCards/InfoCardsWrapper.vue';
import OrderProduct from '@/views/Orders/Order/OrderProductInfo.vue';
import Popup from '@/components/Common/Popup.vue';
import convertDate from '@/utils/ConvertDate';

const factory = new Factory();
export default Vue.extend({
  name: 'index',
  components: {
    ProductCardInfo,
    InfoCardsWrapper,
    OrderProduct,
    Popup
  },

  props: ['orderId'],
  data() {
    return {
      showPopup: false,
      popupMethod: '',
      loading: false,
      orderCanceled: false
    };
  },
  computed: {
    ...mapState('order', ['status']),
    ...mapGetters('order', ['getOrderById']),

    additionalText(): TranslateResult {
      const removingOrder = this.popupMethod === 'cancel';
      const orderId = this.order?.id ? this.order?.id : '';
      const removingText = this.$t('confirm_removing_order', { orderId });
      const processingText = this.$t('confirm_processing_order', { orderId });
      return removingOrder ? removingText : processingText;
    },
    headline(): TranslateResult {
      const removingOrder = this.popupMethod === 'cancel';
      const removingText = this.$t('order-cancel-text');
      const processingText = this.$t('order-process-text');
      return removingOrder ? removingText : processingText;
    },
    orderRepository(): OrdersRepository {
      return factory.get('order') as OrdersRepository;
    },
    order(): Order | null {
      return this.getOrderById(this.orderId);
    },
    createdDate(): string | undefined {
      return convertDate(this.order?.creation_date);
    },
    customerId(): string | undefined {
      return this.order?.account_id;
    },
    accountName(): string | TranslateResult {
      const name = this.order?.account_name ? this.order?.account_name : this.$i18n.t('order');
      return name;
    },
    orderProducts(): Product[] | undefined {
      return this.order?.products;
    },
    orderDeliveryDate(): string {
      return convertDate(this.order?.delivery_date);
    },
    cancelOrderDisabled(): boolean {
      return this.order?.status_id !== 100;
    },
    popupMethodPass(): unknown {
      return this.popupMethod === 'cancel' ? this.cancelOrder : this.processOrder;
    }
  },
  methods: {
    ...mapActions('order', ['showOrderDetail', 'setOrder']),
    ...mapMutations('order', ['REMOVE_ORDER']),
    getOrder(): void {
      this.orderRepository.getById(this.orderId);
    },
    popupStatus(fn: string): void {
      this.popupMethod = fn;
      this.showPopup = true;
    },
    getOrdersProducts(order: Order | null) {
      if (order?.links.order_items && !order?.products) {
        const {
          links: { order_items: orderItemLink },
          id: orderId
        } = order;
        this.orderRepository.getOrdersProducts(orderItemLink, orderId);
      }
    },
    async cancelOrder(): Promise<void> {
      if (this.order?.status_id !== 600 && this.order?.links.cancel_order) {
        this.loading = true;
        await this.orderRepository.cancelOrder(this.order?.links.cancel_order);
        this.orderCanceled = true;
        if (global.nestedRoute) {
          this.$router.go(-1);
        } else {
          this.$router.push({ name: 'orders' });
        }
      }
    },
    async processOrder(): Promise<void> {
      if (this.order?.status_id !== 600 && this.order?.links.process_order) {
        this.loading = true;
        const { data } = await this.orderRepository.processOrder(this.order?.links.process_order);
        this.setOrder(data);
        this.showPopup = false;
        this.loading = false;
      }
    },
    initComponent() {
      this.showOrderDetail();
      global.changeTitle(`${this.accountName}`);
      this.getOrdersProducts(this.order);
    }
  },
  watch: {
    orderId: {
      handler() {
        this.getOrder();
      },
      immediate: true
    },
    order: {
      handler() {
        this.initComponent();
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    const { name } = from;
    if (name === 'allOrders') {
      global.setNestedRoute(true);
    }
    next();
  },
  beforeDestroy() {
    const orderStatus = parseInt(this.status, 10);
    if ((this.order?.status_id === 200 && orderStatus !== 0) || this.orderCanceled) {
      this.REMOVE_ORDER(this.orderId);
    }
  }
});
