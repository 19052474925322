





























































import Vue from 'vue';
import contactActions from '@/mixins/contactActions';

export default Vue.extend({
  name: 'OpenItemsNotification',
  mixins: [contactActions]
});
