







import Vue from 'vue';

export default Vue.extend({
  name: 'NavBottomBtn',
  props: ['icon', 'title', 'pathName', 'reload'],
  methods: {
    reloadPage() {
      if (this.reload) {
        location.reload();
      }
    }
  }
});
