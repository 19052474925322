/* eslint-disable no-shadow */
import Vue from 'vue';
import { handleConnectionChange, setConnectionStatus } from '@/utils/ConnectionStatus';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { AxiosInstance } from 'axios';
import './filters';
import AuthorizationService from '@/services/authorizationService';
import i18n from './i18n';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import store from './store';
import vuetify from './plugins/vuetify';
import Axios from './services/api/Repository';
import RepositoryFactory from './services/api/RepositoryFactory';
import KacRepositoryFactory from './services/apiKAC/RepositoryFactory';
import AuthService from './services/authService';

Vue.config.productionTip = false;

Vue.prototype.$http = Axios;
Vue.prototype.$repositoryFactory = new RepositoryFactory();
Vue.prototype.$kacRepositoryFactory = new KacRepositoryFactory();
// Authentication service
Vue.prototype.$authService = new AuthService(Vue.prototype.$http);
// Authorization service
Vue.prototype.$authorizationService = new AuthorizationService();

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosInstance;
    $authService: AuthService;
    $repositoryFactory: RepositoryFactory;
    $kacRepositoryFactory: KacRepositoryFactory;
    $authorizationService: AuthorizationService;
  }
}

// monitor offline or online event
addEventListener('online', handleConnectionChange);
addEventListener('offline', handleConnectionChange);

i18n.silentTranslationWarn = true;

/** ************ VUE INSTANCE ************ */
new Vue({
  router,
  store,
  vuetify,
  i18n,
  beforeCreate() {
    setConnectionStatus();
  },
  render: h => h(App)
}).$mount('#app');
