var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification"},[_c('v-bottom-sheet',{attrs:{"fullscreen":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-card',{staticClass:"d-flex",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"subtitle-1 mr-10"},[_vm._v(" "+_vm._s(_vm.$t('open_items.notification.alert'))+" ")]),_c('v-card-actions',{staticClass:"pa-4",attrs:{"relative":""}},[_c('v-btn',_vm._g({staticClass:"brand-confirm-button",attrs:{"color":"success","max-width":"120px","right":"","absolute":"","elevation":"0"}},on),[_vm._v(" "+_vm._s(_vm.$t('open_items.notification.help'))+" ")])],1)],1)]}}]),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-card',{staticClass:"px-3"},[_c('v-card-text',[_c('v-row',{staticClass:"flex-nowrap mb-6"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',{attrs:{"align-self":"center"}},[_c('p',{staticClass:"headline text-center ma-0"},[_vm._v(" "+_vm._s(_vm.$t('open_items.notification.sheet.title'))+" ")])]),_c('v-col',{attrs:{"cols":"1"}})],1),_c('p',{staticClass:"px-1 my-4"},[_vm._v(" "+_vm._s(_vm.$t('open_items.notification.sheet.paragraph'))+" ")]),_c('ul',[_c('li',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm.$t('open_items.notification.sheet.li1'))+" ")]),_c('li',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm.$t('open_items.notification.sheet.li2'))+" ")]),_c('li',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm.$t('open_items.notification.sheet.li3'))+" ")])]),_c('v-card',{attrs:{"flat":""}},_vm._l((_vm.contactInfo),function(ref,index){
var action = ref.action;
var context = ref.context;
var type = ref.type;
var icon = ref.icon;
return _c('v-list',{key:index,attrs:{"two-line":""}},[_c('v-list-item',{on:{"click":function($event){return action(context)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t(("" + type))))]),_c('v-list-item-title',[_vm._v(_vm._s(context))])],1)],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }