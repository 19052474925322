export default {
  prepareParams(initParams, params) {
    let finalParams = initParams;
    if (params.searchString !== '') {
      finalParams += `&search=${params.searchString}`;
    }

    if (params.type !== '') {
      finalParams += '&type=';
      finalParams += params.type;
    }

    if (params.tag !== '') {
      finalParams += '&tag=';
      finalParams += params.tag;
    }

    return finalParams;
  },

  addOffset(oldPath, initPath, offset) {
    let finalPath = initPath;
    if (oldPath === initPath && offset) {
      finalPath += '&offset=';
      finalPath += offset;
    }

    return finalPath;
  }
};
