
































import Vue from 'vue';
import { mapState } from 'vuex';
import globalStore from '@/store/modules/global';

export default Vue.extend({
  name: 'NavTitleBar',
  props: ['detailView'],
  computed: {
    ...mapState('global', ['current_view', 'online', 'current_title', 'nestedRoute', 'loadingCounter']),
    cloudDrawer: {
      get() {
        return globalStore.cloudDrawer;
      },

      set(value: boolean) {
        globalStore.showCloudDrawer(value);
      }
    }
  },
  methods: {
    back() {
      this.$router.back();
    }
  }
});
