






















































import Vue from 'vue';

export default Vue.extend({
  name: 'ProductViewDetailsRow',
  props: {
    text: {
      type: String
    },
    oneColumn: {
      type: Boolean
    },
    date: {
      type: String
    },
    inputValue: {
      type: String
    },
    additionalInput: {
      type: Boolean
    },
    sellUnit: {
      type: String
    },
    rules: {
      type: Array
    },
    formerPrice: {
      type: Boolean
    },
    differentPrice: {
      type: Boolean
    }
  },
  computed: {
    isLoading(): boolean {
      return !this.date.length;
    }
  },
  methods: {
    setLastPrice(): void {
      this.$emit('setLastPrice');
    }
  }
});
