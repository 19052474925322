<template>
  <div class="planninglist-component">
    <v-row>
      <v-col cols="12" lg="6" offset-lg="3" class="pb-0">
        <form @submit.prevent="filterBtnAction" data-testid="filter-form">
          <v-row>
            <v-col cols="4" lg="5" class="pb-0">
              <v-dialog ref="dialogFrom" v-model="modalFrom" :return-value.sync="dateFrom" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatDateFrom"
                    :label="$t('from_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    data-test="date-from"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateFrom" scrollable :locale="$i18n.locale">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalFrom = false">{{ $t('cancel') }}</v-btn>
                  <v-btn text color="primary" @click="$refs.dialogFrom.save(dateFrom)" data-test="confirm-btn">{{ $t('confirm') }}</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="4" md="5" class="pb-0">
              <v-dialog ref="dialogTo" v-model="modalTo" :return-value.sync="dateTo" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatDateTo"
                    :label="$t('to_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    data-test="date-to"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateTo" scrollable :locale="$i18n.locale">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalTo = false">{{ $t('cancel') }}</v-btn>
                  <v-btn text color="primary" @click="$refs.dialogTo.save(dateTo)" data-test="confirm-btn">{{ $t('confirm') }}</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="4" md="2">
              <v-btn data-testid="filter-button" type="submit" class="mt-2">{{ $t('show') }}</v-btn>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
    <v-row v-if="getPlanningList">
      <v-col cols="12" data-testid="date-range-header">
        <v-alert v-if="getHeaderInfo === $t('headerNoDataFound')" type="info">{{ getHeaderInfo }}</v-alert>
        <h1 v-if="getHeaderInfo !== $t('headerNoDataFound')">{{ getHeaderInfo }}</h1>
      </v-col>
      <v-col cols="12">
        <v-row v-for="dateGroup in getPlanningList" :key="dateGroup.index" class="mb-8" no-gutters data-testid="date-group-row">
          <v-card>
            <v-col cols="12">
              <v-card-title data-testid="date-title">
                <span class="font-weight-bold mr-2 ml-2">{{ $t('date') }}:</span> {{ formatDate(dateGroup.index) }}
              </v-card-title>
            </v-col>

            <v-col cols="12" no-gutters class="pt-0">
              <v-row
                v-for="productCategoryGroup in dateGroup.items"
                :key="productCategoryGroup.index"
                class="prod-cat-group__row"
                data-testid="product-category-group-row"
              >
                <v-col cols="12 pb-0">
                  <v-row no-gutters class="prod-cat-group__prod-list-header">
                    <v-col cols="12" no-gutters>
                      <v-card-title class="justify-space-between">
                        <div>
                          <span class="font-weight-bold mr-2">{{ $t('productGroup') }}:</span>
                          <span class="mr-3">{{ productCategoryGroup.groupName }}</span>
                        </div>
                        <div>
                          <v-chip class="mt-2 mr-2 mb-2 ml-0" color="primary" text-color="white">
                            <v-avatar left>
                              <v-icon>mdi-counter</v-icon>
                            </v-avatar>
                            <span class="font-weight-bold mr-2">{{ $t('productAmount') }}:</span>
                            <span data-testid="product-category-group-sum-quantity">{{ productCategoryGroup.sumQuantityToLocaleString }}</span>
                          </v-chip>
                          <v-chip class="mt-2 mr-0 mb-2 ml-0" color="primary" text-color="white">
                            <v-avatar left>
                              <v-icon>mdi-weight</v-icon>
                            </v-avatar>
                            <span class="font-weight-bold mr-2">{{ $t('productWeight') }}:</span>
                            <span data-testid="product-category-group-sum-weight">{{ productCategoryGroup.sumWeightToLocaleString }}</span>
                          </v-chip>
                        </div>
                      </v-card-title>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row no-gutters class="prod-cat-group__prod-list">
                    <v-col cols="12" no-gutters>
                      <v-card-text no-gutters>
                        <v-row no-gutters>
                          <v-col cols="4" md="6" class="font-weight-bold pr-2" no-gutters>{{ $t('productName') }}</v-col>
                          <v-col cols="3" md="3" class="font-weight-bold pr-2" no-gutters>{{ $t('productDetails') }}</v-col>
                          <v-col cols="2" md="1" class="font-weight-bold pr-2" no-gutters>{{ $t('productAmount') }}</v-col>
                          <v-col cols="2" md="1" class="font-weight-bold text-right pr-2" no-gutters>
                            {{ $t('productWeight') }}
                          </v-col>
                          <v-col cols="1" md="1" class="font-weight-bold" no-gutters>{{ $t('productWeightUnit') }}</v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" no-gutters>
                      <v-card-text no-gutters>
                        <v-row
                          v-for="productIdGroup in productCategoryGroup.items"
                          :key="productIdGroup.index"
                          no-gutters
                          data-testid="product-id-group-row"
                          class="prod-id-group__row"
                        >
                          <v-col cols="12" no-gutters>
                            <v-row v-for="product in productIdGroup.items" :key="product.index" no-gutters data-testid="product-row">
                              <v-col cols="12" no-gutters>
                                <v-row no-gutters>
                                  <v-col cols="4" md="6" no-gutters class="pr-2"> {{ product.productId }}/{{ product.productName }} </v-col>
                                  <v-col cols="3" md="3" no-gutters class="pr-2" data-testid="date-in-product-detail">
                                    {{ formatDate(product.deliveryDate) }} - ({{ product.customerId }})
                                    {{ product.customerName }}
                                  </v-col>
                                  <v-col cols="2" md="1" no-gutters class="pr-2">
                                    {{ product.getSumQuantityToLocaleString($t) }}
                                  </v-col>
                                  <v-col cols="2" md="1" no-gutters class="text-right pr-2" data-testid="product-weight">
                                    {{ product.getSumWeightToLocaleString() }}
                                  </v-col>
                                  <v-col cols="1" md="1" no-gutters class="pr-2" data-testid="product-sell-unit">{{ product.sellUnitName }} </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" no-gutters>
                            <v-row no-gutters class="mb-3 prod-id-group-summary__row">
                              <v-col cols="7" md="9" no-gutters class="pr-2">
                                <h4>{{ $t('productSum') }}: {{ productIdGroup.groupName }}</h4>
                              </v-col>
                              <v-col cols="2" md="1" no-gutters class="pr-2">
                                <h4 data-testid="product-id-group-sum-quantity">{{ productIdGroup.sumQuantityToLocaleString }}</h4>
                              </v-col>
                              <v-col cols="2" md="1" no-gutters class="text-right pr-2">
                                <h4 data-testid="product-id-group-sum-weight">{{ productIdGroup.sumWeightToLocaleString }}</h4>
                              </v-col>
                              <v-col cols="1" md="1" no-gutters class="pr-2"></v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import RepositoryFactory from '@/services/api/RepositoryFactory';
import PlanningListMapperService from '@/services/PlanningListServices/PlanningListMapperService';
import paramBuilder from '@/utils/ParamBuilder';
import convertDate from '@/utils/ConvertDate';

export default Vue.extend({
  name: 'PlanningList',
  data: () => ({
    planningList: [],
    error: '',
    dateFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    modalFrom: false,

    dateTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    modalTo: false
  }),
  methods: {
    async filterBtnAction() {
      const planningListsRepository = RepositoryFactory.getStatic('planninglists');
      const params = paramBuilder({
        date_from: this.getDateFrom,
        date_to: this.getDateTo
      });

      await planningListsRepository
        .get(params)
        .then(response => {
          this.planningList = PlanningListMapperService.groupBy(response);
        })
        .catch(error => {
          this.error = error.message;
        });
    },
    formatDate(dateToFormat) {
      return convertDate(dateToFormat);
    }
  },
  computed: {
    getError() {
      return this.error;
    },
    getDateFrom() {
      return this.dateFrom;
    },
    getDateTo() {
      return this.dateTo;
    },
    getPlanningList() {
      return this.planningList.items;
    },
    getLocale() {
      return this.$i18n.locale;
    },
    formatDateFrom() {
      return convertDate(this.dateFrom);
    },
    formatDateTo() {
      return convertDate(this.dateTo);
    },
    getHeaderInfo() {
      let returnValue;
      if (this.planningList.items.length === 0) {
        returnValue = this.$t('headerNoDataFound');
      } else {
        returnValue = this.$t('header', { dateFrom: this.formatDate(this.getDateFrom), dateTo: this.formatDate(this.getDateTo) });
      }
      return returnValue;
    }
  },
  i18n: {
    silentTranslationWarn: true,
    messages: {
      en: {
        header: 'Items ordered per day from {dateFrom} to {dateTo}',
        headerNoDataFound: 'No data was found in chosen date range',
        show: 'show',
        date: 'Date',
        productGroup: 'Product group',
        productGroupSum: 'Details of the product group',
        productName: 'Product name',
        productDetails: 'Product details',
        productAmount: 'Amount',
        productWeight: 'Weight',
        productWeightUnit: 'MEH',
        productSum: 'Product summary'
      },
      de: {
        header: 'Bestellte Artikel pro Tag vom {dateFrom} bis {dateTo}',
        headerNoDataFound: 'Im ausgewählten Zeitraum wurden keine Daten gefunden',
        show: 'Anzeigen',
        date: 'Datum',
        productGroup: 'Warengruppe',
        productGroupSum: 'Angaben zur Produktgruppe',
        productName: 'Produktname',
        productDetails: 'Produktdetails',
        productAmount: 'Menge',
        productWeight: 'Gewicht',
        productWeightUnit: 'MEH',
        productSum: 'Produktübersicht'
      }
    }
  }
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.v-alert {
  margin: 10px 0 10px 0;
}
.v-card__title,
.v-card__text {
  padding: 0 16px;
}
.v-card__title {
  font-size: 1.1rem;
}

.prod-cat-group__row {
  // https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/styles/settings/_colors.scss
  background-color: map-get($grey, lighten-4);
  margin: 0px 0px 10px 0px;
}

.prod-id-group__row:last-child .prod-id-group-summary__row {
  margin: 0px !important;
}

.prod-cat-group__prod-list {
  background-color: #ffffff;
  padding: 10px;
}
.planninglist-component {
  padding-top: 12px;
}
</style>
