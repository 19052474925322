var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height"},[_c('v-data-table',{directives:[{name:"sortable-table",rawName:"v-sortable-table",value:({ onEnd: _vm.sortHeaders }),expression:"{ onEnd: sortHeaders }"}],key:_vm.anIncresingNumber,staticClass:"customScroll",attrs:{"id":"table","items":_vm.orders,"mobile-breakpoint":"0","no-data-text":_vm.$t('no-orders'),"no-results-text":_vm.$t('no-matching-records-found'),"disable-pagination":true,"hide-default-footer":true,"headers":_vm.tableHeaders,"search":_vm.search,"sort-desc":_vm.sortDesc,"sort-by":_vm.sortBy,"must-sort":""},on:{"click:row":function (item) { return _vm.redirectToOrderDetail(item); },"update:sort-by":_vm.changeSortBy,"update:sort-desc":_vm.changeSortDesc},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-3",attrs:{"label":_vm.$t('search'),"clearable":"","append-icon":'mdi-filter-variant'},on:{"click:append":function($event){_vm.filterDrawer = !_vm.filterDrawer}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},_vm._l((_vm.tableHeaders),function(ref){
var text = ref.text;
var value = ref.value;
return {key:("header." + value),fn:function(ref){
var header = ref.header;
return [_vm._t(header.text,[_vm._v(_vm._s(_vm.$t(("" + text))))])]}}}),{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._t(item.id,[_vm._v(" "+_vm._s(item.id ? item.id : '-')+" ")])]}},{key:"item.account_name",fn:function(ref){
var item = ref.item;
return [_vm._t(item.account_name,[_vm._v(" "+_vm._s(item.account_name ? item.account_name : '-')+" ")])]}},{key:"item.status_name",fn:function(ref){
var item = ref.item;
return [_vm._t(item.status,[_c('span',{class:_vm.getTextColor(item.status_name)},[_vm._v(" "+_vm._s(item.status_name ? _vm.$t(item.status_name) : '-')+" ")])])]}},{key:"item.delivery_date",fn:function(ref){
var item = ref.item;
return [_vm._t(item.delivery_date,[_vm._v(" "+_vm._s(item.delivery_date ? _vm.convertData(item.delivery_date) : '-')+" ")])]}},{key:"item.account_id",fn:function(ref){
var item = ref.item;
return [_vm._t(item.account_id,[_vm._v(" "+_vm._s(item.account_id ? item.account_id : '-')+" ")])]}},{key:"item.creation_date",fn:function(ref){
var item = ref.item;
return [_vm._t(item.creation_date,[_vm._v(" "+_vm._s(item.creation_date ? _vm.convertData(item.creation_date) : '-')+" ")])]}}],null,true)}),_c('FilterDrawer',{scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('OrdersFilter')]},proxy:true}])}),_c('v-fab-transition',[(_vm.showButton)?_c('v-btn',{attrs:{"fab":"","bottom":"","right":"","fixed":"","color":"primary"},on:{"click":_vm.scrollToTop}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }