









import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Tab } from '@/store/modules/order/types';

export default Vue.extend({
  name: 'OrdersNavTabs',
  computed: {
    ...mapGetters('order', ['tabs']),

    navTabs(): Tab[] {
      return this.tabs;
    }
  }
});
