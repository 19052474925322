






















































































/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import Component from 'vue-class-component';
import globalStore from '@/store/modules/global';
import lsService from '@/services/localStorage/lsService';
import licenseStore from '@/store/modules/license';
import Footer from './Footer.vue';

export interface IRouterLink {
  title: string;
  icon: string;
  path: string;
  linkType: string;
  target: string;
  isModuleActive?: boolean;
}

const NavDrawerProps = Vue.extend({
  props: {
    app: String
  }
});

@Component({
  components: {
    Footer
  }
})
export default class NavDrawer extends NavDrawerProps {
  currentItem: number;

  apps = {
    Contacts: 0,
    Orders: 1,
    KiratikCloud: 9
  } as { [index: string]: number };

  externalLinks: IRouterLink[] = [];

  support = {
    phone: '075 72 / 763 00',
    email: 'support@kiratik.de'
  };

  languages = [
    { value: 'de', label: 'German' },
    { value: 'en', label: 'English' }
  ];

  constructor() {
    super();
    this.currentItem = this.apps[this.app];
  }

  // Life-cycle hooks - begin
  mounted() {
    this.externalLinks = [
      {
        title: 'admin_console',
        icon: '@/assets/AdminConsoleIcon.svg',
        path: this.adminConsolePath,
        linkType: 'external',
        target: '_blank'
      }
    ];
  }
  // Life-cycle hooks - begin

  get locale() {
    return this.$i18n.locale;
  }

  set locale(value) {
    this.$i18n.locale = value;
  }

  get cloudDrawer() {
    return globalStore.cloudDrawer;
  }

  set cloudDrawer(value) {
    globalStore.showCloudDrawer(value);
  }

  get localeLabel() {
    // eslint-disable-next-line arrow-parens
    const label = this.languages.find(lang => lang.value === this.locale);
    return label ? label.label : 'German';
  }

  get adminConsolePath() {
    return process.env.VUE_APP_KAC_URL;
  }

  get getIsOrderAppModuleActive() {
    return licenseStore.isOrderAppModuleActive;
  }

  get routerLinks() {
    return [
      {
        title: 'contacts',
        icon: 'ContactIcon.svg',
        path: '/contacts/search',
        linkType: 'router',
        target: '',
        isModuleActive: true
      },
      {
        title: 'orders',
        icon: 'OrderIcon.svg',
        path: '/orders',
        linkType: 'router',
        target: '',
        isModuleActive: this.getIsOrderAppModuleActive
      }
    ];
  }

  showAdminConsoleLink() {
    const userRole = lsService.getItem('userRole');

    return userRole === 'ROLE_ADMIN' || userRole === 'ROLE_SUPER_ADMIN';
  }

  localeChanged(value: string) {
    this.locale = value;
    lsService.setLocale(value);
  }

  logout() {
    this.$authService.logout();
  }

  prepareTelNo(telNo: string) {
    return telNo.replace('/', '');
  }

  openCall() {
    const tel = this.prepareTelNo(this.support.phone);
    window.open(`tel:${tel}`, '_self');
  }

  openMail() {
    window.open(`mailto:${this.support.email}`, '_self');
  }
}
