/* eslint-disable class-methods-use-this */
import fave from '@/store/modules/fave';
import search from '@/store/modules/search';
import store from '@/store/index';
import SearchUtils from '../../utils/SearchUtils';
import Axios from './Repository';
import { ContactRepository } from './IEntityRepository';

class ContactsRepository implements ContactRepository {
  resource = '/contacts';

  limit = 15;

  sortby = 'description';

  async get(params = null, limit = this.limit, sortby = this.sortby) {
    let initParams = `?limit=${limit}&sortby=${sortby}`;
    if (params) {
      const prepareParams = SearchUtils.prepareParams(initParams, params);
      initParams = SearchUtils.addOffset(search.search_request_path, prepareParams, search.search_next_offset);
      search.add_search_request_path(prepareParams);
    }
    return Axios.get(`${this.resource}${initParams}`).then(res => {
      const total = Number(res.data.total);
      const offset = Number(res.data.offset);
      const resLimit = Number(res.data.limit);
      const nextOffset = offset + resLimit;

      if (offset) {
        search.append_search(res.data.data);
      } else {
        search.sort_search(res.data.data);
      }

      search.add_search_total(total);
      search.add_search_offset(offset);
      search.add_search_limit(limit);
      search.add_search_next_offset(nextOffset);
    });
  }

  /**
   * Searching first in store, then if not found make Axios request to API
   */
  async getOne(contactId: string) {
    return Axios.get(`${this.resource}/${contactId}`).then(res => {
      store.dispatch('contact/addCurrentContact', res.data);
      return res.data;
    });
  }

  async getFavorites(params = null, limit = this.limit, sortby = this.sortby) {
    let initParams = `?limit=${limit}&favorite=true&sortby=${sortby}`;
    if (params) {
      const prepareParams = SearchUtils.prepareParams(initParams, params);
      initParams = SearchUtils.addOffset(fave.fave_request_path, prepareParams, fave.fave_next_offset);
      fave.add_fave_request_path(prepareParams);
    }
    return Axios.get(`${this.resource}${initParams}`).then(res => {
      const total = Number(res.data.total);
      const offset = Number(res.data.offset);
      const resLimit = Number(res.data.limit);
      const nextOffset = offset + resLimit;

      if (offset) {
        fave.append_fave(res.data.data);
      } else {
        fave.sort_fave(res.data.data);
      }

      fave.add_fave_total(total);
      fave.add_fave_offset(offset);
      fave.add_fave_limit(limit);
      fave.add_fave_next_offset(nextOffset);
    });
  }

  async getChildren(parentId: string) {
    const initParams = `?parent_contact_id=${parentId}`;
    return Axios.get(`${this.resource}${initParams}`).then(res => {
      store.dispatch('contact/addChildren', res.data.data);
    });
  }

  async getProductsStatistics(url: string) {
    return Axios.get(url).then(res => {
      store.dispatch('contact/addProducts', res.data);
      // TODO: set here also order/contact_id???
    });
  }

  async getValuesPerMonthStatitics(url: string): Promise<void> {
    return Axios.get(url).then(res => {
      store.dispatch('contact/addValuesPerMonth', res.data);
    });
  }

  async getOpenItems(url: string): Promise<void> {
    return Axios.get(url).then(res => {
      store.dispatch('contact/addOpenItems', res.data);
    });
  }

  async getLivestockTrader(url: string): Promise<void> {
    return Axios.get(url).then(res => {
      store.dispatch('contact/addLivestockTrader', res.data);
    });
  }

  /**
   * @param {number} contactId
   * @param {string} status - 'true'|'false'
   * @returns {Promise<AxiosResponse<T>>}
   */
  async changeFavoriteStatus(contactId: string, favorite: boolean) {
    return Axios.patch(`${this.resource}/${contactId}`, { favorite });
  }

  async getDeliveryDates(contactId: string, limit = '30') {
    return Axios.get(`${this.resource}/${contactId}/next_delivery_dates`, { params: { limit } });
  }
}

export default new ContactsRepository();
