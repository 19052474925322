




























import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { mapMutations } from 'vuex';
import { DatesInterface } from './types';

export default Vue.extend({
  name: 'FiltersDrawerDates',
  props: {
    dates: {
      type: Object as () => DatesInterface,
      required: true
    }
  },
  data() {
    return {
      showCalendar: false,
      changeMinDate: false
    };
  },
  computed: {
    fromDate(): TranslateResult | string {
      return this.dates.dateFrom === '' ? this.$t('from_date') : this.dates.dateFrom.substring(0, 10);
    },
    toDate(): TranslateResult | string {
      return this.dates.dateTo === '' ? this.$t('to_date') : this.dates.dateTo.substring(0, 10);
    }
  },
  methods: {
    ...mapMutations('order', ['CLEAR_DATES']),
    allowedDates(value: string): boolean {
      if (!this.changeMinDate && this.dates.dateFrom.length) {
        return value >= this.dates.dateFrom;
      }
      return true;
    },
    clearDate(): void {
      this.$emit('changeFromDate', '');
      this.$emit('changeToDate', '');
      this.CLEAR_DATES();
    },
    submitForm(): void {
      this.$emit('searchByDates');
    },
    setDate(value: string): void {
      if (this.changeMinDate) {
        this.$emit('changeFromDate', value);
      } else {
        this.$emit('changeToDate', value);
      }
    },
    changeDate(changeMinDate: boolean): void {
      this.showCalendar = !this.showCalendar;
      if (changeMinDate) {
        this.changeMinDate = true;
      } else {
        this.changeMinDate = false;
      }
    }
  },
  watch: {
    'dates.dateFrom': {
      handler() {
        if (this.dates.dateFrom > this.dates.dateTo) {
          this.$emit('changeToDate', '');
        }
      }
    }
  }
});
