import { IPlanningListItem } from '@/interfaces/IPlanningListItem';
import i18n from '@/i18n';

class PlanningListItem implements IPlanningListItem {
  private readonly _amount: number;

  private readonly _customerId: string;

  private readonly _customerName: string;

  private readonly _deliveryDate: string;

  private readonly _productGroupId: string;

  private readonly _productGroupName: string;

  private readonly _productId: string;

  private readonly _productName: string;

  private readonly _productWeight: number;

  private readonly _quantity: number;

  private readonly _sellUnit: string;

  private readonly _sellUnitName: string;

  private readonly _sumAmount: number | undefined;

  private readonly _sumQuantity: number;

  private readonly _sumWeight: number;

  private readonly _weight: number;

  constructor(object: IPlanningListItem) {
    this._amount = object.amount;
    this._productId = object.productId;
    this._productName = object.productName;
    this._productWeight = object.productWeight;
    this._productGroupId = object.productGroupId;
    this._productGroupName = object.productGroupName;
    this._customerId = object.customerId;
    this._customerName = object.customerName;
    this._weight = object.weight;
    this._deliveryDate = object.deliveryDate;
    this._sellUnit = object.sellUnit;
    this._sellUnitName = object.sellUnitName;
    this._sumAmount = object.sumAmount;
    this._sumWeight = object.sumWeight;
    this._quantity = object.quantity;
    this._sumQuantity = object.sumQuantity;
  }

  get amount(): number {
    return this._amount;
  }

  get customerId(): string {
    return this._customerId;
  }

  get customerName(): string {
    return this._customerName;
  }

  get deliveryDate(): string {
    return this._deliveryDate.split('T')[0];
  }

  get productGroupId(): string {
    return this._productGroupId;
  }

  get productGroupName(): string {
    return this._productGroupName;
  }

  get productId(): string {
    return this._productId;
  }

  get productName(): string {
    return this._productName;
  }

  get productWeight(): number {
    return this._productWeight;
  }

  get quantity(): number {
    return this._quantity;
  }

  get sellUnit(): string {
    return this._sellUnit;
  }

  get sellUnitName(): string {
    return this._sellUnitName;
  }

  get sumAmount(): number | undefined {
    return this._sumAmount;
  }

  get sumQuantity(): number {
    return this._sumQuantity;
  }

  get sumWeight(): number {
    return this._sumWeight;
  }

  get weight(): number {
    return this._weight;
  }

  public getDeliveryDateFormatted(): string {
    const date = new Date(this._deliveryDate);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    } as Intl.DateTimeFormatOptions;

    return date.toLocaleDateString(i18n.locale, options);
  }

  public getSumQuantityToLocaleString(): string {
    return this._sumQuantity.toLocaleString(i18n.locale);
  }

  public getSumWeightToLocaleString(): string {
    return new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: this._sellUnitName === 'kg' ? 3 : 2,
      maximumFractionDigits: this._sellUnitName === 'kg' ? 3 : 2
    }).format(this._sumWeight);
  }
}

export default PlanningListItem;
